import React from "react";
import styled from "styled-components/macro";
import { useGetState, useSetState } from "components/ReduxProvider";
import { useEffectOnce, useEvent } from "react-use";
import { CARD_TRANS, CardFlipWrap, CardFront } from "./Card";
import { TChatBubble } from "../../../components/assistant/Assistant";
import { Button, Zoom } from "@mui/material";
import { Trans } from "react-i18next";
import ChatBubble from "../../../components/assistant/ChatBubble";
import { useOpenAssistant } from "../../../components/assistant/assistantHooks";
import { useTranslates } from "../../../hooks/useTranslates";
import { useViewerDecks, useViewerQuery } from "../../../queries/viewerQuery";
import { getAIStoryStarter } from "../../../components/assistant/suggestions/suggestionsDefinitions";
import { PICKED_FEW_CARDS } from "../../../components/fire/xpHooks";
import { EVENT } from "../../../tools/events";

// for testing purposes, if set to true it will always show at the bottom (make sure you test it in AI-story supported langs only)
const FORCE_SHOW_AT_BOTTOM = false;

const CardFrontIn = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
`;
const Buttons = styled.div`
  margin: 20px;
  button {
    margin-bottom: 15px;
    width: 100%;
  }
`;
const SChatBubble = styled(ChatBubble)`
  margin: 0 20px 40px;
`;

type Props = {
  cardsAboveCount: number;
};
const AIStoryCard: React.FC<Props> = ({ cardsAboveCount }) => {
  const { hintWasSeen } = useViewerQuery();
  const setBottomCardActive = useSetState("bottomCardActive");
  const bottomCardActive = useGetState("bottomCardActive");
  const { deck } = useViewerDecks();
  const openAssistant = useOpenAssistant();
  const { translates, isLoadingTranslates } = useTranslates(
    deck?.front || "",
    ["I will tell you a story using the words and phrases that you are learning..."],
    !deck
  );
  const cardRef: React.RefObject<HTMLDivElement> = React.useRef(null);
  const rightSwipesCount = useGetState("rightSwipesCount");

  const memoMessage: TChatBubble = {
    text: translates?.[0] || "",
    owner: "assistant"
  };

  useEffectOnce(() => {
    if (FORCE_SHOW_AT_BOTTOM) {
      setBottomCardActive(true);
    }
  });

  React.useEffect(() => {
    if (bottomCardActive) return;

    if (!hintWasSeen(PICKED_FEW_CARDS) && (rightSwipesCount || 0) === 7) {
      setBottomCardActive(true);
    }

    if (hintWasSeen(PICKED_FEW_CARDS) && (rightSwipesCount || 0) % 30 === 0) {
      setBottomCardActive(true);
    }
  });

  useEvent(EVENT.assistantClosed, () => {
    if (cardsAboveCount === 0) {
      setBottomCardActive(false);
    }
  });

  const handleLater = () => {
    if (!cardRef.current) return;
    cardRef.current.style.left = `120%`;
    cardRef.current.style.opacity = `0`;

    setTimeout(() => {
      setBottomCardActive(false);
    }, CARD_TRANS);
  };

  const handleStart = () => {
    const aiStorySuggestion = getAIStoryStarter();
    openAssistant({ autoApplySuggestion: aiStorySuggestion, openAnimation: Zoom });
  };

  if (!bottomCardActive) return null;

  return (
    <CardFlipWrap index={0} moving={false} putBelow={true} ref={cardRef}>
      <CardFront>
        <CardFrontIn>
          <SChatBubble chatMessage={memoMessage} transparentBubble isTyping={isLoadingTranslates}></SChatBubble>
          <Buttons>
            <Button variant="contained" onClick={handleStart}>
              <Trans>Start</Trans>
            </Button>
            <Button variant="outlined" onClick={handleLater}>
              <Trans>Maybe later</Trans>
            </Button>
          </Buttons>
        </CardFrontIn>
      </CardFront>
    </CardFlipWrap>
  );
};

export default AIStoryCard;

import { graphql } from "babel-plugin-relay/macro";
import { useMutation } from "relay-hooks";
import { LemonLinkGetMutation } from "./__generated__/LemonLinkGetMutation.graphql";
import { EVENT, dispatchEvent } from "tools/events";
import { useSeeHint } from "components/hint/Hint";
import { FIRSTSUBSCRIPTION } from "root/tutorial/misc/FirstSubscription";
import { isBrowser, openInExternalBrowser } from "tools/device";
import { logEvent, logPurchase } from "tools/analyticEvents";
import { useEffectOnce } from "react-use";
import { useSetState } from "components/ReduxProvider";
import { SUBDBLOG } from "sharedJs__generated/constants";
import { useDBLog } from "hooks/useDBLog";
import { LemonSubscriptionData } from "sharedJs__generated/globalTypes";

const getLemonLink = graphql`
  mutation LemonLinkGetMutation($type: String!, $storeId: String, $returnPath: String) {
    getLemonLink(type: $type, storeId: $storeId, returnPath: $returnPath) {
      link
    }
  }
`;

export const useLemonLink = () => {
  const seeHint = useSeeHint();
  const [mutate] = useMutation<LemonLinkGetMutation>(getLemonLink);
  const dblog = useDBLog();
  const setOrderOpened = useSetState("orderOpened");

  useEffectOnce(() => {
    const lemonScriptSrc = "https://app.lemonsqueezy.com/js/lemon.js";
    if (isBrowser() && !document.querySelector(`script[src="${lemonScriptSrc}"]`)) {
      initializeLemonScript(lemonScriptSrc);
    }
  });

  const initializeLemonScript = (lemonScriptSrc: string) => {
    const script = document.createElement("script");
    script.src = lemonScriptSrc;
    script.async = true;
    script.defer = true;

    script.onload = () => {
      let attempts = 0;
      const maxAttempts = 3;

      const setupLemonSqueezy = () => {
        // need to wait for lemon.js to be loaded
        if (window.LemonSqueezy) {
          window.LemonSqueezy.Setup({ eventHandler });
        } else if (attempts < maxAttempts) {
          window?.createLemonSqueezy?.();
          attempts++;
          setTimeout(setupLemonSqueezy, 500);
        }
      };

      setupLemonSqueezy();
    };

    document.body.appendChild(script);
  };

  const eventHandler = (event) => {
    if (event === "close") {
      dispatchEvent(EVENT.closeLoaderGlobalWithMemoDialog);
      return;
    }

    switch (event.event) {
      case "Checkout.Success":
        const lemonData = event.data.order as LemonSubscriptionData;
        logEvent("trial_or_purchase"); // in case we want to target only this events regardless of revenue
        logPurchase({ price: Number(lemonData.meta.custom_data.price_czk), currency: "CZK" });
        dblog(SUBDBLOG, `Confirmed Lemon purchase on FE`, lemonData, Infinity);
        break;
      case "Checkout.ViewCart":
        dispatchEvent(EVENT.closeLoaderGlobalWithMemoDialog);
        break;
    }
  };

  const redirectToLemon = (options: { variables: any; onCompleted?: () => void }) => {
    const { variables, onCompleted } = options;

    return mutate({
      variables,
      optimisticUpdater: () => {
        dispatchEvent(EVENT.openLoaderGlobalWithMemoDialog);
      },
      onCompleted: (data) => {
        setOrderOpened(undefined);
        if (data.getLemonLink?.link) {
          seeHint(FIRSTSUBSCRIPTION);
          // if embed is possible, use it. Otherwise use redirect
          if (variables.type === "checkout" && window.LemonSqueezy) {
            window.LemonSqueezy.Url.Open(data.getLemonLink.link);
          } else {
            openInExternalBrowser(data.getLemonLink.link, { shouldReuseCurrentTab: true });
          }
        }
        setTimeout(() => {
          dispatchEvent(EVENT.closeLoaderGlobalWithMemoDialog);
        }, 8000);
        onCompleted?.();
      },
      onError: () => {
        setOrderOpened(undefined);
        dispatchEvent(EVENT.closeLoaderGlobalWithMemoDialog);
      }
    });
  };

  return { redirectToLemon };
};

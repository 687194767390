import React from "react";
import styled from "styled-components/macro";
import { Trans } from "react-i18next";

export const Item = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
`;

export const IconWrap = styled.div`
  min-width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.duo.palette.blueMediumLight};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  background-color: ${({ theme }) => theme.duo.color.white};
`;

export const TextWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Title = styled.div`
  font-size: 16px;
  margin-top: 10px;
`;

export const Description = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.duo.palette.greyDark};
  margin-top: 4px;
`;

export type FeatureItemProps = {
  iconPath: string;
  title: string;
  description?: string;
};

const FeatureItem: React.FC<FeatureItemProps> = ({ iconPath, title, description }) => {
  return (
    <Item>
      <IconWrap>
        <img src={iconPath} alt={`${title} Icon`} width={30} height={30} />
      </IconWrap>
      <TextWrap>
        <Title>
          <Trans>{title}</Trans>
        </Title>
        {description && (
          <Description>
            <Trans>{description}</Trans>
          </Description>
        )}
      </TextWrap>
    </Item>
  );
};

export default FeatureItem;

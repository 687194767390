import React from "react";
import styled from "styled-components/macro";
import CloseIcon from "@mui/icons-material/Close";
import { Button } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { devicePaddings } from "../../tools/device";
import { useSubscribe, useSubscriptions } from "../../root/profile/subscription/subscriptionHooks";
import CheckIcon from "@mui/icons-material/Check";
import { useViewerQuery } from "../../queries/viewerQuery";
import { useNavigate } from "react-router";
import { SUBSCRIPTION } from "../../root/profile/ProfileRouter.config";
import { TUTORIALDONE } from "../../root/tutorial/steps/Verdict";
import { useStoreIds } from "../../root/profile/subscription/subscriptionIds";
import TransVariable from "../other/TransVariable";
import { useABTest } from "queries/users/useABTest";
import { useEffectOnce } from "react-use";

const { top, bottom } = devicePaddings();

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: ${({ theme }) => theme.duo.maxWidth.narrow};
  margin: 0 auto;
  padding: ${top + 20}px 20px ${bottom + 20}px;
  height: 100%;
  width: 100%;
  background: linear-gradient(180deg, #00a1e6 0%, #d4ecff 100%);
`;

const Header = styled.header`
  display: flex;
  justify-content: flex-end;
`;

const CloseButton = styled.button<{ $isVisible: boolean }>`
  min-width: 24px;
  width: 24px;
  height: 24px;
  padding: 0;
  background: none;
  border: none;
  color: ${({ theme }) => theme.duo.palette.white};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${({ $isVisible }) => ($isVisible ? 0.6 : 0)};
  transition: opacity 2.5s;
  pointer-events: ${({ $isVisible }) => ($isVisible ? "auto" : "none")};
  svg {
    font-size: 20px;
  }
`;

const Title = styled.h1`
  font-size: 32px;
  font-family: "Noto Sans", "Segoe UI", sans-serif;
  color: ${({ theme }) => theme.duo.palette.white};
  margin: 0 0 15px;
  text-align: center;
`;

const BoldText = styled.span`
  font-weight: 700;
`;

const UnderlineWrapper = styled.div`
  margin-top: -10px;
  position: relative;
  width: 187px;
  height: 7px;
  margin: 0 auto;
`;

const Underline = styled.div`
  width: 100%;
  height: 4px;
  background-color: ${({ theme }) => theme.duo.palette.white};
  border-radius: 2px;
  transform: rotate(-0.736deg);
`;

const TitleSection = styled.div`
  text-align: center;
`;

const PremiumTitle = styled.h2`
  font-size: 30px;
  color: ${({ theme }) => theme.duo.palette.white};
  font-family: "Baloo Chettan 2", system-ui, sans-serif;
  font-weight: ${({ theme }) => theme.duo.fontWeight.w700};
  text-align: center;
  margin: 20px 0;
`;

const FeaturesContainer = styled.section`
  background-color: ${({ theme }) => theme.duo.palette.white};
  border-radius: ${({ theme }) => theme.duo.borderRadius.normal};
  padding: 20px;
  width: 100%;
  max-width: 350px;
  margin: 0 auto;
`;

const FeaturesList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 12px;
  list-style: none;
  padding: 0;
  margin: 0;
`;

const FeatureItem = styled.li`
  display: flex;
  align-items: center;
  gap: 12px;
  color: ${({ theme }) => theme.duo.color.text};
  font-family: "Noto Sans", "Segoe UI", sans-serif;
  font-size: 16px;
`;

const CheckIconWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background-color: ${({ theme }) => theme.duo.palette.greenMedium};
  border-radius: 50%;
  color: ${({ theme }) => theme.duo.palette.white};
`;

const StyledCheckIcon = styled(CheckIcon)`
  && {
    font-size: 16px;
  }
`;

const Divider = styled.hr`
  height: 1px;
  background-color: ${({ theme }) => theme.duo.palette.blueMediumLight};
  opacity: 0.5;
  border: none;
  margin: 0;
`;

const ButtonContainer = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 20px;
`;

const TrialButton = styled(Button)`
  && {
    background-color: ${({ theme }) => theme.duo.palette.greenMedium};
    color: ${({ theme }) => theme.duo.palette.white};
    text-transform: uppercase;
    font-family: Roboto, "Segoe UI", sans-serif;
    font-weight: ${({ theme }) => theme.duo.fontWeight.w700};
    font-size: 14px;
    padding: 11px 20px;
    border-radius: 100px;
    margin-bottom: 10px;
    white-space: nowrap;
  }
`;

const TrialInfo = styled.div`
  font-size: ${({ theme }) => theme.duo.fontSize.small};
  color: ${({ theme }) => theme.duo.color.text};
  margin: 5px 0;
`;

type Props = { onClose: VoidFunction };

const InternalAdPaywall: React.FC<Props> = ({ onClose }) => {
  const { t } = useTranslation();
  const { subscriptions } = useSubscriptions();
  const { storeIdYear } = useStoreIds();
  const { hintWasSeen, isSubscriber } = useViewerQuery();
  const subscribe = useSubscribe();
  const navigate = useNavigate();
  const moreVisibleSkip = useABTest("abtest_moreVisibleSkip");
  const [isCloseVisible, setIsCloseVisible] = React.useState(false);

  useEffectOnce(() => {
    setTimeout(() => setIsCloseVisible(true), moreVisibleSkip ? 300 : 1500);
  });

  const features = [
    t("More effective and faster learning"),
    t("Unlimited number of cards"),
    t("No ad interruptions"),
    t("Learning with AI assistant")
  ];

  const handleSubscribe = (e: React.MouseEvent) => {
    if (!hintWasSeen(TUTORIALDONE)) {
      subscribe();
    } else {
      navigate(SUBSCRIPTION.url());
    }
    e.stopPropagation();
  };

  React.useEffect(() => {
    if (isSubscriber || !subscriptions) {
      onClose();
    }
  }, [isSubscriber, subscriptions, onClose]);

  return (
    <Wrap>
      <Header>
        <CloseButton onClick={onClose} aria-label="Close premium offer" $isVisible={isCloseVisible}>
          <CloseIcon />
        </CloseButton>
      </Header>

      <div>
        <TitleSection>
          <Title>
            <Trans>
              Get more from your <BoldText>study&nbsp;plan!</BoldText>
            </Trans>
          </Title>
          <UnderlineWrapper>
            <Underline />
          </UnderlineWrapper>
        </TitleSection>

        <PremiumTitle>DuoCards Premium</PremiumTitle>

        <FeaturesContainer>
          <FeaturesList>
            {features.map((feature, index) => (
              <React.Fragment key={index}>
                {index > 0 && <Divider />}
                <FeatureItem>
                  <CheckIconWrapper>
                    <StyledCheckIcon />
                  </CheckIconWrapper>
                  {feature}
                </FeatureItem>
              </React.Fragment>
            ))}
          </FeaturesList>
        </FeaturesContainer>
      </div>

      <ButtonContainer>
        <TrialButton variant="contained" fullWidth onClick={handleSubscribe} disabled={!subscriptions}>
          <Trans>Start free trial</Trans>
        </TrialButton>
        {subscriptions && (
          <TrialInfo>
            {subscriptions[storeIdYear]?.isTrial ? (
              <Trans>
                7 days free, then <TransVariable text={subscriptions[storeIdYear]?.price + " / " + t("1 Year")} />
              </Trans>
            ) : (
              <>
                {subscriptions[storeIdYear]?.price} / <Trans>1 Year</Trans>
              </>
            )}
          </TrialInfo>
        )}
      </ButtonContainer>
    </Wrap>
  );
};

export default InternalAdPaywall;

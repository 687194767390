import React from "react";
import styled from "styled-components/macro";
import { Stack, Button } from "@mui/material";
import { Trans } from "react-i18next";
import LoginGuest from "./LoginGuest";
import OAuth from "components/other/OAuth";
import EmailAuth from "./EmailAuth";
import ContinueWithEmailButton from "./ContinueWithEmailButton";

const SliderOut = styled.div`
  width: 100%;
  overflow: hidden;
`;

const SliderBox = styled.div`
  width: 50%;
  padding: 8px 0;
`;

const SliderIn = styled.div<{ index: number; size: number }>`
  width: ${(p) => p.size * 100}%;
  position: relative;
  left: ${(p) => p.index * -100}%;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  transition: left 0.3s;
  ${SliderBox} {
    width: ${(p) => 100 / p.size}%;
  }
`;

const In = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  margin: 0 auto;
  padding: 5px 24px;
  @media (min-width: 600px) {
    max-width: 400px;
  }
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: stretch;
  margin: 0 auto;
  padding: 5px 24px;
  @media (min-width: 600px) {
    max-width: 400px;
  }
  height: 100%;
`;

const SContinueWithEmailButton = styled(ContinueWithEmailButton)`
  margin-bottom: 20px;
`;

type AuthOptionsProps = {
  isEmailOpen: boolean;
  areLoginOptionsOpen: boolean;
  onEmailToggle: (show: boolean) => void;
  onKeyboardToggle?: (isVisible: boolean) => void;
};

export const AuthOptions: React.FC<AuthOptionsProps> = ({
  isEmailOpen,
  areLoginOptionsOpen,
  onEmailToggle,
  onKeyboardToggle
}) => {
  let slideIndex: number;
  if (areLoginOptionsOpen) {
    slideIndex = isEmailOpen ? 2 : 1;
  } else {
    slideIndex = 0;
  }

  return (
    <SliderOut>
      <SliderIn size={3} index={slideIndex}>
        <SliderBox>
          <Bottom>
            <Stack direction="column" spacing={3} sx={{ margin: "0 15px 22px" }}>
              <Button variant="outlined" color="primary" size="large" fullWidth onClick={() => onEmailToggle(false)}>
                <Trans>I already have an account</Trans>
              </Button>
              <LoginGuest />
            </Stack>
          </Bottom>
        </SliderBox>
        <SliderBox>
          <In>
            <OAuth />
            <SContinueWithEmailButton onClick={() => onEmailToggle(true)} />
          </In>
        </SliderBox>
        <SliderBox>
          {isEmailOpen && (
            <EmailAuth
              onBackButton={() => onEmailToggle(false)}
              onFocus={() => onKeyboardToggle?.(true)}
              onBlur={() => onKeyboardToggle?.(false)}
            />
          )}
        </SliderBox>
      </SliderIn>
    </SliderOut>
  );
};

import React, { useCallback } from "react";
import { incAdStack, setInviteDialog, useGetState } from "../ReduxProvider";
import { useDispatch } from "react-redux";
import { useOpenAdsDialog, useShouldPrepareAd } from "./adStack";
import AdPopup from "./AdPopup";
import { useViewerQuery } from "../../queries/viewerQuery";
import { useCounter } from "react-use";
import InternalAds from "./InternalAds";
import AdDialog from "./AdDialog";
import { isBrowser, isIos } from "tools/device";
import { useCanAdvertFriends } from "./useCanAdvertFriends";
import { useSubscriptions } from "root/profile/subscription/subscriptionHooks";
import { useSetFlag } from "../../queries/users/setFlag";
import { EXTERNAL_AD_COUNT_FLAG } from "../../queries/users/userFlagsModel";
import { useStoreIds } from "root/profile/subscription/subscriptionIds";
import * as Sentry from "@sentry/browser";
import { dispatchEvent, EVENT } from "tools/events";
// admob docs: https://admob-plus.github.io/docs/cordova/installation

type Phase = "external" | "internal";
type Itenerary = { 0?: Phase; 1?: Phase; skipDialog?: boolean; friendsPromo?: boolean }[];

const itenerary: Itenerary = [
  { 0: "internal" },
  { 0: "internal", friendsPromo: true },
  { 0: "external", 1: "internal", skipDialog: true }
  // continue with odd=internal / even=external
];

type Props = {};

const AdsLoader: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const openAdsDialog = useOpenAdsDialog();
  const shouldPrepareAd = useShouldPrepareAd();
  const [showPopup, setShowPopup] = React.useState(false);
  const [showInternalAd, setShowInternalAd] = React.useState(false);
  const consentChecked = React.useRef(false);
  const [admobReady, setAdmobReady] = React.useState(false);
  const advertFriends = useCanAdvertFriends();
  const { subscriptions } = useSubscriptions();
  const { viewer, getFlag } = useViewerQuery();
  const [stageCount, { inc: incStageCount }] = useCounter();
  const [phaseCount, { inc: incPhaseCount, reset: resetPhaseCount }] = useCounter();
  const interstitial = React.useRef<any | null>(null);
  const [continueClicked, setContinueClicked] = React.useState(false); // for hiding addialog after seeing ad
  const trackingDenied = useGetState("trackingDenied");
  const canRequestAdmobAds = useGetState("canRequestAdmobAds");
  const setFlag = useSetFlag();
  const externalAdCount = parseInt(getFlag(EXTERNAL_AD_COUNT_FLAG)?.value ?? "0");
  const { storeIdYear } = useStoreIds();

  const admob = window.admob;

  const stage = React.useMemo(() => itenerary[stageCount] || {}, [stageCount]);
  const phase: Phase = canRequestAdmobAds
    ? (stage && stage[phaseCount]) || (stageCount % 2 ? "internal" : "external")
    : "internal";

  const handleContinue = React.useCallback(() => {
    if (!isBrowser()) {
      if (!consentChecked.current) {
        dispatchEvent(EVENT.consentCheck);
        consentChecked.current = true;
      }

      if (admobReady && phase === "external") {
        try {
          interstitial.current?.show();
        } catch (e) {
          Sentry.captureException(e);
        }
      } else {
        setShowInternalAd(true);
        if (stage[phaseCount + 1] === "internal") incStageCount();
      }
    } else {
      setShowPopup(true);
    }
    setTimeout(() => {
      setContinueClicked(true);
    }, 500);
  }, [admobReady, incStageCount, stage, phaseCount, phase]);

  const handleAdClose = useCallback(() => {
    if (phase === "external") {
      setFlag({ name: EXTERNAL_AD_COUNT_FLAG, value: (externalAdCount + 1).toString() });
    }

    if (stage[phaseCount + 1] && !isBrowser()) {
      incPhaseCount();
      setTimeout(handleContinue);
    } else {
      if (stage.friendsPromo && advertFriends) dispatch(setInviteDialog(true));
      dispatch(incAdStack(null));
      setShowPopup(false);
      setAdmobReady(false);
      setShowInternalAd(false);
      incStageCount();
      resetPhaseCount();
    }
    setContinueClicked(false);
  }, [
    setShowPopup,
    dispatch,
    setAdmobReady,
    advertFriends,
    stage,
    incStageCount,
    incPhaseCount,
    resetPhaseCount,
    phaseCount,
    handleContinue,
    externalAdCount,
    phase,
    setFlag
  ]);

  const canPurchase = subscriptions?.[storeIdYear]?.canPurchase;

  // TODO: this hook is fired twice, not sure which dep is causing it
  React.useEffect(() => {
    if (admob && shouldPrepareAd && phase === "external" && canPurchase) {
      initAndLoadInterstitial({
        onLoad: () => setAdmobReady(true),
        onDismiss: handleAdClose,
        trackingDenied
      })
        .then((res) => {
          interstitial.current = res;
        })
        .catch((e) => {
          handleAdClose();
          Sentry.captureException(e);
        });
    }
  }, [shouldPrepareAd, admob, handleAdClose, phase, canPurchase, trackingDenied]);

  React.useEffect(() => {
    if (openAdsDialog && stage?.skipDialog) handleContinue();
  }, [openAdsDialog, stage?.skipDialog, handleContinue]);

  if (viewer?.subscription) return null;

  return (
    <>
      <AdDialog
        open={
          openAdsDialog &&
          !showPopup &&
          !showInternalAd &&
          (canPurchase || isBrowser()) &&
          !stage.skipDialog &&
          !continueClicked
        }
        onContinue={handleContinue}
      />

      <InternalAds open={showInternalAd && openAdsDialog} onClose={handleAdClose} />

      {showPopup && openAdsDialog && <AdPopup onClose={handleAdClose} />}
    </>
  );
};

export default AdsLoader;

type InitInterstitialOptions = {
  onLoad?: () => void;
  onDismiss?: () => void;
  trackingDenied?: boolean;
};

let interstitialInstance: any = null;

export const initInterstitial = (options?: InitInterstitialOptions) => {
  if (!window.admob) return null;
  const { onLoad, onDismiss, trackingDenied } = options || {};
  if (interstitialInstance) {
    // reuse existing interstitial, note that events are not reset,
    // if i create a new one and load, i got error "ad not found" on ios
    // todo: refactor this whole file, this is just a quick hack to get it working (but still take time to figure out why)
    return interstitialInstance;
  }

  const admob = window.admob;
  admob.configure({ appVolume: 0.4 });

  interstitialInstance = new admob.InterstitialAd({
    adUnitId:
      process.env.NODE_ENV === "development"
        ? "ca-app-pub-3940256099942544/4411468910" // testing ad
        : isIos()
          ? "ca-app-pub-5025823426896792/7180644611"
          : "ca-app-pub-5025823426896792/3450773857",

    ...(trackingDenied ? { npa: "1" } : {})
  });

  interstitialInstance.on("load", () => {
    onLoad?.();
  });

  interstitialInstance.on("dismiss", () => {
    onDismiss?.();
  });

  return interstitialInstance;
};

export const initAndLoadInterstitial = async (options?: InitInterstitialOptions): Promise<any> => {
  const interstitial = initInterstitial(options);
  await new Promise((done) => setTimeout(done, 10));
  await interstitial.load();
  return interstitial;
};

import React from "react";
import { useViewerQuery } from "queries/viewerQuery";
import { useLibraryQuery } from "queries/sources/libraryQuery";
import { isChromeExtension, isChromeExtensionLogin } from "tools/device";
import LoggedInChromeExtension from "components/other/LoggedInChromeExtension";
import ChromeExtensionLogin, { loginToExtensionHash } from "components/other/ChromeExtensionLogin";
import { useUpdateUserOnChanged } from "queries/users/editUser";
import LoginPage from "root/auth/LoginPage";
import ConnectionError from "./auth/ConnectionError";
import { useIsPublicUrl } from "root/RootRouter";
import { useGetState } from "components/ReduxProvider";
import { useUserSale } from "queries/users/userSaleQuery";

type Props = {
  children: React.ReactNode;
};

const ViewerWrapper: React.FC<Props> = ({ children }) => {
  const { viewer } = useViewerQuery();
  useLibraryQuery("store-only");
  useUserSale(true);
  useUpdateUserOnChanged();

  const token = useGetState("token");
  const isPublicUrl = useIsPublicUrl();

  let renderComponent: React.ReactNode = null;

  // if user is not logged in and try to open chrome extension, let's show him button to log in (which leads to new window)
  if (!token && isChromeExtension()) {
    // let's try to open the login window automatically (can fail due to browser blocking popup, but that's ok)
    window.open(`${window.location.protocol}//${window.location.host}${loginToExtensionHash}`, "duocards");
    renderComponent = <ChromeExtensionLogin />;
  }

  // if user logged in chrome extension through new window, let's tell him he can close the window now
  else if (token && isChromeExtensionLogin()) {
    renderComponent = <LoggedInChromeExtension />;
  } else if ((!isPublicUrl || window.location.hash === `#login` || window.location.hash === `#email`) && !token) {
    renderComponent = <LoginPage />;
  }

  //
  else {
    renderComponent = children;
  }

  return (
    <>
      <ConnectionError tokenIsSet={!!token} viewerIsLoaded={!!viewer} />

      {renderComponent}
    </>
  );
};

export default ViewerWrapper;

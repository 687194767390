import React from "react";
import { useSaleExpiration, useSubscriptions } from "./subscriptionHooks";
import { useStoreIds } from "./subscriptionIds";
import styled from "styled-components";
import { Trans, useTranslation } from "react-i18next";
import { format } from "date-fns";
import { SALE } from "sharedJs__generated/constants";
import Snowfall from "react-snowfall";

const Countdown = styled.div<{ blackFriday?: boolean }>`
  position: absolute;
  bottom: -12px;
  left: -2px;
  right: -2px;
  flex-shrink: 0;
  border-radius: 8px;
  background: ${({ theme }) => theme.duo.color.green};
  color: #fff;
  font-size: 12px;
  line-height: 1.6em;
  font-weight: 400;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 5px;
`;
const BlackFriday = styled(Countdown)`
  bottom: -30px;
  background: black;
`;
const ChristmasFamily = styled(Countdown)`
  bottom: 0;
  transform: translateY(calc(100% - 4px));
  margin: 0 -12px;
  padding: 8px;
  background: green;
  overflow: visible;
  white-space: wrap;
  line-height: 1.3em;
`;
const MotherLangDay = styled(Countdown)`
  bottom: -30px;
  overflow: visible;
  padding: 5px;
  margin: 0 -25px;
  transform: translateY(calc(100% - 47px));
  white-space: wrap;
  background: ${({ theme }) => theme.duo.color.primary};
`;
const Present = styled.div`
  position: absolute;
  right: -3px;
  bottom: -3px;
  font-size: 28px;
`;

const PriceSale = () => {
  const { saleExpiration, saleDiscount, saleActive, saleId } = useSaleExpiration();
  const { subscriptions } = useSubscriptions();
  const { storeIdYear } = useStoreIds();
  const [secondsLeft, setSecondsLeft] = React.useState(saleExpiration);
  const { t } = useTranslation();

  React.useEffect(() => {
    setSecondsLeft(saleExpiration);
    const interval = setInterval(() => setSecondsLeft((val) => (val > 0 ? val - 1 : 0)), 1000);
    return () => clearInterval(interval);
  }, [saleExpiration]);

  if (!subscriptions) return null;
  if (!saleActive && !subscriptions[storeIdYear]?.isTrial) return null;

  const formatTimeLeft = (time: number) => format(new Date(time * 1000), "HH:mm:ss");

  if (saleId === SALE.christmasFamily) {
    return (
      <ChristmasFamily>
        <Snowfall snowflakeCount={4} speed={[0.05, 0.05]} />
        <Trans>Same price as the individual subscription</Trans>
        <Present>🎁</Present>
      </ChristmasFamily>
    );
  }

  if (saleId === SALE.blackFriday) {
    return (
      <BlackFriday>
        -{saleDiscount}% {secondsLeft < 24 * 60 * 60 ? `| ${formatTimeLeft(secondsLeft)}` : null}
        <br />
        <strong>Black Friday</strong>
      </BlackFriday>
    );
  }

  if (saleId === SALE.motherLangDay) {
    return (
      <MotherLangDay>
        -{saleDiscount}% {secondsLeft < 24 * 60 * 60 ? `| ${formatTimeLeft(secondsLeft)}` : null}
        <br />
        <strong>
          <Trans>Mother Language Day</Trans>
        </strong>
      </MotherLangDay>
    );
  }

  return (
    <Countdown>
      {!subscriptions[storeIdYear]?.isTrial
        ? `-${saleDiscount}% | ${secondsLeft ? formatTimeLeft(secondsLeft) : t("season")}`
        : "7 days free"}
    </Countdown>
  );
};

export default PriceSale;

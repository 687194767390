import React from "react";
import styled from "styled-components/macro";
import { Backdrop, IconButton } from "@mui/material";
import { Portal } from "@mui/base";
import GrammarDictionary from "components/GrammarDictionary/GrammarDictionary";
import HintTooltip from "components/other/HintTooltip";
import { Grammar } from "dexieDB/dexie";
import { faBookOpenCover } from "@duocards/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { loopQuery_loop$data } from "queries/decks/__generated__/loopQuery_loop.graphql";
import { useGenerateGrammarMutation } from "queries/cards/generateGrammar";
import { useViewerDecks } from "queries/viewerQuery";
import { useGrammarQuotaExceeded } from "components/GrammarDictionary/grammarQuotaExceeded";
import { useLocation, useNavigate } from "react-router";

const AI_GRAMMAR_HINT = "aiGrammarTooltip";

const Wrap = styled.div``;
const SIconButton = styled(IconButton)`
  position: relative;

  && svg {
    width: 19px;
    height: 19px;
  }
`;
const SBackdrop = styled(Backdrop)`
  z-index: 1000;
`;
const SGrammarDictionary = styled(GrammarDictionary)`
  max-width: 90%;
  max-height: 90%;
`;

type Props = {
  card: loopQuery_loop$data["loop"][0];
  grammar?: readonly Grammar[] | null;
  canShowTooltip: boolean;
};

const getGrammarOpenHash = (cardId: string) => `grammar-open-${cardId}`;

const CardGrammarOpener: React.FC<Props> = React.memo(({ grammar, canShowTooltip, card }) => {
  const [delayedTooltip, setDelayedTooltip] = React.useState(canShowTooltip);
  const { deck } = useViewerDecks();
  const { generateGrammar, loading: generateGrammarLoading, data: generateGrammarData } = useGenerateGrammarMutation();
  const location = useLocation();
  const navigate = useNavigate();

  const cardGrammarHash = getGrammarOpenHash(card.id);
  const isGrammarOpen = location.hash === `#${cardGrammarHash}`;

  const grammarQuotaExceeded = useGrammarQuotaExceeded();

  React.useEffect(() => {
    setTimeout(() => setDelayedTooltip(canShowTooltip), 500);
  }, [canShowTooltip]);

  const handleClose = (e?: React.MouseEvent<HTMLElement>) => {
    e?.stopPropagation();
    navigate({ hash: "" }, { replace: true });
  };

  const handleGrammarClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    navigate({ hash: cardGrammarHash });

    if (!grammar && deck?.front && deck?.back && !grammarQuotaExceeded) {
      generateGrammar({
        variables: {
          text: card.front,
          fromLang: deck.front,
          toLang: deck.back
        }
      });
    }
  };

  return (
    <Wrap onClick={(e) => e.stopPropagation()}>
      {
        <HintTooltip
          title="This card is hard. Read more info about it to remember it better."
          canOpen={delayedTooltip}
          name={AI_GRAMMAR_HINT}
          placement="bottom"
        >
          <SIconButton onClick={handleGrammarClick}>
            <FontAwesomeIcon icon={faBookOpenCover} />
          </SIconButton>
        </HintTooltip>
      }

      {
        // cannot use WindowDialog because Contextor inside Mui-Dialog doesn't work
        isGrammarOpen && (
          <Portal>
            <SBackdrop
              open={true}
              onTouchStart={(e) => e.stopPropagation()}
              onMouseDown={(e) => e.stopPropagation()}
              onClick={handleClose}
            >
              <SGrammarDictionary
                grammar={grammar || generateGrammarData?.grammar}
                onClose={handleClose}
                loading={generateGrammarLoading}
              />
            </SBackdrop>
          </Portal>
        )
      }
    </Wrap>
  );
});

export default CardGrammarOpener;

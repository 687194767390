import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { Button, Typography, List, ListItem, ListItemButton, Box, listItemClasses } from "@mui/material";
import { Trans } from "react-i18next";
import { useGetState, useSetState } from "components/ReduxProvider";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import Flames from "../fire/Flames";
import { LoginMethodEnum } from "../../sharedJs__generated/constants";
import { usePreLoginQuery } from "../../root/auth/PreLogin";
import { useLogout } from "../../queries/users/logout";
import { useViewerQuery } from "queries/viewerQuery";
import { useEffectOnce } from "react-use";
import { useDeviceLang } from "../../hooks/deviceHooks";
import { useDeleteUserMutation } from "../../queries/users/deleteUser";
import MammothIcon from "../../icons/MammothIcon";
import DialogFullWindow from "components/other/DialogFullWindow";
import CrownIcon from "../../icons/CrownIcon";
import PersonIcon from "@mui/icons-material/Person";

const SButton = styled(Button)`
  margin: 7px 0;
`;
const SMammothIcon = styled(MammothIcon)`
  width: 70px;
  height: 70px;
  margin: 20px 0;
`;
const Title = styled.div`
  margin: 20px 30px;
  text-align: center;
`;
const SDialogFullWindow = styled(DialogFullWindow)`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;
const Bottom = styled.div`
  margin: 0 20px;
`;
const SListItem = styled(ListItem)`
  height: 64px;
  &&.${listItemClasses.divider} {
    border-color: ${({ theme }) => theme.duo.color.grey};
  }
`;
const SFlames = styled(Flames)`
  margin-left: auto;
`;
const Middle = styled.div`
  margin-left: 5px;
  height: 48px;
  align-content: center;
`;
const SImgWrap = styled.div`
  width: 30px;
`;
const SCrownWrap = styled.div`
  width: 20px;
  height: 20px;
`;
const SMailOutlineIcon = styled(MailOutlineIcon)`
  width: 20px;
  height: 20px;
`;
const SNewAccount = styled(PersonIcon)`
  width: 20px;
  height: 20px;
  margin-top: 6px;
`;

const DeviceUsersDialog: React.FC = () => {
  const { data: preLoginData, isLoading: preLoginLoading } = usePreLoginQuery();
  const deviceId = useGetState("deviceId");
  const logout = useLogout();
  const deleteUser = useDeleteUserMutation();
  const { viewer, isLoading: isLoadingViewer, isSubscriber } = useViewerQuery();
  const token = useGetState("token");
  const dialogOpen = useGetState("deviceUsersDialogOpen");
  const setDialogOpen = useSetState("deviceUsersDialogOpen");
  const { appLang } = useDeviceLang();
  const [prevToken, setPrevToken] = React.useState<string | undefined>("");
  const [prevEmail, setPrevEmail] = React.useState<string | undefined | null>(undefined);
  const [selectedEmail, setSelectedEmail] = useState<string | null>("");
  const isAccountSelected = selectedEmail !== "";
  const loggedByHash = useGetState("loggedByHash");

  useEffectOnce(() => {
    setPrevToken(token);
    setPrevEmail(viewer?.email);
  });

  useEffect(() => {
    if (viewer) {
      setSelectedEmail("");
    }
  }, [viewer]);

  React.useEffect(() => {
    if (
      token &&
      deviceId && // only on mobile
      viewer &&
      !isSubscriber &&
      !preLoginLoading &&
      !isLoadingViewer && // viewer is taking longer then preLogin, wait for both to finish
      prevToken !== token && // only for new login/registration
      prevEmail !== null && // only if he was not a guest changed to user
      preLoginData?.users &&
      preLoginData?.users?.length > 0 && // only if there are other users
      !(viewer?.email && !preLoginData?.users.find((u) => u.email === viewer?.email)) && // because webFunnelSelf is not in the list
      !(preLoginData?.users?.length === 1 && preLoginData?.users[0].email === viewer?.email) && // and the one user is not him
      !loggedByHash // did not come from forgottenPassword or loginLink
    ) {
      setDialogOpen(true);
      setPrevToken(token);
      setPrevEmail(viewer?.email);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, preLoginLoading, viewer?.email, isLoadingViewer]);

  const onSwitchAccount = () => {
    logout("/?switch_account=true");
    if (viewer?.createdBeforeSeconds && viewer?.createdBeforeSeconds < 60 && !viewer?.emailVerified) {
      deleteUser(false);
    }
    setPrevToken(undefined);
    setPrevEmail(undefined);
    setDialogOpen(false);
  };

  return (
    <SDialogFullWindow open={!!dialogOpen}>
      <div />
      <Title>
        <Box textAlign="center">
          <SMammothIcon />
        </Box>
        <Trans>
          Memo noticed that there are other accounts connected to this device. Select the account you want to continue
          with.
        </Trans>
      </Title>
      <List sx={{ paddingX: 2.5 }}>
        {viewer?.email === null && (
          <SListItem disablePadding divider>
            <ListItemButton onClick={() => setSelectedEmail(null)} selected={selectedEmail === null}>
              <SImgWrap>{<SNewAccount />}</SImgWrap>

              <Middle>
                <Trans>Continue as guest</Trans>
              </Middle>

              <SFlames strength={5} xps={0} />
            </ListItemButton>
          </SListItem>
        )}
        {preLoginData?.users?.map((user, index, userArray) => {
          return (
            <SListItem key={user.email} disablePadding divider={!(index === userArray.length - 1)}>
              <ListItemButton onClick={() => setSelectedEmail(user.email)} selected={selectedEmail === user.email}>
                <SImgWrap>
                  {user.loginMethod === LoginMethodEnum.google && (
                    <img src="https://www.google.com/favicon.ico" alt="google icon" width="20" height="20" />
                  )}
                  {user.loginMethod === LoginMethodEnum.apple && (
                    <img src="https://www.apple.com/favicon.ico" alt="apple icon" width="20" height="20" />
                  )}
                  {user.loginMethod === LoginMethodEnum.email && <SMailOutlineIcon />}
                  {user.subscription && (
                    <SCrownWrap>
                      <CrownIcon />
                    </SCrownWrap>
                  )}
                </SImgWrap>
                <Middle>
                  {user.email} <br />
                  {user.lastLoginAt && (
                    <Typography variant="caption">{new Date(user.lastLoginAt).toLocaleDateString(appLang)}</Typography>
                  )}
                </Middle>

                <SFlames strength={5} xps={user.flamesCount} />
              </ListItemButton>
            </SListItem>
          );
        })}
      </List>
      <Bottom>
        <SButton
          color="primary"
          fullWidth
          variant="contained"
          disabled={!isAccountSelected}
          onClick={() => {
            if (selectedEmail === viewer?.email) {
              setDialogOpen(false);
            } else if (selectedEmail !== viewer?.email) {
              onSwitchAccount();
            }
          }}
        >
          {isAccountSelected && selectedEmail !== viewer?.email ? (
            <Trans>Switch account</Trans>
          ) : (
            <Trans>Continue</Trans>
          )}
        </SButton>
      </Bottom>
    </SDialogFullWindow>
  );
};

export default DeviceUsersDialog;

export enum StepScreenId {
  WELCOME = "welcome",
  WELCOME_2 = "welcome-2",
  QUESTIONS_LANGUAGE = "questions-language",
  QUESTIONS_LANGUAGE_2 = "questions-language-2",
  QUESTIONS_LEVEL = "questions-level",
  QUESTIONS_NOTIFICATIONS = "questions-notifications",
  QUESTIONS_AGE = "questions-age",
  QUESTIONS_ROLE = "questions-role",
  TEACHERS_INFO = "teachers-info",
  QUESTIONS_MOTIVATION = "questions-motivation",
  QUESTIONS_REFERRALS = "questions-referrals",
  QUESTIONS_INTERESTS = "questions-interests",
  COURSE_PREPARATION = "course-preparation",
  FIRST_WEEK = "first-week",
  SET_EMAIL = "set-email", // only web funnel
  ACHIEVEMENTS = "achievements",
  FIRST_PAYWALL = "first-paywall",
  FOLLOW_EMAILED_INSTRUCTIONS = "follow-emailed-instructions", // only web funnel
  PREPARED_COURSE = "prepared-course",
  PREPARED_COURSE_OPENED = "prepared-course-opened", // virtual step just to track that the course was opened
  PREPARED_VIDEO = "prepared-video",
  PREPARED_ARTICLE = "prepared-article",
  PREPARED_VIDEO_OPENED = "prepared-video-opened", // virtual step
  PREPARED_ARTICLE_OPENED = "prepared-article-opened", // virtual step
  START_LEARNING = "start-learning"
}

export type OnboardingSubmitCallback = (canSubmit: boolean, submitFunction?: () => Promise<boolean>) => void;

export type OnboardingStepDataType<T extends React.FC<any> | undefined = undefined> = {
  screenId: StepScreenId;
  hideProgressBar?: boolean;
  hideSubmitButton?: boolean;
  motivation?: React.ReactNode;
  component?: T;
  componentProps?: T extends React.FC<infer P> ? P : never;
  optional?: boolean;
  canGoBack?: boolean;
  forceLargeMammoth?: boolean;
};

export type PreparedSources = {
  setOrCourseId?: string;
  videoId?: string;
  articleId?: string;
};

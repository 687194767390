import React from "react";
import styled, { css } from "styled-components/macro";
import { IconButton, Paper } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { SOURCE, getSourceEditUrl } from "./libraryUrls";
import { fromSourceOrDefault, imgLibRowFormat, ytbg } from "tools/unsplash";
import Difficulty from "./Difficulty";
import EditIcon from "@mui/icons-material/Edit";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { Difficulty as DifficultyT } from "../../../queries/sources/__generated__/sourceQuery.graphql";
import SubjectIcon from "@mui/icons-material/Subject";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import PlayArrowOutlinedIcon from "@mui/icons-material/PlayArrowOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { secToTime } from "tools/dateFns";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { brownDark } from "components/Theory/theoryStyles";
import useLangNative from "../../../hooks/useLangNative";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import PublicIcon from "@mui/icons-material/Public";
import { useViewerQuery } from "queries/viewerQuery";
import TopSource from "./components/TopSource";
import { libraryQuery_Public$data } from "queries/sources/__generated__/libraryQuery_Public.graphql";
import { useGetState } from "components/ReduxProvider";
import { useOpenCourseView } from "root/library/source/course/courseHooks";
import ContentTest from "./components/ContentTest";
import { useWindowSize } from "hooks/useWindowSize";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGraduationCap, faRectangleVerticalHistory } from "@duocards/pro-regular-svg-icons";

export const LibCardW = 155;
export const LibCardH = 140;
export const SET_CARD_CLS = (difficulty: DifficultyT) => "setCard-" + difficulty;
export const COURSE_CARD_CLS = (difficulty: DifficultyT) => "courseCard-" + difficulty;
export const SET_OR_COURSE_CLS = "setOrCourse";
export const CONTENTTEST_CLS = "contentTestCls";

const SPaper = styled(Paper)<{ $hide?: boolean; $responsive?: boolean }>`
  position: relative;
  flex-shrink: 0;
  margin: 5px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-size: ${({ theme }) => theme.duo.fontSize.small};
  font-weight: ${({ theme }) => theme.duo.fontWeight.w700};
  overflow: hidden;
  opacity: ${({ $hide }) => ($hide ? 0.5 : 1)};
  ${({ $responsive }) =>
    !$responsive &&
    css`
      width: ${LibCardW}px;
    `}
`;
const CheckedImg = styled.img`
  width: 50px;
  height: 50px;
`;
const Img = styled.div<{ shadow?: boolean }>`
  height: ${LibCardH}px;
  background-size: cover;
  background-position: center;
  position: relative;
  box-shadow: ${({ shadow }) => (shadow ? `inset 0 0 40px 2px black` : `none`)};
`;
const In = styled.div`
  height: 62px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 10px 14px;
`;
const Ellipsis = styled.div<{ mine: boolean }>`
  display: -webkit-box;
  line-height: 1.2em;
  max-height: 2.4em;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ mine, theme }) => (mine ? theme.duo.color.green : "inherit")};
`;
const Down = styled.div`
  position: absolute;
  bottom: 3px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 9.5px;
  color: ${({ theme }) => theme.duo.color.textGrey};
  line-height: 1.3em;
  font-weight: normal;
  svg {
    margin-left: 1px;
    width: 14px;
    height: 14px;
  }
  & > div {
    display: flex;
    align-items: center;
    margin: 0 5px;
  }
`;
const LinkFix = ({ sWidth, ...p }) => <Link {...(p as any)} />;
const SLink = styled(LinkFix)<{ sWidth?: number }>`
  display: block;
  ${({ sWidth }) =>
    sWidth &&
    css`
      width: ${sWidth}%;
    `}
`;
const Count = styled.div<{ red?: boolean }>`
  position: absolute;
  bottom: -2px;
  left: 0;
  background: ${({ theme, red }) => (red ? theme.duo.color.red : theme.duo.color.green)};
  box-shadow: 0 0 4px #fff5;
  height: 4px;
  padding: 0 5px;
  font-weight: normal;
  border-radius: 2px;
`;

const EditButton = styled(IconButton)`
  position: absolute;
  bottom: 55px;
  right: 0;
  svg {
    color: white;
    filter: drop-shadow(1px 1px 2px #000c);
  }
`;
const Center = styled.div<{ square?: boolean }>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background: #0007;
  margin: ${(p) => (p.square ? 48 : 41)}px auto 0;
  left: 0;
  right: 0;
  width: ${({ square }) => (square ? 44 : 60)}px;
  height: ${({ square }) => (square ? 44 : 60)}px;
  border-radius: ${({ square }) => (square ? 5 : 50)}px;
  svg {
    width: ${({ square }) => (square ? 26 : 32)}px;
    height: ${({ square }) => (square ? 26 : 32)}px;
  }
`;
const TheoryIconWrap = styled.div<{ $course: boolean }>`
  color: ${(p) => (p.$course ? p.theme.duo.color.lightBlue : brownDark)};
`;
const Green = styled.div`
  color: ${(p) => p.theme.duo.color.green};
`;
const Gold = styled.div`
  color: ${(p) => p.theme.duo.color.gold};
`;
const AdminControls = styled.div`
  position: absolute;
  left: 3px;
  bottom: 3px;
  display: flex;
`;
const SetIcon = styled(FontAwesomeIcon)`
  && {
    width: 11px;
    margin-left: 2px;
  }
`;

type LibSrcType = NonNullable<NonNullable<NonNullable<libraryQuery_Public$data["sources"]["edges"]>[0]>["node"]>;
type SourceType = Omit<LibSrcType, "contentTest"> & { contentTest?: LibSrcType["contentTest"] }; // other than library query doesn't have contentTest
type Props = {
  source: SourceType;
  responsive?: boolean;
  isLatestVisited?: boolean;
};

const SourceCard: React.FC<Props> = ({ source, responsive, isLatestVisited }) => {
  const { viewer } = useViewerQuery();
  const { width } = useWindowSize();
  const linkWidth = responsive ? (width > 450 ? 31.5 : 48) : undefined;
  const navigate = useNavigate();
  // todo: performance - langNative is dependend on fast changing location
  const langNative = useLangNative();
  const openCourseView = useOpenCourseView();
  const libTopic = useGetState("libTopic");

  const to = SOURCE.url(source.uri, (source.kind === "set" && langNative) || undefined, {
    list: source.mine
  });
  const image = fromSourceOrDefault(source);
  const backgroundImage = source.kind === "video" ? ytbg(source.source) : imgLibRowFormat(image.url);
  const myCards = (source.rating?.dismissed || 0) + (source.rating?.picked || 0);
  const percRaw = source.counts ? (myCards / source.counts.total) * 100 : 0;

  const perc = !!source?.rating?.finished
    ? 100
    : source.rating?.continueFrom && source.videoDuration
      ? (source.rating?.continueFrom / source.videoDuration) * 100
      : percRaw > 100
        ? 100
        : percRaw;

  const getSourceClass = (): string => {
    if (source.kind !== "set") return "";
    let cls = SET_OR_COURSE_CLS + " ";
    if (!isLatestVisited) {
      cls +=
        (source.course ? COURSE_CARD_CLS(source.difficulty) : SET_CARD_CLS(source.difficulty)) +
        (source.contentTest?.active ? " " + CONTENTTEST_CLS : "");
    }
    return cls;
  };

  const handleClick = (e) => {
    if (source.course && langNative) {
      e.preventDefault();
      openCourseView(source.uri, langNative, libTopic || "");
    }
  };

  return (
    <SLink to={to} className={getSourceClass()} data-id={source.id} sWidth={linkWidth} onClick={handleClick}>
      <SPaper $hide={perc > 99} $responsive={responsive}>
        <Img className="nofilter" style={{ backgroundImage }} shadow={source.kind === "video"}>
          {!!perc && (
            <Count
              red={!!source.rating?.continueFrom}
              style={{
                width: `${perc}%`
              }}
            />
          )}

          {source.kind === "video" && (
            <Center>
              <PlayArrowIcon />
            </Center>
          )}

          {source.kind === "article" && (
            <Center square>
              <SubjectIcon />
            </Center>
          )}

          {source.kind === "set" && source.course && perc <= 99 && (
            <Center square>
              <FontAwesomeIcon icon={faGraduationCap} />
            </Center>
          )}

          {perc > 99 && (
            <Center>
              <CheckedImg src={"https://cdn.duocards.com/course/checked.png"} />
            </Center>
          )}

          {Number(viewer?.admin) >= 2 && !isLatestVisited && (
            <AdminControls>
              <TopSource source={source} />
              {source.contentTest?.admin && <ContentTest testData={source.contentTest?.admin} />}
            </AdminControls>
          )}
        </Img>
        <In>
          <Ellipsis mine={!!source.mine}>{source.name}</Ellipsis>
          <Down>
            {source.mine && <>{source.private ? <LockOutlinedIcon /> : <PublicIcon />}</>}
            {source.kind === "set" && (
              <>
                <Green>
                  {source?.counts?.total} <SetIcon icon={faRectangleVerticalHistory} />
                </Green>
                {!!source?.counts?.theories && (
                  <TheoryIconWrap $course={!!source?.course}>
                    {source?.counts?.theories}{" "}
                    {source?.course ? <FontAwesomeIcon icon={faGraduationCap} /> : <AssignmentIcon />}
                  </TheoryIconWrap>
                )}
              </>
            )}
            {source.kind === "video" && (
              <Green>
                <PlayArrowOutlinedIcon />
                {secToTime(source?.videoDuration || 0)}
              </Green>
            )}
            {source.kind === "article" && (
              <Green>
                <DescriptionOutlinedIcon style={{ marginRight: 1 }} /> {Math.ceil(source?.textLength / 1000)} min
              </Green>
            )}
            <Gold>
              <StarBorderIcon />
              {source.avgRating || <span style={{ fontSize: "15px", marginTop: "-2px" }}>-</span>}
            </Gold>
          </Down>
        </In>
        <Difficulty difficulty={source.difficulty} />
        {source.mine && (
          <EditButton
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              navigate(getSourceEditUrl(source));
            }}
          >
            <EditIcon />
          </EditButton>
        )}
      </SPaper>
    </SLink>
  );
};

export default SourceCard;

import AppLang from "components/other/AppLang";
import { useGetState } from "components/ReduxProvider";
import styled from "styled-components";
import { topPadding } from "tools/device";
const Wrap = styled.div`
  position: absolute;
  top: ${topPadding}px;
`;

const Welcome: React.FC = () => {
  const abtest_removeLangPicker = useGetState("abtest_removeLangPicker") === "on";

  return <Wrap>{abtest_removeLangPicker ? null : <AppLang />}</Wrap>;
};

export default Welcome;

import { RatingStatus } from "types/LaunchReviewTypes";
import { isAndroid, isIos } from "tools/device";

export enum LaunchRatingResult {
  displayed = "displayed",
  notDisplayed = "notDisplayed",
  dismissed = "dismissed"
}

export const isLaunchRatingSupported = (): boolean => {
  return window.LaunchReview && window.LaunchReview.isRatingSupported() ? true : false;
};

const MAX_DIALOG_WAIT_TIME_ANDROID = 60 * 1000; //max time to wait for rating dialog to display on Android and be submitted by user
const MAX_DIALOG_WAIT_TIME_IOS = 5000; //max time to wait for rating dialog to display on iOS

// Documentation: https://github.com/dpa99c/cordova-launch-review?tab=readme-ov-file#usage
// Example: https://github.com/dpa99c/cordova-launch-review-example/blob/master/www/js/index.js (this code is based on this example)
function launchRating(): Promise<LaunchRatingResult> {
  return new Promise((resolve, reject) => {
    const launchReview = window.LaunchReview;
    if (!launchReview || !launchReview.isRatingSupported()) {
      resolve(LaunchRatingResult.notDisplayed);
      return;
    }

    function ratingDialogNotShown() {
      resolve(LaunchRatingResult.notDisplayed);
    }

    let ratingTimerId;
    if (isAndroid()) {
      ratingTimerId = setTimeout(ratingDialogNotShown, MAX_DIALOG_WAIT_TIME_ANDROID);
    }

    // Request the rating dialog
    launchReview.rating(
      function (status: RatingStatus) {
        if (status === "requested") {
          if (isAndroid()) {
            clearTimeout(ratingTimerId);
            resolve(LaunchRatingResult.displayed); //on Android, the dialog is shown immediately
          } else if (isIos()) {
            ratingTimerId = setTimeout(ratingDialogNotShown, MAX_DIALOG_WAIT_TIME_IOS);
          }
        } else if (status === "shown") {
          clearTimeout(ratingTimerId);
          resolve(LaunchRatingResult.displayed);
        } else if (status === "dismissed") {
          clearTimeout(ratingTimerId);
          resolve(LaunchRatingResult.dismissed);
        }
      },
      function (err) {
        clearTimeout(ratingTimerId);
        resolve(LaunchRatingResult.notDisplayed);
      }
    );
  });
}

export default launchRating;

import {
  CURRENT_DECK_ID_FLAG,
  YEAR_WRAPUP_FLAG,
  GOAL_FLAG_NAME,
  MY_DIFFICULTY_FLAG
} from "sharedJs__generated/constants";
import { viewerQuery$data } from "queries/__generated__/viewerQuery.graphql";

export const TUTORIAL_STEP_FLAG = "myTutorialStep";
export const LOCAL_STORAGE_KB = "myLocalStorageKb";
export const CHROME_EXTENSION_FLAG = "myChromeExtension";
export const MY_SORT_FLAG = "mySort";
export const EXTERNAL_AD_COUNT_FLAG = "myExternalAdCount";
export const APP_RATING_FLAG = "myAppRating";
export const LIKE_IT_RESULT_FLAG = "myLikeItResult";
export const NOTIFICATION_DIALOG_SEEN = "myNotificationDialogSeen";
export const ONBOARDING_DATA_FLAG = "myOnboardingData";
export const USER_PROFILE_FLAG = "myUserProfile";
export const CONSENT_CHECK_FLAG = "myConsentCheck";
export const UTMS_FLAG = "myUtms";

// onDemand abtests
// | `abtest_onDemand_${string}`
export type FlagName =
  | "abtest_removeLangPicker"
  | "abtest_featuresScreen"
  | "abtest_openLogin"
  | "abtest_maxCardsToLearnDialog"
  | "abtest_onDemand_generatedAppLangDialog"
  | "abtest_notificationSecondDialog"
  | "abtest_teachersOnboarding"
  | "abtest_withoutWelcome"
  | "abtest_allowNativeBack"
  | "abtest_newFirstPaywall"
  | "abtest_moreVisibleSkip"
  | typeof CURRENT_DECK_ID_FLAG
  | typeof TUTORIAL_STEP_FLAG
  | typeof CHROME_EXTENSION_FLAG
  | typeof GOAL_FLAG_NAME
  | typeof MY_SORT_FLAG
  | typeof EXTERNAL_AD_COUNT_FLAG
  | typeof APP_RATING_FLAG
  | typeof LIKE_IT_RESULT_FLAG
  | typeof NOTIFICATION_DIALOG_SEEN
  | typeof ONBOARDING_DATA_FLAG
  | typeof MY_DIFFICULTY_FLAG
  | typeof USER_PROFILE_FLAG
  | typeof CONSENT_CHECK_FLAG
  | typeof YEAR_WRAPUP_FLAG
  | typeof UTMS_FLAG;

type UserFlags = viewerQuery$data["viewer"]["flags"];
type UserFlag = UserFlags[0];

export type FlagFunc = (name: FlagName) => (UserFlag & { body: any }) | undefined;

export const hasUserFlag = (userFlags: UserFlags | undefined, name: string): UserFlag | undefined => {
  return userFlags?.find((flag) => flag.name === name);
};

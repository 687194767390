/**
 * @generated SignedSource<<9f65bea2f0a69a7db63f917f50f7dff2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type setFlagMutation$variables = {
  body?: string | null;
  name: string;
  remove?: boolean | null;
  ttlDays?: number | null;
  value?: string | null;
};
export type setFlagMutation$data = {
  readonly setUserFlag: {
    readonly flags: ReadonlyArray<{
      readonly body: string | null;
      readonly name: string;
      readonly value: string | null;
    }>;
  } | null;
};
export type setFlagMutation = {
  response: setFlagMutation$data;
  variables: setFlagMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "body"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "name"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "remove"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "ttlDays"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "value"
},
v5 = [
  {
    "kind": "Variable",
    "name": "body",
    "variableName": "body"
  },
  {
    "kind": "Variable",
    "name": "name",
    "variableName": "name"
  },
  {
    "kind": "Variable",
    "name": "remove",
    "variableName": "remove"
  },
  {
    "kind": "Variable",
    "name": "ttlDays",
    "variableName": "ttlDays"
  },
  {
    "kind": "Variable",
    "name": "value",
    "variableName": "value"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "body",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "setFlagMutation",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "setUserFlag",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserFlags",
            "kind": "LinkedField",
            "name": "flags",
            "plural": true,
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v4/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "setFlagMutation",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "setUserFlag",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserFlags",
            "kind": "LinkedField",
            "name": "flags",
            "plural": true,
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/)
            ],
            "storageKey": null
          },
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9de26799a2d9ff7efac64a5ab66a57a8",
    "id": null,
    "metadata": {},
    "name": "setFlagMutation",
    "operationKind": "mutation",
    "text": "mutation setFlagMutation(\n  $name: String!\n  $value: String\n  $remove: Boolean\n  $body: String\n  $ttlDays: Int\n) {\n  setUserFlag(name: $name, value: $value, remove: $remove, body: $body, ttlDays: $ttlDays) {\n    flags {\n      name\n      value\n      body\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "75a792fde85612fa0e03b06e881ca89a";

export default node;

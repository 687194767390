import React from "react";
import styled, { css } from "styled-components/macro";
import StatsPaper from "./StatsPaper";
import { useViewerDecks } from "../../../queries/viewerQuery";
import { useNavigate } from "react-router";
import { LEARN } from "../MainRouter";
import { useToggle } from "react-use";
import DialogWindow from "components/other/DialogWindow";
import { Trans, useTranslation } from "react-i18next";
import FilterIcon from "icons/FilterIcon";
import { Box, Button } from "@mui/material";
import { dispatchEvent, EVENT } from "../../../tools/events";
import { CardState } from "../../../queries/cards/__generated__/cardsQuery.graphql";
import { CardStateEnum } from "../../../sharedJs__generated/constants";
import { useIsOffline } from "tools/offline";

const Row = styled.div<{ isOffline?: boolean }>`
  display: flex;
  justify-content: space-between;
  height: 17%;
  max-height: 105px;
  ${({ isOffline }) =>
    isOffline &&
    css`
      pointer-events: none;
      opacity: 0.4;
    `}
`;
const Title = styled.h1<{ first?: boolean }>`
  font-size: 18px;
  font-weight: bold;
`;
const Img = styled.img`
  max-width: 120px;
  width: 100%;
  margin: 20px 0px;
`;
const PopupWrap = styled.section`
  padding: 0px 15px;
`;
const SFilterIcon = styled(FilterIcon)`
  fill: rgba(0, 0, 0, 0.54);
  width: 14px;
  height: 14px;
  position: relative;
  top: 2px;
  left: 2px;
`;

const CardStatistics = ({ ref }: { ref?: React.Ref<HTMLDivElement> }) => {
  const navigate = useNavigate();
  const { deck } = useViewerDecks();
  const { t } = useTranslation();
  const [toLearnPopupOpen, toggleToLearnPopupOpen] = useToggle(false);
  const [knownPopupOpen, toggleKnownPopupOpen] = useToggle(false);
  const [learnedPopupOpen, toggleLearnedPopupOpen] = useToggle(false);
  const isOffline = useIsOffline();

  if (!deck) return null;
  const { unknown, known, completed } = deck.stats;

  const closeOpenPopup = () => {
    if (toLearnPopupOpen) toggleToLearnPopupOpen();
    if (knownPopupOpen) toggleKnownPopupOpen();
    if (learnedPopupOpen) toggleLearnedPopupOpen();
  };

  const handleOpenCardList = (cardState: string) => {
    closeOpenPopup();
    dispatchEvent(EVENT.openCardList, { cardState: cardState as CardState });
  };

  return (
    <>
      <Row ref={ref} isOffline={isOffline}>
        <StatsPaper num={unknown} color="green" text={t("To learn")} onClick={toggleToLearnPopupOpen} showHelpIcon />
        <StatsPaper num={known} color="primary" text={t("Known")} onClick={toggleKnownPopupOpen} showHelpIcon />
        <StatsPaper num={completed} color="gold" text={t("Learned")} onClick={toggleLearnedPopupOpen} showHelpIcon />
      </Row>

      <DialogWindow
        open={toLearnPopupOpen}
        onClose={closeOpenPopup}
        wide
        title={
          <Title first>
            <Trans>Cards to learn</Trans>
          </Title>
        }
        bottom={
          <Box mt={1.5} mb={1.5}>
            <Button
              disabled={deck.stats.unknown === 0}
              variant="contained"
              color="primary"
              onClick={() => navigate(LEARN.url())}
            >
              <Trans>Start learning</Trans>
            </Button>
          </Box>
        }
      >
        <PopupWrap>
          <Trans parent="p">
            This number means how many cards are ready to be learnt based on our spaced-repetition algorithm.
          </Trans>
          <div>
            <Button onClick={() => handleOpenCardList(CardStateEnum.toLearn)}>
              <Trans>Show cards to learn</Trans>
            </Button>
          </div>
          <Img src="https://cdn.duocards.com/mail/mamooth-books.png" />
          <Title>
            <Trans>Spaced-repetition algorithm</Trans>
          </Title>
          <p>
            <Trans>
              Memo tracks every single one of your cards and knows when it is the right time to show it to you again.
            </Trans>{" "}
            <Trans>
              He knows which cards you sent to the right so he will show them to you again after a longer period of
              time.
            </Trans>{" "}
            <Trans>If you sent a card to the left, he will show it to you sooner.</Trans>
          </p>
        </PopupWrap>
      </DialogWindow>

      <DialogWindow
        open={knownPopupOpen}
        onClose={closeOpenPopup}
        wide
        title={
          <Title first>
            <Trans>Short-term memory</Trans>
          </Title>
        }
      >
        <PopupWrap>
          <Trans parent="p">This number means how many words and phrases you have in your short-term memory.</Trans>
          <Trans parent="p">
            You won't find these cards in learning mode for a while, unless you use the{" "}
            <b>
              <SFilterIcon /> filter
            </b>
            , where you can include cards that you already know.
          </Trans>

          <Button onClick={() => handleOpenCardList(CardStateEnum.known)}>
            <Trans>Show known cards</Trans>
          </Button>
        </PopupWrap>
      </DialogWindow>

      <DialogWindow
        open={learnedPopupOpen}
        onClose={closeOpenPopup}
        wide
        title={
          <Title first>
            <Trans>Long-term memory</Trans>
          </Title>
        }
      >
        <PopupWrap>
          <Trans parent="p">This number means how many words and phrases you have in your long-term memory.</Trans>

          <Img src="https://cdn.duocards.com/mail/mamooth-books.png" />
          <p>
            <Trans>
              Memo knows which cards you already remember well. He will show them to you again occasionally, just to
              make sure you don't forget them ever.
            </Trans>
          </p>
          <p>
            <Trans>
              You can list through these learned cards and reset their progress if you want to learn them again.
            </Trans>
          </p>
          <Button onClick={() => handleOpenCardList(CardStateEnum.learned)}>
            <Trans>Show learned cards</Trans>
          </Button>
        </PopupWrap>
      </DialogWindow>
    </>
  );
};

export default CardStatistics;

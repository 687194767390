import React from "react";
import styled from "styled-components/macro";
import { IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@duocards/pro-regular-svg-icons";
import { getTheme } from "styled/theme";
import WrapUpDialog, { useOpenWrapup } from "./dialog/WrapUpDialog";
import { useViewerQuery, useViewerXps } from "queries/viewerQuery";
import { useSetFlag } from "queries/users/setFlag";
import {
  MINIMUM_DAYS_USER_CREATED_BEFORE_WRAPUP,
  MINIMUM_WRAPUP_USERTOTAL_XP,
  YEAR_WRAPUP_FLAG
} from "sharedJs__generated/constants";
import { useLocation, useNavigate } from "react-router-dom";
import { WRAPUP_START_DATE, WRAPUP_END_DATE } from "sharedJs__generated/constants";
const StyledCalendarButton = styled(IconButton)`
  position: relative;
  margin-left: 8px;
  svg {
    color: ${({ theme }) => theme.duo.color.green};
    font-size: 24px;
  }
`;

const CalendarNumber = styled.div`
  position: absolute;
  font-size: 10px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -25%);
  color: ${({ theme }) => theme.duo.color.textGrey};
  font-weight: 800;
  color: ${({ theme }) => theme.duo.color.green};
`;

const WrapUpButton: React.FC = () => {
  const theme = getTheme();
  const openWrapup = useOpenWrapup();
  const { viewer, getFlag } = useViewerQuery();
  const setFlag = useSetFlag();
  const { xps } = useViewerXps();
  const handleWrapupClick = () => {
    openWrapup();
  };
  const location = useLocation();
  const navigate = useNavigate();
  const forceOpen = location.hash === "#wrapup";

  const eligible =
    viewer?.createdBeforeDays &&
    viewer?.createdBeforeDays > MINIMUM_DAYS_USER_CREATED_BEFORE_WRAPUP &&
    xps?.total &&
    xps?.total > MINIMUM_WRAPUP_USERTOTAL_XP &&
    Date.now() <= WRAPUP_END_DATE.getTime() &&
    Date.now() > WRAPUP_START_DATE.getTime();

  if (!eligible && !forceOpen) return null;

  return (
    <>
      <StyledCalendarButton onClick={handleWrapupClick}>
        <FontAwesomeIcon icon={faCalendar} color={theme.color.red} />
        <CalendarNumber>{WRAPUP_START_DATE.getFullYear() % 100}</CalendarNumber>
      </StyledCalendarButton>

      <WrapUpDialog
        open={getFlag(YEAR_WRAPUP_FLAG)?.value !== WRAPUP_START_DATE.getFullYear().toString() || forceOpen}
        onClose={() => {
          setFlag({ name: YEAR_WRAPUP_FLAG, value: WRAPUP_START_DATE.getFullYear().toString() });
          if (forceOpen) navigate({ hash: "" });
        }}
      />
    </>
  );
};

export default WrapUpButton;

import { isUuid } from "tools/validators";

export const REFERRER_PARAM = "r";
export const REFERRER_FROM_URL = "referrerFromUrl";

export const referrerUrl = ({
  referrer,
  noredirect,
  webFunnelUserId
}: {
  referrer?: string;
  noredirect?: boolean;
  webFunnelUserId?: string;
}) => {
  if (webFunnelUserId && referrer !== "webFunnelSelf") throw new Error("webFunnelUserId without 'webFunnelSelf'");
  if (webFunnelUserId && !isUuid(webFunnelUserId)) throw new Error("webFunnelUserId must be a valid UUID");

  const params = new URLSearchParams();
  if (noredirect) {
    params.set("noredirect", "1");
  }
  if (webFunnelUserId) {
    params.set("userId", webFunnelUserId);
  }
  return "https://get.duocards.com/r/" + referrer + "?" + params.toString();
};

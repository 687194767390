import React from "react";
import styled from "styled-components/macro";
import { Badge, BottomNavigation as BottomNavigationM, BottomNavigationAction } from "@mui/material";
import { useViewerDecks, useViewerQuery } from "queries/viewerQuery";
import { useLibraryUrl, useMatchMain } from "root/RootRouter";
import { FIVEHINT, MANY_UNKNOWN } from "root/main/main/Main";
import { devicePaddings } from "tools/device";
import Pulse from "./Pulse";
import { FIRSTLOOPDONE } from "root/main/learn/Learn";
import { useIsOffline } from "tools/offline";
import { TUTORIALDONE } from "root/tutorial/steps/Verdict";
import FADuotoneIcon from "icons/FADuotoneIcon";
import { useTranslation } from "react-i18next";
import { MAIN, PROFILE } from "../../root/RootRouter.config";
import { CONTENT_ID } from "components/header/constants";

export const BOTTOMNAVID = "bottomNav";

const { bottom } = devicePaddings();
const SBottomNavigation = styled(BottomNavigationM)`
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.duo.color.grey};
  background: white;
  height: ${56 + bottom}px;
  .Mui-disabled {
    color: ${({ theme }) => theme.duo.color.grey};
  }
  .Mui-selected {
    color: unset;
  }
  button {
    height: 56px;
  }
`;

type Props = {
  onChange: (event: React.ChangeEvent<{}>, value: string) => void;
  value: string;
};

const BottomNavigation: React.FC<Props> = (p) => {
  const { t } = useTranslation();
  const { hintWasSeen, viewer } = useViewerQuery();
  const { deck } = useViewerDecks();
  const pulsingLibrary = useMatchMain({ end: false }) && !deck?.stats.total && hintWasSeen(FIVEHINT);
  const libraryUrl = useLibraryUrl();
  const isOffline = useIsOffline();

  return (
    <SBottomNavigation onChange={p.onChange} value={p.value} id={BOTTOMNAVID}>
      <BottomNavigationAction
        value={MAIN.url()}
        onClick={() => document.getElementById(CONTENT_ID)?.scrollTo({ top: 0, behavior: "smooth" })}
        icon={
          <Badge invisible={(deck?.stats.unknown || 0) < MANY_UNKNOWN} color="primary" variant="dot">
            <FADuotoneIcon rotate={20} size={27} label={t("LEARNING")} switchedColors icon="learningCardsBlank" />
          </Badge>
        }
      />
      <BottomNavigationAction
        value={libraryUrl}
        disabled={isOffline}
        icon={
          <Pulse iterations={pulsingLibrary ? "infinite" : 0} bold>
            <FADuotoneIcon switchedColors label={t("LIBRARY")} icon="libraryBooks" />
          </Pulse>
        }
      />

      <BottomNavigationAction
        value={PROFILE.url()}
        disabled={isOffline}
        icon={
          <Badge
            invisible={
              !!viewer?.emailVerified ||
              (!hintWasSeen(TUTORIALDONE) && (!hintWasSeen(FIVEHINT) || !hintWasSeen(FIRSTLOOPDONE)))
            }
            variant="dot"
            color="error"
          >
            <FADuotoneIcon label={t("ACCOUNT")} icon="accountUserVNeck" />
          </Badge>
        }
      />
    </SBottomNavigation>
  );
};

export default BottomNavigation;

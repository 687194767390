import React from "react";
import { Quest } from "../Quest";
import { StreakRecoveryBubble } from "./StreakRecoveryBubble";

const StreakRecoveryQuest = () => {
  return (
    <Quest questName="StreakRecoveryQuest" requiredExps={0}>
      <StreakRecoveryBubble />
    </Quest>
  );
};

export default StreakRecoveryQuest;

import { useViewerQuery } from "../viewerQuery";
import { useSetFlag } from "./setFlag";
import { FlagName } from "./userFlagsModel";
import { useEffect } from "react";

// return one of three states:
// 1. undefined: user is not in the test group
// 2. true: user is in on test group
// 3. false: user is in off test group

export const useABTest = (testName: FlagName, { createIfNotExists = false }: { createIfNotExists?: boolean } = {}) => {
  const { getFlag } = useViewerQuery();
  const setFlag = useSetFlag();
  const abtest = getFlag(testName);
  const value = abtest ? abtest.value === "on" : undefined;

  useEffect(() => {
    if (value === undefined && createIfNotExists) {
      const isOn = Math.random() < 0.5;
      setFlag({
        name: testName,
        value: isOn ? "on" : "off"
      });
    }
  }, [testName, value, setFlag, createIfNotExists]);

  return value;
};

import { Button } from "@mui/material";
import React from "react";
import { Trans } from "react-i18next";
import { useNavigate } from "react-router";
import styled from "styled-components/macro";
import Loader from "components/other/Loader";
import { useLibraryQuery } from "../../../queries/sources/libraryQuery";
import { useViewerDecks, useViewerQuery } from "../../../queries/viewerQuery";
import { useLibraryUrl } from "../../RootRouter";
import { PICKED_FEW_CARDS } from "components/fire/xpHooks";
import { useGetState, useSetState } from "components/ReduxProvider";
import RecommendedSources from "components/RecommendedSources/RecommendedSources";
import LearnNoCardsSources from "./LearnNoCardsSources";
import PetableMammoth from "../../../components/mammoth/PetableMammoth";
import { faBooks } from "@duocards/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MAIN } from "../../RootRouter.config";

const SLoader = styled(Loader)`
  height: 220px;
`;
const SLibraryIcon = styled(FontAwesomeIcon)`
  font-size: 17px;
  padding-right: 3px;
`;

type Props = {};

const LearnNoCards: React.FC<Props> = () => {
  const { hintWasSeen } = useViewerQuery();
  const navigate = useNavigate();
  const libraryUrl = useLibraryUrl();
  const { isLoading } = useLibraryQuery();
  const pickedFewCards = useGetState("pickedFewCards");
  const { deck } = useViewerDecks();
  const setNoCardsDialogShown = useSetState("noCardsDialogShown");

  React.useEffect(() => {
    setNoCardsDialogShown(new Date().toString());
  });

  React.useEffect(() => {
    if (pickedFewCards && !hintWasSeen(PICKED_FEW_CARDS)) {
      navigate(MAIN.url(), { replace: true });
    }
  }, [pickedFewCards, hintWasSeen, navigate]);

  return (
    <>
      <PetableMammoth noPet gig={<Trans>Time to discover new words</Trans>}></PetableMammoth>
      {deck && <RecommendedSources lang={deck.front} />}

      {isLoading ? <SLoader /> : <LearnNoCardsSources />}
      <Button style={{ marginTop: 20 }} color="primary" onClick={() => navigate(libraryUrl)}>
        <SLibraryIcon icon={faBooks} /> <Trans>go to Library</Trans>
      </Button>
    </>
  );
};

export default LearnNoCards;

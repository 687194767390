import { HeaderLayout } from "components/header/Header";
import { useNavigate } from "react-router-dom";
import { FEATURE_TESTS, SWITCH_APP_SOURCE, TRANSLATIONS, USERS } from "./AdminRouter";
import Row from "components/other/Row";
import styled from "styled-components";
import BiotechIcon from "@mui/icons-material/Biotech";
import CodeIcon from "@mui/icons-material/Code";
import { isApp } from "tools/device";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import ViewHeadlineIcon from "@mui/icons-material/ViewHeadline";
import { useSetState } from "components/ReduxProvider";
import { useGetState } from "components/ReduxProvider";
import ConsoleIcon from "@mui/icons-material/Terminal";

export const AdminIndex = () => {
  const navigate = useNavigate();
  const logUIActive = useGetState("logUIActive");
  const setLogUIActive = useSetState("logUIActive");

  const Rows = styled.div`
    svg {
      margin-right: 10px;
    }
  `;

  return (
    <HeaderLayout title="Admin">
      <Rows>
        <Row onClick={() => navigate(USERS.url())}>
          <PersonOutlineIcon /> User actions
        </Row>
        <Row onClick={() => navigate(TRANSLATIONS.url())}>
          <ViewHeadlineIcon /> Translations
        </Row>
        {isApp() && (
          <Row onClick={() => navigate(SWITCH_APP_SOURCE.url())}>
            <CodeIcon />
            Switch app source
          </Row>
        )}
        <Row onClick={() => navigate(FEATURE_TESTS.url())}>
          <BiotechIcon />
          Feature tests
        </Row>
        <Row onClick={() => setLogUIActive(!logUIActive)}>
          <ConsoleIcon />
          {logUIActive ? "Disable ui logs" : "Enable ui logs"}
        </Row>
      </Rows>
    </HeaderLayout>
  );
};

import React from "react";
import styled from "styled-components";
import { Trans } from "react-i18next";
import { TextField } from "@mui/material";
import { useSetState } from "components/ReduxProvider";
import { UserEditQL } from "root/profile/UserEdit";
import { UserEditMutation } from "root/profile/__generated__/UserEditMutation.graphql";
import { useMutation } from "relay-hooks";
import OnboardingButton from "./OnboardingButton";
import useOnboardingData from "hooks/useOnboardingData";
import { useEffectOnce } from "react-use";

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;
const Inner = styled.div`
  padding: 10px 25px;
  background: white;
  border-radius: 50px;
  width: calc(100% - 80px);
`;

const isEmailValid = (email: string) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

const SetEmail = ({ onRequestSubmit }) => {
  const setLoaderGlobal = useSetState("loaderGlobal");
  const { saveOnboardingData } = useOnboardingData();
  const [editUser] = useMutation<UserEditMutation>(UserEditQL);
  const [email, setEmail] = React.useState("");
  const [disabled, setDisabled] = React.useState(true);

  const handleContinue = () => {
    setLoaderGlobal(true);
    editUser({
      variables: { email },
      onCompleted: () => {
        setLoaderGlobal(false);
        onRequestSubmit();
      },
      onError: () => {
        setLoaderGlobal(false); // on error user see will see "email is used..., try to login" and will stay on this step
      }
    });
  };

  // webfunnel users need to see notifications step when continuing in onboarding in App
  useEffectOnce(() => {
    saveOnboardingData({ notificationsPending: true });
  });

  return (
    <>
      <Wrap>
        <Inner>
          <TextField
            label={<Trans>Email</Trans>}
            name="email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setDisabled(!isEmailValid(e.target.value));
            }}
            variant="standard"
            autoComplete="email"
            autoFocus
            fullWidth
          />
        </Inner>
      </Wrap>

      <OnboardingButton onClick={handleContinue} disabled={disabled}>
        <Trans>Continue</Trans>
      </OnboardingButton>
    </>
  );
};

export default SetEmail;

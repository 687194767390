import React from "react";
import { Route, Routes, useNavigate } from "react-router";
import AdminUsers from "root/admin/adminUsers/AdminUsers";
import AdminTranslations from "./translations/AdminTranslations";
import { useViewerQuery } from "queries/viewerQuery";
import BaseUrlSettings from "./switchAppSource/BaseUrlSettings";
import FeatureTests from "./featureTests/FeatureTests";
import { ADMIN } from "../RootRouter.config";
import { AdminIndex } from "./AdminIndex";

export const USERS = {
  relative: "users",
  url: () => ADMIN.url() + "/" + USERS.relative
};
export const TRANSLATIONS = {
  relative: "translations",
  url: () => ADMIN.url() + "/" + TRANSLATIONS.relative
};
export const SWITCH_APP_SOURCE = {
  relative: "switch-app-source",
  url: () => ADMIN.url() + "/" + SWITCH_APP_SOURCE.relative
};
export const FEATURE_TESTS = {
  relative: "featue-tests",
  url: () => ADMIN.url() + "/" + FEATURE_TESTS.relative
};

type Props = {};

const AdminRouter: React.FC<Props> = () => {
  const { viewer } = useViewerQuery();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!viewer?.admin) navigate("/");
  });

  return (
    <Routes>
      <Route path={USERS.relative} element={<AdminUsers />} />
      <Route path={TRANSLATIONS.relative} element={<AdminTranslations />} />
      <Route path={SWITCH_APP_SOURCE.relative} element={<BaseUrlSettings />} />
      <Route path={FEATURE_TESTS.relative} element={<FeatureTests />} />
      <Route index element={<AdminIndex />} />
    </Routes>
  );
};

export default AdminRouter;

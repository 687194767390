import React from "react";
import { useMutation } from "relay-hooks";
import { CreateDeck } from "../../../queries/decks/createDeck";
import { useViewerDecks } from "queries/viewerQuery";
import DeckForm, { Values } from "../../main/deck/DeckForm";
import { OnboardingSubmitCallback } from "./onboardingTypes";

type LanguageSelectStep = "language-select-1" | "language-select-2";

const useCreateDeck = () => {
  const [createDeck] = useMutation(CreateDeck);
  return (values: Values) =>
    createDeck({ variables: { front: values.front, back: values.back } })
      .then(() => true)
      .catch(() => false);
};

interface LanguagesSelectProps {
  onCanSubmitChange: OnboardingSubmitCallback;
  step: LanguageSelectStep;
}

const LanguagesSelect: React.FC<LanguagesSelectProps> = ({ onCanSubmitChange, step }) => {
  const { decks } = useViewerDecks();
  const createDeck = useCreateDeck();

  const handleCanSubmitChange = (canSubmit: boolean, values: Values) => {
    if (step === "language-select-2") {
      onCanSubmitChange(canSubmit, () => createDeck(values));
    } else {
      onCanSubmitChange(canSubmit);
    }
  };

  React.useEffect(() => {
    if (step === "language-select-2" && decks?.length && decks[0].id) {
      onCanSubmitChange(true);
    }
  }, [decks, step, onCanSubmitChange]);

  return (
    <DeckForm
      inOnboarding={true}
      isStep1Done={step === "language-select-2"}
      onCanSubmitChange={handleCanSubmitChange}
      editId={decks?.[0]?.id}
    />
  );
};

export default LanguagesSelect;

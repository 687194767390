/**
 * @generated SignedSource<<e5ffa1fe35a294bf74ad438fb3520209>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type viewerQuery_xps$data = {
  readonly xps: {
    readonly currentStreak: number;
    readonly days: ReadonlyArray<{
      readonly added: number | null;
      readonly freeze: string | null;
      readonly reviewed: number | null;
      readonly streaked: boolean;
      readonly when: string;
      readonly xp: number | null;
    }>;
    readonly today: number;
    readonly todayExtra: number;
    readonly total: number;
    readonly yesterdayLostStreak: number;
  };
  readonly " $fragmentType": "viewerQuery_xps";
};
export type viewerQuery_xps$key = {
  readonly " $data"?: viewerQuery_xps$data;
  readonly " $fragmentSpreads": FragmentRefs<"viewerQuery_xps">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "viewerQuery_xps",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Xps",
      "kind": "LinkedField",
      "name": "xps",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "today",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "todayExtra",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "total",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "currentStreak",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "yesterdayLostStreak",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "DayXps",
          "kind": "LinkedField",
          "name": "days",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "xp",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "added",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "reviewed",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "when",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "streaked",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "freeze",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "3f7cd3da53156d37b6f26462801c2567";

export default node;

import React from "react";
import DialogFullWindow from "../other/DialogFullWindow";
import { useLocation, useNavigate } from "react-router-dom";
import Assistant from "./Assistant";
import { useEvent } from "react-use";
import { EVENT } from "../../tools/events";
import { DialogProps } from "@mui/material";
import { SuggestionDefinition } from "./suggestions/suggestionsDefinitions";
import { useCloseAssistant } from "./assistantHooks";
import { AssistantMode } from "./__generated__/AssistantMutation.graphql";
import { CardValues } from "../ReduxProvider";

export const ASSISTANT_HASH = "#assistant";

export type AssistantOpenSettings = {
  openAnimation?: DialogProps["TransitionComponent"];
  autoApplySuggestion?: SuggestionDefinition;
  autoApplyMode?: AssistantMode;
  cardContext?: CardValues;
};

type Props = {};

const AssistantPopup: React.FC<Props> = () => {
  const navigate = useNavigate();
  const closeAssistant = useCloseAssistant();
  const location = useLocation();
  const isOpen = location.hash === ASSISTANT_HASH;
  const animationRef = React.useRef<DialogProps["TransitionComponent"] | undefined>();
  const applySuggestion = React.useRef<SuggestionDefinition>();
  const applyMode = React.useRef<AssistantMode>();
  const cardContext = React.useRef<CardValues>();

  useEvent(EVENT.openAssistant, (e: CustomEvent<AssistantOpenSettings>) => {
    animationRef.current = e.detail.openAnimation;
    applySuggestion.current = e.detail.autoApplySuggestion;
    applyMode.current = e.detail.autoApplyMode;
    cardContext.current = e.detail.cardContext;
    navigate({ hash: ASSISTANT_HASH });
  });

  const handleClose = () => {
    closeAssistant();
  };

  return (
    <DialogFullWindow open={isOpen} maxWidth={600} allowDesktopPopup TransitionComponent={animationRef.current}>
      {isOpen && (
        <Assistant
          autoApplySuggestion={applySuggestion.current}
          onClose={handleClose}
          autoApplyMode={applyMode.current}
          cardContext={cardContext.current}
        />
      )}
    </DialogFullWindow>
  );
};

export default AssistantPopup;

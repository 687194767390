import { useCallback, useMemo } from "react";
import { QuestionType } from "root/tutorial/questions/questions";
import { useSetFlag } from "queries/users/setFlag";
import useUserProfile from "./useUserProfile";
import { useViewerQuery } from "queries/viewerQuery";
import { useEditUser } from "../queries/users/editUser";
import { dispatchEvent, EVENT } from "../tools/events";
import { MY_DIFFICULTY_FLAG } from "sharedJs__generated/constants";
type Answers = string[];

interface QuestionHandler {
  save: (answers: Answers) => void;
  get: () => Answers;
}

const useOnboardingQuestions = () => {
  const { saveUserProfileData, getUserProfileData } = useUserProfile();
  const setFlag = useSetFlag();
  const { getFlag, viewer } = useViewerQuery();
  const editUser = useEditUser();

  const handlers: Record<QuestionType, QuestionHandler> = useMemo(() => {
    const createProfileHandler = (key: QuestionType): QuestionHandler => ({
      save: (answers) =>
        saveUserProfileData({ [key]: [QuestionType.AGE, QuestionType.ROLE].includes(key) ? answers[0] : answers }),
      get: () => {
        const value = getUserProfileData()[key];
        return Array.isArray(value) ? value : value ? [value] : [];
      }
    });

    return {
      [QuestionType.LEVEL]: {
        save: (answers) => setFlag({ name: MY_DIFFICULTY_FLAG, value: answers[0] }),
        get: () => {
          const value = getFlag(MY_DIFFICULTY_FLAG)?.value;
          return value ? [value] : [];
        }
      },
      [QuestionType.NOTIFICATIONS]: {
        save: async (answers) => {
          const value = parseInt(answers[0]);
          await editUser({ coachLevel: value });
          dispatchEvent(EVENT.refreshNotifications);
        },
        get: () => {
          return [viewer!.coachLevel + ""];
        }
      },
      [QuestionType.AGE]: createProfileHandler(QuestionType.AGE),
      [QuestionType.INTERESTS]: createProfileHandler(QuestionType.INTERESTS),
      [QuestionType.REFERRALS]: createProfileHandler(QuestionType.REFERRALS),
      [QuestionType.MOTIVATION]: createProfileHandler(QuestionType.MOTIVATION),
      [QuestionType.ROLE]: createProfileHandler(QuestionType.ROLE)
    };
  }, [editUser, setFlag, getFlag, getUserProfileData, saveUserProfileData, viewer]);

  const saveQuestion = useCallback(
    ({ questionId, answers }: { questionId: QuestionType; answers: Answers }) => {
      handlers[questionId] ?? console.warn(`Unhandled question type: ${questionId}`);
      return handlers[questionId]?.save(answers);
    },
    [handlers]
  );

  const getAnswers = useCallback(
    (questionId: QuestionType): Answers => {
      handlers[questionId] ?? console.warn(`Unhandled question type: ${questionId}`);
      return handlers[questionId]?.get() ?? [];
    },
    [handlers]
  );

  return { saveQuestion, getAnswers };
};

export default useOnboardingQuestions;

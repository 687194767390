import React from "react";
import styled from "styled-components";
import { Stack } from "@mui/material";
import { useGetState, useSetState } from "components/ReduxProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCrown } from "@duocards/pro-solid-svg-icons";
import { useLogout } from "../../queries/users/logout";
import { useNavigate } from "react-router";
import { PROFILE } from "../../root/RootRouter.config";

const Container = styled(Stack)`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 40px;
  height: 40px;
  background: rgba(184, 0, 0, 0.9);
  color: white;
  z-index: 1000;
  cursor: pointer;
`;

const LogAdminBack: React.FC = () => {
  const adminOrigin = useGetState("adminOrigin");
  const logout = useLogout();
  const setToken = useSetState("token");
  const setAdminOrigin = useSetState("adminOrigin");
  const navigate = useNavigate();

  if (!adminOrigin) return null;

  const handleClick = async () => {
    await logout();
    setAdminOrigin(undefined);
    setToken(adminOrigin);
    navigate(PROFILE.url());
  };

  return (
    <Container alignItems="center" justifyContent="center" onClick={handleClick}>
      <FontAwesomeIcon icon={faUserCrown} />
    </Container>
  );
};

export default LogAdminBack;

import React from "react";
import styled from "styled-components/macro";
import { Trans } from "react-i18next";
import Streak from "./Streak";
import { useViewerXps } from "queries/viewerQuery";
import { Grid } from "@mui/material";

const Wrap = styled.div``;

const StreakRecoveryAchievement: React.FC = () => {
  const { xps } = useViewerXps();

  if (!xps) return null;

  return (
    <Wrap>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
          <Trans>You recovered your daily streak!</Trans>
        </Grid>
        <Grid item>
          <Streak days={xps.currentStreak} />
        </Grid>
      </Grid>
    </Wrap>
  );
};

export default StreakRecoveryAchievement;

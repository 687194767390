import React from "react";
import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import QuestReward from "./QuestReward";
import { PICKED_FEW_CARDS, PICKED_FEW_CARDS_COUNT } from "components/fire/xpHooks";
import { useSeeHint } from "components/hint/Hint";
import { useViewerDecks, useViewerXps } from "queries/viewerQuery";
import { useXpIncrement } from "components/fire/xpIncrement";
import { useGetState } from "components/ReduxProvider";
import { useAnimateTexts } from "hooks/useAnimateTexts";
import { LibTutorialPointer } from "root/tutorial/steps/BreakLibrary";
import { QuestMessage } from "./misc/QuestMessage";

const Text = styled.div<{ crossed?: boolean }>`
  text-decoration: ${({ crossed }) => (crossed ? `line-through` : `none`)};
`;

type Props = {};

const FewMoreCards: React.FC<Props> = () => {
  const seeHint = useSeeHint();
  const { xps } = useViewerXps();
  const { deck } = useViewerDecks();
  const xpIncrement = useXpIncrement();
  const pickedFewCards = useGetState("pickedFewCards");
  const incremented = React.useRef<boolean>(false);
  const [s0, s1, s2] = useAnimateTexts({ delay: 800, disable: pickedFewCards });
  const { t } = useTranslation();
  const cardsToAdd = String(PICKED_FEW_CARDS_COUNT - (deck?.stats?.total || 4)); // in case user deleted some cards already

  React.useEffect(() => {
    if (pickedFewCards && !incremented.current && xps!.total < 5) {
      xpIncrement(5 - xps!.total);
      incremented.current = true;
    }
  });

  return (
    <QuestMessage>
      <Text crossed={pickedFewCards}>
        <Trans parent="div" style={!pickedFewCards ? s1 : {}}>
          Help me light my fire!
        </Trans>
        <p style={{ margin: "12px 0 8px", ...(!pickedFewCards ? s2 : {}) }}>
          {t("Add 4 more words to your deck and learn them").replace("4", cardsToAdd)}
        </p>
      </Text>
      <QuestReward
        claim={pickedFewCards}
        onDone={() => setTimeout(() => seeHint(PICKED_FEW_CARDS), 2000)}
        style={!pickedFewCards ? s0 : {}}
      />

      {!pickedFewCards && <LibTutorialPointer delayed />}
    </QuestMessage>
  );
};

export default FewMoreCards;

import React from "react";
import { useMatch, useNavigate } from "react-router";
import { FIVEHINT } from "root/main/main/Main";
import { useViewerDecks, useViewerQuery } from "queries/viewerQuery";
import { FIRSTLOOPDONE } from "root/main/learn/Learn";
import { TUTORIALDONE } from "./steps/Verdict";
import { DECK } from "root/main/MainRouter";
import { PETMAMMOTH } from "./steps/PetMammoth";
import { LIBRARY, MAIN, ONBOARDING } from "../RootRouter.config";
import { useMatchMain } from "root/RootRouter";
import { useLocation } from "react-router";
import { useOnboardingStepsData } from "./onboarding/onboardingStepsData";
import { StepScreenId } from "./onboarding/onboardingTypes";
import { getStepHash } from "./onboarding/OnboardingRedirect";
// if user is still in first loop, should be at source at all
export const useRedirectIfTutorialInLoop = () => {
  const { hintWasSeen } = useViewerQuery();
  const navigate = useNavigate();
  const inLibrary = useMatch(LIBRARY.root() + "/*");
  React.useEffect(() => {
    if (inLibrary && !hintWasSeen(TUTORIALDONE) && !hintWasSeen(FIRSTLOOPDONE) && hintWasSeen(FIVEHINT)) {
      navigate(MAIN.url(), { replace: true });
    }
  });
};

export const useNoDecksRedirect = () => {
  const { decks } = useViewerDecks();
  const navigate = useNavigate();
  const location = useLocation();
  const { hintWasSeen } = useViewerQuery();
  const inMain = useMatchMain();
  const inMainExact = useMatch({ path: MAIN.url(), end: true });
  const onboardingSteps = useOnboardingStepsData();

  React.useEffect(() => {
    if (decks === undefined || decks.length > 0 || !inMain) return;

    const hasOnboardingCompleted = hintWasSeen(PETMAMMOTH) || hintWasSeen(TUTORIALDONE);
    if (hasOnboardingCompleted) {
      location.pathname !== DECK.url() && navigate(DECK.url(), { state: { noBack: true } });
    } else {
      const selectLanguageStep = onboardingSteps.find((step) => step.screenId === StepScreenId.QUESTIONS_LANGUAGE);
      if (inMainExact && selectLanguageStep) {
        navigate(ONBOARDING.url() + getStepHash(selectLanguageStep.screenId));
      }
    }
  }, [decks, navigate, hintWasSeen, inMainExact, inMain, location.pathname, onboardingSteps]);
};

import React from "react";
import { useGetState, useSetState } from "components/ReduxProvider";
import DialogWindow from "components/other/DialogWindow";
import { useViewerDecks, useViewerQuery } from "queries/viewerQuery";
import { useSourceQuery } from "queries/sources/sourceQuery";
import CourseBlocks from "root/library/source/course/CourseBlocks";
import styled from "styled-components/macro";
import Button from "@mui/material/Button";
import { Trans, useTranslation } from "react-i18next";
import { isNil } from "lodash";
import { SOURCE } from "root/library/library/libraryUrls";
import { useLocation, useMatch } from "react-router";
import { useNavigate } from "react-router-dom";
import { TUTORIALDONE } from "root/tutorial/steps/Verdict";
import { MANY_UNKNOWN } from "root/main/main/Main";
import { Stack, Box, Typography, Slide } from "@mui/material";
import { LEARN } from "root/main/MainRouter";
import StickyBottom from "components/other/StickyBottom";
import CourseViewTutorialHand from "root/library/source/course/CourseViewTutorialHand";
import { useEffectOnce } from "react-use";
import { GreyArrow } from "../../../../components/mammoth/GreyBubble";
import MammothWithBubble, { MammothVariant } from "../../../tutorial/onboarding/MammothWithBubble";
import PetableMammoth from "../../../../components/mammoth/PetableMammoth";
import { isApp } from "../../../../tools/device";

export const COURSE_VIEW_HASH = "#courseView";

const SDialogWindow = styled(DialogWindow)`
  .MuiDialog-paper {
    width: 100%;
  }

  @media (max-width: 580px) {
    .MuiDialog-paper {
      margin-left: 20px;
      margin-right: 20px;
    }
  }
  @media (max-width: 490px) {
    .MuiDialog-paper {
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  ${GreyArrow} {
    // delete this code when abtest_maxCardsToLearnDialog is accepted
    background-color: white;
  }
`;
const SStack = styled(Stack)`
  margin: 40px auto 60px;
  max-width: 400px;
  font-size: 117%; // inner 85% -> 100%
  padding: 0 15px;
`;

type Props = {};

const CourseView: React.FC<Props> = () => {
  const location = useLocation();
  const isOpen = location.hash === COURSE_VIEW_HASH;
  const courseView = useGetState("courseView");
  const { decks, deck } = useViewerDecks();
  const pickedCardsDeck = decks?.find((d) => d.front === courseView.langLearning);
  const setLoaderGlobal = useSetState("loaderGlobal");
  const source = useSourceQuery(
    isOpen ? courseView.sourceUriOrId : "",
    {
      id: pickedCardsDeck?.id,
      back: courseView.langNative
    },
    "store-or-network"
  );
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { hintWasSeen, getFlag } = useViewerQuery();
  const inSource: boolean = !!useMatch(SOURCE.query());
  const maxCardsToLearnDialog = getFlag("abtest_maxCardsToLearnDialog")?.value === "on";

  const inTutorialStep: boolean = isOpen && !hintWasSeen(TUTORIALDONE) && !inSource;

  const [tooManyLayout, setTooManyLayout] = React.useState<boolean>(false);
  const [tooManyShown, setTooManyShown] = React.useState(false);
  const [editingTheory, setEditingTheory] = React.useState(false);
  const [introEnded, setIntroEnded] = React.useState(false);
  const isNewCourse = isNil(source?.sCards?.[0]?.isInMyDeck);

  const callToActionText = tooManyLayout
    ? "Continue course"
    : courseView.openTheoryByCardId && !inSource
      ? "Go to course"
      : isNewCourse
        ? "Start"
        : "Continue";

  React.useEffect(() => {
    if (!tooManyShown && courseView.canShowTooMany && deck && deck.stats.unknown >= MANY_UNKNOWN) {
      setTooManyLayout(true);
      setTooManyShown(true);
    }
  }, [tooManyShown, courseView.canShowTooMany, deck]);

  // show loader until source is loaded
  React.useEffect(() => {
    setLoaderGlobal(isOpen && !source);
  }, [isOpen, source, setLoaderGlobal]);

  // if we open the app with course view hash, let's remove the hash
  useEffectOnce(() => {
    if (isOpen && !courseView.sourceUriOrId) {
      navigate({ hash: "" }, { replace: true });
    }
  });

  const handleEditingTheory = (to: boolean) => {
    setEditingTheory(to);
  };

  const handleClose = () => {
    navigate(-1);
  };

  const handleStart = () => {
    if (tooManyLayout) {
      setTooManyLayout(false);
      return;
    }

    if (source) {
      navigate(SOURCE.url(source.uri, courseView.langNative), { replace: true });
    }
  };

  if (!isOpen || !source) return null;

  return (
    <SDialogWindow
      open={isOpen}
      onClose={editingTheory || inTutorialStep || tooManyLayout ? undefined : handleClose}
      wide={isApp()}
      consumeHeader
      noContentPadding
      closeButtonInCircle
    >
      {tooManyLayout && (
        <>
          <Box sx={{ marginBottom: 3, marginTop: 5, paddingLeft: 2, paddingRight: 2 }}>
            <Typography variant="h6">
              <Trans>You have finished a lesson</Trans>
            </Typography>
          </Box>
          <SStack direction="column" justifyContent="space-around">
            {maxCardsToLearnDialog ? (
              <MammothWithBubble variant={MammothVariant.Large}>
                <>
                  <Trans parent="p">You have enough cards to learn now.</Trans>
                  <Trans parent="p">
                    The most effective way is to learn new words while there are not too many of them.
                  </Trans>
                </>
              </MammothWithBubble>
            ) : (
              <PetableMammoth
                gig={
                  <>
                    <Trans parent="p">You have enough cards to learn now.</Trans>
                    <Trans parent="p">
                      The most effective way is to learn new words while there are not too many of them.
                    </Trans>
                  </>
                }
              />
            )}
          </SStack>
        </>
      )}
      {!tooManyLayout && (
        <CourseBlocks
          source={source}
          allowScrollAnimation={courseView.allowScrollAnimation}
          openTheoryByCardId={courseView.openTheoryByCardId}
          onTheoryEditToggle={handleEditingTheory}
          onIntroEnd={(ended) => setIntroEnded(ended)}
          playIntro={isNewCourse}
        />
      )}
      <Slide direction="up" in={!editingTheory && (!isNewCourse || introEnded)} mountOnEnter unmountOnExit>
        <StickyBottom>
          <Stack alignItems="center" spacing={2.5} direction="column" sx={{ padding: "0 20px" }}>
            <Button
              id="course-view-action-button"
              color="primary"
              variant={tooManyLayout ? "outlined" : "contained"}
              fullWidth={maxCardsToLearnDialog}
              onClick={handleStart}
            >
              {t(callToActionText)}
            </Button>

            {tooManyLayout && (
              <Button
                variant="contained"
                fullWidth={maxCardsToLearnDialog}
                onClick={() => {
                  setTooManyLayout(false);
                  navigate(LEARN.url());
                }}
              >
                {t("Learn")}
              </Button>
            )}
          </Stack>
          {inTutorialStep && <CourseViewTutorialHand />}
        </StickyBottom>
      </Slide>
    </SDialogWindow>
  );
};

export default CourseView;

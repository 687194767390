import { graphql } from "babel-plugin-relay/macro";

export const CreateSCard = graphql`
  mutation createSCardMutation(
    $sourceId: ID!
    $front: String!
    $hint: String
    $back: String
    $theory: String
    $backLang: String
    $svg: FlatIconEdit
  ) {
    createSCard(
      sourceId: $sourceId
      front: $front
      hint: $hint
      back: $back
      theory: $theory
      backLang: $backLang
      svg: $svg
    ) {
      id
      sCards(backLang: $backLang) {
        id
        front
        hint
        sourceId
        back {
          value
          translated
        }
        theory(langNative: $backLang) {
          sCardId
          theory
          theoryIsChecked
          theoryNative
          theoryNativeIsChecked
          theoryEn
          lang
          langNative
          canEdit
        }
        svg {
          flatId
          url
        }
      }
    }
  }
`;

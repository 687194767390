import React from "react";
import { Trans } from "react-i18next";
import styled, { keyframes } from "styled-components/macro";
import DialogWindow from "../../other/DialogWindow";
import { MammothVariant, SpeechBubble } from "../../../root/tutorial/onboarding/MammothWithBubble";
import { Button } from "@mui/material";
import { useViewerQuery } from "queries/viewerQuery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@duocards/pro-solid-svg-icons";
import { fromGlobalId } from "graphql-relay";
import { inAppBrowser } from "tools/device";
import { WRAPUP_START_DATE } from "sharedJs__generated/constants";

const ContentWrapper = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

const ImageSection = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 24px;
`;

const StyledImage = styled.img`
  width: 100%;
  max-width: 320px;
  height: auto;
  object-fit: contain;
  margin-top: -20px;
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const StyledButton = styled(Button)`
  background: ${({ theme }) => theme.duo.color.themeGradient} !important;
  opacity: 0;
  animation: ${fadeIn} 1s ease-in-out 1s forwards;
  &:hover {
    background: ${({ theme }) => theme.duo.color.themeGradient};
    opacity: 0.9;
  }
`;

const CalendarIconWrapper = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
`;

const CalendarNumber = styled.span`
  position: absolute;
  left: 52%;
  top: 50%;
  transform: translate(-50%, -30%);
  font-size: 12px;
  font-weight: bold;
  color: ${({ theme }) => theme.duo.color.primary};
`;

type Props = {
  open: boolean;
  onClose: () => void;
};

const WrapUpDialog: React.FC<Props> = ({ open, onClose }) => {
  const openWrapup = useOpenWrapup();

  const handleOpenWrapup = () => {
    openWrapup();
    onClose();
  };

  return (
    <DialogWindow open={open} onClose={onClose} fullWidth={true}>
      <ContentWrapper>
        <ImageSection>
          <SpeechBubble $variant={MammothVariant.Large}>
            {/* TODO: change year */}
            <Trans>Time to celebrate your 2024 achievements! Take a look at how far you've come!</Trans>{" "}
          </SpeechBubble>
          {/* TODO: change image */}
          <StyledImage src="https://imgs.duocards.com/wrapup/memo_2024.png" alt="mammoth" />{" "}
        </ImageSection>
        <StyledButton
          size="large"
          startIcon={
            <CalendarIconWrapper>
              <FontAwesomeIcon icon={faCalendar} />
              <CalendarNumber>{WRAPUP_START_DATE.getFullYear() % 100}</CalendarNumber>
            </CalendarIconWrapper>
          }
          onClick={handleOpenWrapup}
          variant="contained"
        >
          <Trans>View Stats!</Trans>
        </StyledButton>
      </ContentWrapper>
    </DialogWindow>
  );
};

// keep in mind that this need to be https some browser deny share feature with http
function getWrapupBaseUrl(): string {
  const apiUrl = process.env.REACT_APP_API || "http://localhost:4000";
  const url = new URL(apiUrl);
  return `${url.protocol}//${url.host}`;
}

export const useOpenWrapup = () => {
  const { viewer } = useViewerQuery();

  return React.useCallback(() => {
    if (!viewer?.id) return;
    const { id: decodedId } = fromGlobalId(viewer?.id);
    const baseUrl = getWrapupBaseUrl();
    const url = `${baseUrl}/wrapup/${decodedId}`;
    const browser = inAppBrowser();
    if (browser) {
      browser.open(url, "_system");
    } else {
      window.open(url, "_blank");
    }
  }, [viewer?.id]);
};

export default WrapUpDialog;

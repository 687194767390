import React from "react";
import { Formik, Form } from "formik";
import styled from "styled-components";
import { Modal, IconButton, Button, Box, CircularProgress } from "@mui/material";
import { FTextField } from "../../../styled/TextField";
import { FFileField } from "../../../styled/FileField";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { FormikHelpers } from "formik";
import { graphql } from "babel-plugin-relay/macro";
import { useMutation } from "relay-hooks";
import { useNavigate } from "react-router";
import { snackbar } from "tools/events";
import { useViewerQuery } from "queries/viewerQuery";
import { SupportFeedbackModalMutation } from "./__generated__/SupportFeedbackModalMutation.graphql";
import { useGetState } from "components/ReduxProvider";
import { FeedbackMessage } from "./MessageBubbles";
import { FeedbackField } from "./AIFeedbackForm";

const FeedbackQL = graphql`
  mutation SupportFeedbackModalMutation($email: String!, $feedback: String!, $attachment: File, $relatedTo: RelatedTo) {
    feedback(email: $email, feedback: $feedback, attachment: $attachment, relatedTo: $relatedTo)
  }
`;

const stringifyFeedbackMessages = (feedback: FeedbackMessage[]) =>
  feedback.map((m) => `${m.owner}: ${m.text}`).join("\n");

export const StyledModalBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 400px;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.background.paper};
  box-shadow: 24px;
  padding: 24px;
  border-radius: 16px;
  padding-top: 24px;
`;

type Props = {
  open: boolean;
  onClose: () => void;
  relatedTo: { text: string } | undefined;
  additionalInfo?: string;
};

const SupportFeedbackModal: React.FC<Props> = ({ open, onClose, relatedTo, additionalInfo }) => {
  const { t } = useTranslation();
  const { viewer } = useViewerQuery();
  const init = { email: viewer?.email || "", attachment: null, feedback: "" };
  const [feedbackMutation] = useMutation<SupportFeedbackModalMutation>(FeedbackQL);
  const navigate = useNavigate();
  const lastFeedback = useGetState("lastFeedback");
  const messages: FeedbackMessage[] = lastFeedback?.relatedTo === relatedTo?.text ? lastFeedback?.messages || [] : [];

  const handleSubmit = (variables: typeof init, helpers: FormikHelpers<typeof init>) => {
    feedbackMutation({
      variables: {
        ...variables,
        relatedTo,
        feedback:
          stringifyFeedbackMessages([...messages, { text: variables.feedback, owner: "user" }]) +
          "\n\n" +
          (additionalInfo || "")
      },
      onCompleted: () => {
        onClose();
        snackbar(t("Message sent, we'll get back to you via email!"));
        navigate(-1);
        helpers.setSubmitting(false);
        helpers.resetForm();
      },
      onError: () => {
        helpers.setSubmitting(false);
        helpers.resetForm();
      }
    });
  };

  return (
    <Modal open={open} onClose={onClose}>
      <StyledModalBox>
        <IconButton style={{ position: "absolute", top: 10, right: 10 }} onClick={onClose}>
          <CloseIcon />
        </IconButton>
        {messages.length > 0 ? (
          <p>
            {t("Your chat history will be sent. We'll get back to you via email! Attach a screenshot if it helps.")}
          </p>
        ) : (
          <p>{t("We'll get back to you via email! Attach a screenshot if it helps.")}</p>
        )}
        <Formik initialValues={init} onSubmit={handleSubmit}>
          {({ isSubmitting }) => (
            <Form autoComplete="off">
              <FTextField disabled={viewer?.email ? true : false} name="email" required label="Email" type="email" />
              <FeedbackField name="feedback" label={t("More details - optional")} autoFocus multiline />
              <FFileField name="attachment" label={t("attach photo")} />
              <Button variant="contained" color="primary" fullWidth type="submit" disabled={isSubmitting}>
                {isSubmitting ? <CircularProgress /> : t("Send to support")}
              </Button>
            </Form>
          )}
        </Formik>
      </StyledModalBox>
    </Modal>
  );
};

export default SupportFeedbackModal;

import { Trans } from "react-i18next";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { getStepHash } from "./OnboardingRedirect";
import { ONBOARDING } from "root/RootRouter.config";
import { StepScreenId } from "./onboardingTypes";
import DownloadButton from "root/auth/DownloadButton";
import { useEffectOnce } from "react-use";
import { useViewerQuery } from "queries/viewerQuery";
import { referrerUrl } from "components/initializers/ReferrerCounter.model";
import { fromGlobalId } from "graphql-relay";

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  width: 100%;
`;
const Inner = styled.div`
  padding: 25px;
  background: white;
  border-radius: 50px;
  width: 100%;
`;
const Instruction = styled.div`
  margin: 1em 1em 1.5em;
  font-weight: bold;
`;
// const MailIcon = styled(MarkEmailUnreadIcon)`
//   font-size: 70px;
//   margin: 10px;
//   color: ${({ theme }) => theme.duo.color.primary};
// `;
// const StyledA = styled.a`
//   text-decoration: none;
//   display: block;
// `;
const BottomLinks = styled.div`
  color: ${({ theme }) => theme.duo.color.textGrey};
  font-size: 13px;
  span {
    cursor: pointer;
    padding: 0 2px;
  }
`;

const FollowEmailedInstructions = ({ onRequestSubmit }) => {
  const navigate = useNavigate();
  const { viewer } = useViewerQuery();

  /**
   * webFunnelSelf
   *  - this will create row in referrer table
   *  - after user installs the app, it will log him in thanks to prelogin method in auth.ts
   */
  useEffectOnce(() => {
    fetch(referrerUrl({ referrer: "webFunnelSelf", noredirect: true, webFunnelUserId: fromGlobalId(viewer?.id!).id }));
  });

  return (
    <>
      <Wrap>
        <Inner>
          {/* TODO: cleanup other text ideas in the future */}

          {/*    <Instruction>
            1. <Trans>Download our app</Trans>
          </Instruction>
          <DownloadButton>
            <Trans>Install DuoCards</Trans>
          </DownloadButton>
          <Instruction>
            2. <Trans>Log in using the link in the email we just sent you.</Trans>
          </Instruction>
          <StyledA href="mailto:">
            <MailIcon />
          </StyledA>
          <BottomLinks>
            <span onClick={() => navigate(ONBOARDING.url() + getStepHash(StepScreenId.SET_EMAIL))}>
              <Trans>I did not receive the email</Trans>
            </span>
            {isDesktop() && (
              <>
                {" "}
                •{" "}
                <span onClick={onRequestSubmit}>
                  <Trans>Continue in browser</Trans>
                </span>
              </>
            )}
          </BottomLinks>
        </Inner>
      </Wrap> */}
          {/* <Instruction>
            <Trans>Use the link in the email we just sent you to log into our mobile app.</Trans>
          </Instruction>
          <StyledA href="mailto:">
            <MailIcon />
          </StyledA> */}

          <Instruction>
            <Trans>Download the DuoCards app and continue there.</Trans>
          </Instruction>
          <DownloadButton>
            <Trans>Download App</Trans>
          </DownloadButton>
        </Inner>
      </Wrap>
      <BottomLinks>
        <span onClick={() => navigate(ONBOARDING.url() + getStepHash(StepScreenId.SET_EMAIL))}>
          <Trans>I did not receive the email</Trans>
        </span>
        &nbsp; • &nbsp;
        <span onClick={onRequestSubmit}>
          <Trans>Continue in browser</Trans>
        </span>
      </BottomLinks>
    </>
  );
};

export default FollowEmailedInstructions;

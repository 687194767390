import { Button, buttonClasses, Box, Typography, Stack } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { Trans } from "react-i18next";
import { useNavigate } from "react-router";
import styled from "styled-components/macro";
import DialogWindow from "../other/DialogWindow";
import { useViewerQuery, useViewerDecks } from "../../queries/viewerQuery";
import { LEARN } from "../../root/main/MainRouter";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import { useSetState, useGetState } from "../ReduxProvider";
import { isChromeExtension } from "tools/device";
import PriceButton from "root/profile/subscription/PriceButton";
import { SUBSCRIPTION } from "../../root/profile/ProfileRouter.config";
import { useMaxCardsToLearn } from "./useMaxCardsToLearn";
import { useLocation } from "react-router-dom";
import { SlideDownTransition } from "../../styled/transitions/SlideDownTransition";
import MammothWithBubble, { MammothVariant } from "../../root/tutorial/onboarding/MammothWithBubble";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@duocards/pro-solid-svg-icons";

const NOPAYMENTSEXTRA = 10;

const SButton = styled(Button)`
  .${buttonClasses.endIcon} {
    margin-top: -8px;
    margin-left: 4px;
    svg {
      width: 12px;
    }
  }
`;
const SWhatshotIcon = styled(WhatshotIcon)`
  font-size: 36px;
  color: ${({ theme }) => theme.duo.color.primary};
  margin: 15px 0 -10px;
`;
const Strong = styled.strong`
  white-space: nowrap;
`;
const Green = styled.span`
  color: ${({ theme }) => theme.duo.color.green};
`;

const ToLearn = () => (
  <Green>
    "<Trans>To learn</Trans>"
  </Green>
);

export const useReachedMaximum = () => {
  const { isSubscriber, noPayments } = useViewerQuery();
  const { deck } = useViewerDecks();
  const TOLEARNMAX = useMaxCardsToLearn();
  return !!deck && deck.stats.unknown >= TOLEARNMAX + (noPayments() ? NOPAYMENTSEXTRA : 0) && !isSubscriber;
};

type Props = {
  showPermanently?: boolean;
  title?: JSX.Element;
  message?: JSX.Element;
  subscribeButtonLabel?: JSX.Element;
  hideSubscribeButton?: boolean;
};

const CardsMaxDialog: React.FC<Props> = ({
  showPermanently = false,
  title = <Trans>You have enough cards</Trans>,
  message = <Trans>Learn those before adding more. Or become a subscriber to remove all limits.</Trans>,
  subscribeButtonLabel = <Trans>Continue adding</Trans>,
  hideSubscribeButton
}) => {
  const navigate = useNavigate();
  const learnLimit = useGetState("learnLimit");
  const setLearnLimit = useSetState("learnLimit");
  const { getFlag } = useViewerQuery();
  const TOLEARNMAX = useMaxCardsToLearn();
  const maxCardsToLearnDialog = getFlag("abtest_maxCardsToLearnDialog")?.value === "on";
  const location = useLocation();
  const prevLocation = useRef<string>("");

  useEffect(() => {
    if (prevLocation.current !== location.pathname) {
      setLearnLimit(false);
    }
    prevLocation.current = location.pathname;
  }, [location, setLearnLimit]);

  if (!(showPermanently || learnLimit)) return null;

  if (maxCardsToLearnDialog) {
    return (
      <DialogWindow open wide TransitionComponent={SlideDownTransition} TransitionProps={{ timeout: 300 }}>
        <Box sx={{ paddingTop: 10, paddingBottom: 4 }}>
          <Box sx={{ marginBottom: 3, paddingLeft: 2, paddingRight: 2 }}>
            <Typography variant="h6">{title}</Typography>
          </Box>

          <Box sx={{ marginBottom: 10 }}>
            <MammothWithBubble variant={MammothVariant.Large}>
              <Box sx={{ padding: "8px 0" }}>{message}</Box>
            </MammothWithBubble>
          </Box>

          {isChromeExtension() ? (
            <PriceButton notInBrowser />
          ) : (
            <Stack direction="column" spacing={2} sx={{ paddingLeft: 2, paddingRight: 2 }}>
              {!hideSubscribeButton && (
                <SButton
                  color="primary"
                  variant="outlined"
                  onClick={() => {
                    setLearnLimit(false);
                    navigate(SUBSCRIPTION.url());
                  }}
                  endIcon={<FontAwesomeIcon icon={faStar} />}
                >
                  {subscribeButtonLabel}
                </SButton>
              )}

              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setLearnLimit(false);
                  navigate(LEARN.url());
                }}
              >
                <Trans>Learn</Trans>
              </Button>
            </Stack>
          )}
        </Box>
      </DialogWindow>
    );
  }

  return (
    <DialogWindow
      open
      onClose={() => {
        setLearnLimit(false);
        if (showPermanently) {
          navigate(-1);
        }
      }}
      title={<SWhatshotIcon />}
    >
      <p>
        {/* prettier-ignore */}
        <Trans>
          You reached the free version limit of <Strong>{String(TOLEARNMAX)} cards</Strong> in{" "}
          <ToLearn /> state
        </Trans>
      </p>

      <Trans parent="p">Learn those before adding more. Or become a subscriber to remove all limits.</Trans>

      {isChromeExtension() ? (
        <PriceButton notInBrowser />
      ) : (
        <>
          {!hideSubscribeButton && (
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setLearnLimit(false);
                navigate(SUBSCRIPTION.url());
              }}
            >
              <Trans>Subscribe</Trans>
            </Button>
          )}

          <p style={{ margin: "10px 0 15px" }}>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => {
                setLearnLimit(false);
                navigate(LEARN.url());
              }}
            >
              <Trans>Start learning</Trans>
            </Button>
          </p>
        </>
      )}
    </DialogWindow>
  );
};

export default CardsMaxDialog;

import React from "react";
import { useRive, Fit, Layout, Alignment } from "@rive-app/react-canvas";
import styled from "styled-components";
import { Trans } from "react-i18next";
import { useEffectOnce } from "react-use";
import { useLibraryQuery } from "queries/sources/libraryQuery";
import useOnboardingData from "hooks/useOnboardingData";
import { useViewerQuery } from "queries/viewerQuery";
import { MY_DIFFICULTY_FLAG } from "sharedJs__generated/constants";

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const RiveWrap = styled.div`
  width: 368px;
  height: 210px;
  margin-top: -60px;
`;

const SText = styled.div`
  text-align: center;
  width: 260px;
`;

type Props = {
  onRequestSubmit?: () => void;
};

enum RiveAnim {
  WalkLoad = "Walk-load",
  Steps = "steps",
  Sun = "sun",
  Bump = "Bump",
  Eye = "eye",
  Vistas = "Vistas"
}

const RIVE_SOURCE = "https://imgs.duocards.com/rive/onboarding-loader.riv";

const CoursePreparationLoader: React.FC<Props> = ({ onRequestSubmit }) => {
  const { sets, courses, videos, articles, isLoading } = useLibraryQuery();
  const { saveOnboardingData } = useOnboardingData();
  const { getFlag } = useViewerQuery();
  const [animEnded, setAnimEnded] = React.useState(false);
  const isLoadingRef = React.useRef(isLoading);
  isLoadingRef.current = isLoading;

  const ended = React.useRef(false);
  const dataSaved = React.useRef(false);

  const saveDataToFlag = React.useCallback(() => {
    if (isLoading) return;
    if (dataSaved.current) return;

    const difficulty = getFlag(MY_DIFFICULTY_FLAG)?.value;
    dataSaved.current = true;

    const courseForDifficulty = courses.data?.sources?.edges?.find((e) => e?.node?.difficulty === difficulty);
    const setForDifficulty = sets.data?.sources?.edges?.find((e) => e?.node?.difficulty === difficulty);
    const videoForDifficulty = videos.data?.sources?.edges?.find((e) => e?.node?.difficulty === difficulty);
    const articleForDifficulty = articles.data?.sources?.edges?.find((e) => e?.node?.difficulty === difficulty);

    const setOrCourseId =
      courseForDifficulty?.node?.id ??
      setForDifficulty?.node?.id ??
      courses.data?.sources?.edges?.[0]?.node?.id ??
      sets.data?.sources?.edges?.[0]?.node?.id ??
      ""; // this should never happen, but what if it does?

    const videoId = videoForDifficulty?.node?.id ?? videos.data?.sources?.edges?.[0]?.node?.id ?? "";
    const articleId = articleForDifficulty?.node?.id ?? articles.data?.sources?.edges?.[0]?.node?.id ?? "";

    saveOnboardingData({ preparedSources: { setOrCourseId, videoId, articleId } });
  }, [courses, sets, videos, articles, isLoading, saveOnboardingData, getFlag]);

  React.useEffect(() => {
    if (animEnded && !isLoading) {
      saveDataToFlag();
      onRequestSubmit?.();
    }
  }, [animEnded, isLoading, saveDataToFlag, onRequestSubmit]);

  const onAnimEnded = () => {
    if (!ended.current) {
      ended.current = true;
      setAnimEnded(true);
    }
  };

  const { rive, RiveComponent } = useRive({
    src: RIVE_SOURCE,
    layout: new Layout({
      fit: Fit.Contain,
      alignment: Alignment.Center
    }),
    autoplay: true,
    stateMachines: "Anim",
    onStop: (e) => {
      const animEnded =
        (typeof e.data === "string" && e.data.includes(RiveAnim.WalkLoad)) ||
        (Array.isArray(e.data) && e.data.includes(RiveAnim.WalkLoad));
      if (animEnded && !ended.current) {
        onAnimEnded();
      }
    }
  });

  // max 3.5 seconds on this screen
  useEffectOnce(() => {
    const timeout = setTimeout(() => {
      if (!ended.current && !isLoadingRef.current) {
        rive?.stop();
        if (!rive) setAnimEnded(true);
      }
    }, 3500);

    return () => {
      clearTimeout(timeout);
    };
  });

  React.useEffect(() => {
    rive?.play([RiveAnim.WalkLoad]);
    return () => {
      rive?.cleanupInstances();
    };
  }, [rive]);

  return (
    <Wrap>
      <RiveWrap>
        <RiveComponent />
      </RiveWrap>
      <SText>
        <Trans>
          We are preparing <b>your custom learning plan</b>, just a moment.
        </Trans>
      </SText>
    </Wrap>
  );
};

export default CoursePreparationLoader;

import Flames from "components/fire/Flames";
import { useXpIncrement } from "components/fire/xpIncrement";
import { GARDEN_ID } from "icons/garden/Garden";
import { ANIMDUR } from "icons/garden/ItemAnimation";
import React, { useState, useEffect } from "react";
import styled, { css, keyframes } from "styled-components/macro";
import { getLevel } from "../../../../icons/garden/levelsHelper";
import { useViewerXps } from "../../../../queries/viewerQuery";

const Wrap = styled.div<{ fading: boolean; animate: boolean }>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${({ fading }) => (fading ? "white" : "none")};
  transition: all 3000ms;
  pointer-events: none;
  border-radius: 5px;
  z-index: 810; // above QuestReward
`;

const slide = (animateToY: number) => keyframes`
  20% {
    transform: rotate(-360deg) scale(2);
  }
  60% {
    transform: rotate(0deg) scale(1.7);
    opacity: 1;
  }
  100% {
      top: ${animateToY}px;
      transform: scale(0.5);
      opacity: 0;
    }
`;
export const RewardWrap = styled.div<{ animateToY?: number }>`
  position: absolute;
  left: calc(50% - 20px);
  top: -19px;
  transform: scale(1.1);
  ${({ animateToY }) =>
    animateToY &&
    css`
      animation: ${slide(animateToY)} 1.8s ease-in-out 500ms forwards;
    `}
`;

type Props = {
  rewardedXps?: number;
  claim?: boolean;
  onDone?: VoidFunction;
  style?: React.CSSProperties;
};

const QuestReward: React.FC<Props> = ({ rewardedXps, claim, onDone, style }) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [animateToY, setAnimateToY] = React.useState<number>();
  const [animation1, setAnimation1] = React.useState(false);
  const [animation2, setAnimation2] = React.useState(false);
  const xpIncrement = useXpIncrement();
  const { xps } = useViewerXps();
  const incremented = React.useRef<boolean>(false);
  const claimed = React.useRef<boolean>(false);
  const { level } = getLevel(xps?.total || 0);
  const futureLevel = getLevel((xps?.total || 0) + (rewardedXps || 0));
  const animate = futureLevel.level > level;
  const [delayedClaim, setDelayedClaim] = useState(false);

  useEffect(() => {
    if (claim) {
      setTimeout(() => setDelayedClaim(true), 1);
    }
  }, [claim]);

  React.useEffect(() => {
    if (delayedClaim && !claimed.current) {
      claimed.current = true;
      setAnimation1(true);
      setTimeout(() => setAnimation2(true), animate ? 5000 : 2000);
      onDone && setTimeout(onDone, delayedClaim && animate ? ANIMDUR : ANIMDUR / 2 + 200);
      const actualTop = ref.current?.getBoundingClientRect()?.top;
      const gardenTop = document.getElementById(GARDEN_ID)?.getBoundingClientRect()?.top;
      if (actualTop && gardenTop) {
        setAnimateToY(gardenTop - actualTop + 60);
      }
      setTimeout(() => window.navigator.vibrate?.(300), 300);
      setTimeout(() => window.navigator.vibrate?.(100), 700);
    }
  }, [delayedClaim, onDone, animation1, animate]);

  React.useEffect(() => {
    if (rewardedXps && delayedClaim && animation1 && !incremented.current) {
      xpIncrement(rewardedXps);
      incremented.current = true;
    }
  });

  return (
    <Wrap fading={animation2} animate={animate} style={style}>
      <RewardWrap animateToY={animateToY} ref={ref}>
        <Flames xps={rewardedXps || null} strength={4} />
      </RewardWrap>
    </Wrap>
  );
};

export default QuestReward;

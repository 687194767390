import React from "react";
import styled from "styled-components/macro";
import { IconButton } from "@mui/material";
import ProgressBar from "components/other/ProgressBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@duocards/pro-regular-svg-icons";
import { useTranslation } from "react-i18next";
import OnboardingButton from "./OnboardingButton";
import { devicePaddings } from "tools/device";

const { bottom, top } = devicePaddings();
const Wrap = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    "header"
    "content"
    "button";
  height: 100%;
  width: 100%;
  max-width: ${({ theme }) => theme.duo.maxWidth.narrow};
  margin: 0 auto;
  padding-top: ${top}px;
  padding-bottom: ${20 + bottom}px;
`;
const Header = styled.div`
  grid-area: header;
  display: flex;
  width: 100%;
  align-items: center;
  padding: 20px 15px;
`;
const Content = styled.div`
  grid-area: content;
  flex-grow: 1;
  width: 100%;
  overflow-y: hidden;
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  z-index: 2;
`;
const ProgressWrap = styled.div<{ $visible?: boolean }>`
  flex-grow: 1;
  height: 36px;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: ${({ $visible }) => ($visible ? "visible" : "hidden")};
`;
const BackButton = styled(IconButton)<{ $visible?: boolean }>`
  > svg {
    width: 20px;
    height: 20px;
  }
  visibility: ${({ $visible }) => ($visible ? "visible" : "hidden")};
  display: ${({ $visible }) => ($visible ? "inline-flex" : "none")};
`;

type Props = {
  showBackButton?: boolean;
  showProgressBar?: boolean;
  progressValue?: number;
  children: React.ReactNode;
  buttonText?: string;
  buttonOnClick?: () => void;
  buttonDisabled?: boolean;
  onBack?: () => void;
  showContinue?: boolean;
};

const OnboardingStep: React.FC<Props> = ({
  showBackButton = true,
  showProgressBar = true,
  progressValue = 10,
  children,
  buttonText = "Continue",
  buttonOnClick,
  buttonDisabled,
  showContinue = true,
  onBack
}) => {
  const { t } = useTranslation();

  return (
    <Wrap>
      <Header>
        <BackButton onClick={onBack} $visible={showBackButton}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </BackButton>
        <ProgressWrap $visible={showProgressBar}>
          <ProgressBar value={progressValue} />
        </ProgressWrap>
      </Header>

      <Content>{children}</Content>

      {showContinue && (
        <OnboardingButton onClick={buttonOnClick} disabled={buttonDisabled}>
          {t(buttonText)}
        </OnboardingButton>
      )}
    </Wrap>
  );
};

export default OnboardingStep;

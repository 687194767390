import { useCallback } from "react";
import { useSetFlag } from "queries/users/setFlag";
import { useViewerQuery } from "queries/viewerQuery";
import { FlagName } from "queries/users/userFlagsModel";

// deprecated, we have jsonb `body` field instead

type JsonFlagData = Record<string, any>;

const useJsonFlag = <T extends JsonFlagData>(flagName: FlagName, defaultData: T) => {
  const { getFlag } = useViewerQuery();
  const setFlag = useSetFlag();

  const getFlagData = useCallback((): T => {
    const flagData = getFlag(flagName);
    return flagData?.value ? (JSON.parse(flagData.value) as T) : defaultData;
  }, [getFlag, flagName, defaultData]);

  const saveFlagData = useCallback(
    (data: Partial<T>) => {
      const currentData = getFlagData();
      setFlag({
        name: flagName,
        value: JSON.stringify({ ...currentData, ...data })
      });
    },
    [setFlag, getFlagData, flagName]
  );

  return {
    saveFlagData,
    getFlagData
  };
};

export default useJsonFlag;

import React from "react";
import { Trans } from "react-i18next";
import styled from "styled-components";

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: relative;
`;

const OptionText = styled.div`
  font-size: 16px;
`;

const RevealableInfo = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.duo.palette.greyDark};
  display: none;
  position: absolute;
  top: 50px;
  text-align: left;
  z-index: 1;
  .toggled & {
    display: block;
  }
`;

type Props = {
  text: string;
  info: string;
};

const InfoRevealOption: React.FC<Props> = ({ text, info }) => {
  return (
    <Wrap>
      <OptionText>
        <Trans>{text}</Trans>
      </OptionText>
      {info && (
        <RevealableInfo>
          <Trans>{info}</Trans>
        </RevealableInfo>
      )}
    </Wrap>
  );
};

export default InfoRevealOption;

import { faBooks, faCardsBlank, faChartSimple, faPalette, faUserVneck } from "@duocards/pro-duotone-svg-icons";
import {
  faBooks as farBooks,
  faChartSimple as farChartSimple,
  faPalette as farPalette,
  faUserVneck as farUserVneck
} from "@duocards/pro-regular-svg-icons";
import { faCardsBlank as farCardsBlank } from "@awesome.me/kit-55349eb283/icons/kit/custom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useLocation } from "react-router";
import styled from "styled-components";
import { getTheme } from "styled/theme";
import { LIBRARY, MAIN, PROFILE } from "../root/RootRouter.config";

const IconWrapper = styled.div<{ size?: number; switchedColors?: boolean; rotate?: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;

  svg {
    width: ${({ size }) => size || 24}px !important;
    height: ${({ size }) => size || 24}px !important;
    transform: rotate(${({ rotate }) => rotate || 0}deg);
    --fa-secondary-opacity: 1;
    --fa-primary-opacity: 1;
    --fa-primary-color: ${({ switchedColors }) =>
      switchedColors ? getTheme().color.duoToneGreen : getTheme().color.text};
    --fa-secondary-color: ${({ switchedColors }) =>
      switchedColors ? getTheme().color.text : getTheme().color.duoToneGreen};
  }
`;

const SSpan = styled.span`
  margin-top: 4px;
  color: ${getTheme().color.text};
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 8px;
  font-weight: 500;
  line-height: 8.2px;
  text-align: center;
`;

type IconName = "accountUserVNeck" | "libraryBooks" | "learningCardsBlank" | "chartSimple" | "pallete";

interface FADuotoneIconProps {
  icon: IconName;
  label?: string;
  switchedColors?: boolean;
  active?: boolean;
  rotate?: number;
  size?: number;
}

const iconMap = {
  accountUserVNeck: (pathname: string) => (pathname.includes(PROFILE.url()) ? faUserVneck : farUserVneck),
  libraryBooks: (pathname: string) => (pathname.includes(LIBRARY.url()) ? faBooks : farBooks),
  learningCardsBlank: (pathname: string) =>
    pathname.includes(MAIN.url()) || pathname === "/" ? faCardsBlank : farCardsBlank,
  chartSimple: (_pathname: string, active?: boolean) => (active ? faChartSimple : farChartSimple),
  pallete: (_pathname: string, active?: boolean) => (active ? faPalette : farPalette)
};

const convertIntoIcon = (icon: IconName, pathname: string, active?: boolean) => {
  return iconMap[icon](pathname, active);
};

const FADuotoneIcon: React.FC<FADuotoneIconProps> = React.memo(
  ({ icon, rotate, label, active, size, switchedColors }) => {
    const location = useLocation();

    return (
      <IconWrapper size={size} switchedColors={switchedColors} rotate={rotate}>
        <FontAwesomeIcon icon={convertIntoIcon(icon, location.pathname, active)} />
        {label && <SSpan>{label}</SSpan>}
      </IconWrapper>
    );
  }
);

export default FADuotoneIcon;

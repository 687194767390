import React from "react";
import styled from "styled-components/macro";
import { Trans } from "react-i18next";
import CardForm from "../../root/main/card/CardForm";
import { PickerCard } from "./Picker";
import { useMutation } from "relay-hooks";
import { Checkbox } from "@mui/material";
import { EditSCard } from "../../queries/sources/editSCard";
import { editSCardMutation } from "../../queries/sources/__generated__/editSCardMutation.graphql";
import { useViewerDecks } from "../../queries/viewerQuery";
import { CardValues } from "../ReduxProvider";
import DialogFullWindow from "components/other/DialogFullWindow";

const Wrap = styled.div`
  padding: 0 20px 25px 20px;
`;
const CheckLabel = styled.label<{ suggest: boolean }>`
  position: absolute;
  display: flex;
  align-items: center;
  margin-top: -35px;
  margin-left: 5px;
  font-size: 12px;
  line-height: 1.4em;
  cursor: pointer;
  opacity: ${({ suggest }) => (suggest ? 0.9 : 0.8)};
  color: ${({ theme, suggest }) => (suggest ? theme.duo.color.text : theme.duo.color.textGrey)};
  .MuiCheckbox-root {
    padding: 3px;
  }
`;

type Props = {
  card?: PickerCard;
  source?: { id: string; name: string; lang: string; mine: boolean };
  onClose: VoidFunction;
};

const SCardForm: React.FC<Props> = ({ card, onClose, source }) => {
  const [editSCard] = useMutation<editSCardMutation>(EditSCard);
  const [synchronize, setSynchronize] = React.useState(true);
  const { deck } = useViewerDecks();

  const toLang = deck?.back || undefined;

  const handleSave: (v: CardValues) => void = (values) => {
    if (!card?.id) return;
    if (!source?.mine) throw Error("cannot edit others cards"); // there should be option to edit other than mine card after removing suggestions

    editSCard({
      variables: {
        ...values,
        id: card.id,
        backLang: toLang,
        synchronize: card.isInMyDeck ? synchronize : false,
        deckId: deck ? deck.id : null
      }
    });
    onClose();
  };

  return (
    <DialogFullWindow open={!!card && !!source?.mine} onClose={onClose} background>
      <Wrap>
        {card && card.isInMyDeck && (
          <CheckLabel suggest={synchronize}>
            <Checkbox size="small" checked={synchronize} onChange={() => setSynchronize(!synchronize)} />
            <Trans parent="div">Synchronize in your deck</Trans>
          </CheckLabel>
        )}

        {card && source && (
          <CardForm
            withTheory
            fromLang={source.lang}
            toLang={toLang}
            onSave={handleSave}
            card={{
              front: card.front,
              back: card.back?.value as string,
              hint: card.hint,
              svg: card.svg,
              theory: card.theory?.theory,
              sourceId: source.id
            }}
            disableGrammar
          />
        )}
      </Wrap>
    </DialogFullWindow>
  );
};

export default SCardForm;

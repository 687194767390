import React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEarDeaf } from "@duocards/pro-regular-svg-icons";
import { Trans } from "react-i18next";
import { useGetState, useSetState } from "components/ReduxProvider";

const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.duo.color.textGrey,
  padding: "6px 16px",
  textTransform: "none",
  textDecoration: "underline",
  fontSize: "12px",
  fontWeight: 400,
  opacity: 0.7
}));

const VOICE_OFF_DURATION = 1000 * 60 * 60; // 1 hour

const CantListenNow: React.FC<React.ComponentProps<typeof StyledButton>> = (props) => {
  const voiceOff = useGetState("voiceOff");
  const setVoiceOff = useSetState("voiceOff");
  const setVoiceOffUntil = useSetState("voiceOffUntil");

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setVoiceOff(true);
    setVoiceOffUntil(Date.now() + VOICE_OFF_DURATION);
    props.onClick?.(e);
  };

  if (voiceOff) return null;

  return (
    <StyledButton
      size="small"
      variant="text"
      startIcon={<FontAwesomeIcon icon={faEarDeaf} />}
      {...props}
      onClick={handleClick}
    >
      <Trans>Can't listen now</Trans>
    </StyledButton>
  );
};

export default CantListenNow;

import React, { useState } from "react";
import { Button } from "@mui/material";
import { useNavigate, useLocation } from "react-router";
import { useTranslation, Trans } from "react-i18next";
import { snackbar } from "tools/events";
import MessageBubbles from "./MessageBubbles";
import HelpfulPrompt from "./HelpfulFeedbackPrompt";
import { useGetState, useSetState } from "components/ReduxProvider";
import styled from "styled-components/macro";
import AIFeedbackForm from "./AIFeedbackForm";
import SupportFeedbackModal from "./SupportFeedbackModal";
import { useDBLog } from "hooks/useDBLog";
import { HeaderLayout } from "../../../components/header/Header";
import { useViewerQuery } from "queries/viewerQuery";

const FEEDBACK_TIMEOUT = 1000 * 60 * 30; // 30 minutes

export const isRecentFeedback = (createdAt: number | undefined): boolean => {
  return createdAt ? new Date(createdAt).getTime() > Date.now() - FEEDBACK_TIMEOUT : false;
};

export const SHeaderLayout = styled(HeaderLayout)`
  padding: 20px;
`;

export const RelatedTo = styled.div`
  background: #fffa;
  padding: 5px 12px;
  margin-bottom: 15px;
  border-radius: 5px;
  font-size: 90%;
  color: #777;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const AIWarningMessage = styled.p`
  color: #666;
  font-size: 12px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const Feedback: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { viewer } = useViewerQuery();
  const lastFeedback = useGetState("lastFeedback");
  const setLastFeedback = useSetState("lastFeedback");
  const [isSupportModalOpen, setIsSupportModalOpen] = useState(false);
  const [isAwaitingAIResponse, setIsAwaitingAIResponse] = useState(false);
  const dbLog = useDBLog();

  const isPublicView = !viewer;

  const { relatedTo, errorMessage, forceDirectSupport } = React.useMemo(() => {
    const searchParams = new URLSearchParams(location.search);

    const relatedTo =
      (location.state && (location.state as any).relatedTo) ||
      (searchParams.get("relatedTo") ? { text: searchParams.get("relatedTo")! } : undefined);

    // error from search params -> prefilling from cordova index if app not loading
    const errorMessage = [
      searchParams.get("error") && `\nError: ${searchParams.get("error")}`,
      searchParams.get("version") && `\nVersion: ${searchParams.get("version")}`,
      searchParams.get("jsVersion") && `\nJS Version: ${searchParams.get("jsVersion")}`
    ]
      .filter(Boolean)
      .join("");

    const forceDirectSupport = searchParams.get("forceDirectSupport") === "true";

    return {
      relatedTo,
      errorMessage,
      forceDirectSupport
    };
  }, [location.state, location.search]);

  const messages = (lastFeedback?.relatedTo || "") === (relatedTo?.text || "") ? lastFeedback?.messages || [] : [];
  const [isFeedbackHelpful, setIsFeedbackHelpful] = useState<boolean | null>(messages.length < 2 ? null : false);

  // Reset lastFeedback if relatedTo changes or if it's older than FEEDBACK_TIMEOUT
  React.useEffect(() => {
    const relatedToChanged = lastFeedback?.relatedTo !== relatedTo?.text;
    const recent = isRecentFeedback(lastFeedback?.createdAt);

    if (relatedToChanged || !recent) {
      setLastFeedback(undefined);
    }
  }, [relatedTo, lastFeedback, setLastFeedback]);

  React.useEffect(() => {
    if (forceDirectSupport) {
      setIsSupportModalOpen(true);
    }
  }, [forceDirectSupport]);

  const handleHelpfulResponse = (helpful: boolean) => {
    setIsFeedbackHelpful(helpful);
    dbLog(`AI Feedback Helped`, helpful ? "yes" : "no", {
      relatedTo: relatedTo?.text,
      feedback: messages[0]?.text
    });

    if (helpful) {
      snackbar(t("Thank you for the feedback!"));
      navigate(-1);
    }
  };

  const assistantMessages = messages.filter((m) => m.owner === "assistant");
  const shouldShowHelpfulPrompt = assistantMessages.length === 1 && isFeedbackHelpful === null;
  const shouldShowContactSupportButton =
    assistantMessages.length >= 1 && !isSupportModalOpen && isFeedbackHelpful === false;
  const shouldShowFeedbackForm = assistantMessages.length !== 1 || isFeedbackHelpful === false;
  const shouldShowAIWarning = assistantMessages.length >= 1;

  return (
    <SHeaderLayout title={<Trans>Message</Trans>} narrow noBack={isPublicView}>
      {relatedTo && <RelatedTo>{relatedTo.text}</RelatedTo>}
      <MessageBubbles showTypingBubble={isAwaitingAIResponse} messages={messages} />

      {shouldShowFeedbackForm && <AIFeedbackForm relatedTo={relatedTo} onSubmitting={setIsAwaitingAIResponse} />}

      {shouldShowAIWarning && (
        <AIWarningMessage>
          <Trans>AI can make mistakes, check important info.</Trans>
        </AIWarningMessage>
      )}

      {shouldShowHelpfulPrompt && (
        <HelpfulPrompt onYes={() => handleHelpfulResponse(true)} onNo={() => handleHelpfulResponse(false)} />
      )}

      {shouldShowContactSupportButton && (
        <Button variant="text" color="primary" fullWidth onClick={() => setIsSupportModalOpen(true)}>
          <Trans>Contact support</Trans>
        </Button>
      )}

      <SupportFeedbackModal
        open={isSupportModalOpen}
        onClose={() => setIsSupportModalOpen(false)}
        relatedTo={relatedTo}
        additionalInfo={errorMessage}
      />
    </SHeaderLayout>
  );
};

export default Feedback;

import InternalAdPaywall from "components/ads/InternalAdPaywall";
import InternAdOverview from "components/ads/InternAdOverview";
import { useSeeHint } from "components/hint/Hint";
import { useABTest } from "queries/users/useABTest";
import React from "react";
import styled from "styled-components/macro";
import { logEvent, PROXY } from "tools/analyticEvents";

export const FIRSTSUBSCRIPTION = "firstSubscription";

const Wrap = styled.div`
  position: absolute;
  z-index: 1000;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  max-width: ${({ theme }) => theme.duo.maxWidth.normal};
  width: 100%;
  background: ${({ theme }) => theme.duo.color.lightGrey};
`;

const FirstSubscription: React.FC<{ onRequestSubmit: () => void }> = ({ onRequestSubmit }) => {
  const seeHint = useSeeHint();
  const newFirstPaywall = useABTest("abtest_newFirstPaywall");

  const handleClose = () => {
    logEvent(PROXY.email_set); // moved two screens further to optimize campaigns better
    seeHint(FIRSTSUBSCRIPTION);
    onRequestSubmit();
  };

  return (
    <Wrap>
      {newFirstPaywall ? <InternalAdPaywall onClose={handleClose} /> : <InternAdOverview onClose={handleClose} />}
    </Wrap>
  );
};

export default FirstSubscription;

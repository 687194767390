import React from "react";
import styled from "styled-components/macro";
import { sourceQuery$data } from "../../../queries/sources/__generated__/sourceQuery.graphql";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Link } from "react-router-dom";
import { getSourceEditUrl, SOURCE } from "../library/libraryUrls";
import VoiceItem from "../../../components/speech/VoiceItem";
import { Menu, MenuItem, IconButton } from "@mui/material";
import { useViewerQuery } from "../../../queries/viewerQuery";
import { Trans, useTranslation } from "react-i18next";
import SourceAdminMenu from "./SourceAdminMenu";
import { useTtsQuery } from "../../../queries/ttsQuery";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import VoicePickerItem from "../../../components/speech/VoicePickerItem";
import ShareIcon from "@mui/icons-material/Share";
import SharePopup from "../../../components/other/SharePopup";
import { useNavigate } from "react-router";
import FeedbackIcon from "@mui/icons-material/Feedback";
import FilterIcon from "../../../icons/FilterIcon";
import StarIcon from "components/other/StarIcon";
import { dispatchEvent, EVENT } from "tools/events";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRectangleVerticalHistory } from "@duocards/pro-solid-svg-icons";
import { FEEDBACK, SUBSCRIPTION } from "../../profile/ProfileRouter.config";

const SIconButton = styled(IconButton)`
  padding: 10px;
`;
const SFilterIcon = styled(FilterIcon)`
  width: 15px;
  height: 15px;
  margin-left: 4px;
`;
const SStarIcon = styled(StarIcon)`
  top: -3px;
  left: -5px;
`;
const SIcon = styled.span`
  position: relative;
`;
const SMenuText = styled.span`
  display: inline-block;
  margin-left: 6px;
  white-space: normal;
`;

type Props = {
  source: sourceQuery$data["sourceByUriOrId"];
  onSetShowPicker?: VoidFunction;
  showPicker?: boolean;
};

const SourceDots: React.FC<Props> = ({ source, showPicker, onSetShowPicker }) => {
  const { viewer, noPayments } = useViewerQuery();
  const [openEl, setOpen] = React.useState(null);
  const { t } = useTranslation();
  const tts = useTtsQuery((source && source.kind === "set" && source.lang) || undefined);
  const [shareOpen, setShareOpen] = React.useState(false);
  const navigate = useNavigate();

  const handleFeedback = () => {
    navigate(FEEDBACK.url(), {
      state: {
        relatedTo: {
          text: `${source.name} - https://app.duocards.com/library/s/${source.uri}`
        }
      }
    });
  };
  const handleLearnPicked = () => {
    if (!viewer?.subscription && !noPayments()) {
      navigate(SUBSCRIPTION.url());
      return;
    }

    dispatchEvent(EVENT.testMeFromPickedCards);
    setOpen(null);
  };

  return (
    <>
      <SIconButton onClick={(e) => setOpen(e.currentTarget as any)}>
        <MoreVertIcon />
      </SIconButton>
      <Menu open={!!openEl} anchorEl={openEl} onClose={() => setOpen(null)}>
        {source?.kind === "set" && (
          <MenuItem
            key="list"
            onClick={() => {
              onSetShowPicker && onSetShowPicker();
              setOpen(null);
            }}
          >
            {showPicker ? (
              <>
                <FormatListBulletedIcon /> &nbsp; <Trans>Cards list</Trans>
              </>
            ) : (
              <>
                <FontAwesomeIcon fontSize={20} icon={faRectangleVerticalHistory} /> &nbsp; <Trans>Pick cards</Trans>
              </>
            )}
          </MenuItem>
        )}
        <MenuItem onClick={() => setShareOpen(true)} color="secondary">
          <ShareIcon /> &nbsp; <Trans>Share</Trans>
        </MenuItem>
        {tts && <VoicePickerItem lang={source.lang} onDone={() => setOpen(null)} />}
        {tts && <VoiceItem onClick={() => setOpen(null)} />}
        {(source?.mine || viewer?.admin) && [
          <MenuItem key="1" to={getSourceEditUrl(source)} component={Link}>
            <EditIcon />
            &nbsp; {t("Edit")}
          </MenuItem>,
          <SourceAdminMenu key="3" source={source} closeMenu={() => setOpen(null)} />
        ]}
        {source.kind === "set" && (
          <MenuItem onClick={handleLearnPicked} color="secondary">
            <SIcon>
              <SFilterIcon />
              <SStarIcon />
            </SIcon>{" "}
            <Trans parent={SMenuText}>Test me from all picked cards in this set</Trans>
          </MenuItem>
        )}
        <MenuItem onClick={handleFeedback} color="secondary">
          <FeedbackIcon /> &nbsp; <Trans>Report poor quality</Trans>
        </MenuItem>
      </Menu>

      <SharePopup
        open={shareOpen}
        onClose={() => {
          setShareOpen(false);
          setOpen(null);
        }}
        uri={SOURCE.url(source.uri)}
      />
    </>
  );
};

export default SourceDots;

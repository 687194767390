export const MAIN = {
  url: () => "/main"
};
export const LIBRARY = {
  root: () => "/library",
  topicRelative: ":topic",
  query: () => LIBRARY.root() + `/${LIBRARY.topicRelative}`,
  url: (topic?: string) => LIBRARY.root() + (topic ? `/${topic}` : ``),
  kindParam: "kind",
  searchParam: "search",
  topicHash: "topics"
};
export const PROFILE = {
  url: () => "/profile"
};
export const VERIFY = {
  url: () => "/verify",
  verifyParam: "verify",
  sendHash: "#sendit"
};
export const TRYSHARING = {
  url: (lang: string | null) => "/trysharing/" + (lang !== null ? lang : ""),
  query: () => "/trysharing/:lang"
};
export const AUTHOR = {
  url: (name: string) => `/author/${name}`,
  query: () => "/author/:name"
};
export const ADMIN = {
  url: () => `/admin`,
  query: () => "/admin"
};
export const SHARE = {
  query: () => `/share/:hash`
};
export const ONBOARDING = {
  url: () => `/onboarding`
};
export const LATE_REGISTRATION = {
  url: () => `/late-registration`
};

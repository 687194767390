import { useEffect, useState, useCallback } from "react";
import styled from "styled-components/macro";
import { Stack, buttonClasses } from "@mui/material";
import { Trans } from "react-i18next";
import { useNavigate, useLocation } from "react-router";
import { bottomPadding, isDesktop, topPadding, isApp, isPhone } from "../../tools/device";
import LoginHeader from "./LoginHeader";
import { useSetState } from "components/ReduxProvider";
import Toggler from "components/other/Toggler";
import { usePreLoginQuery } from "./PreLogin";
import MammothNew from "icons/MammothNew";
import { BrandTitle } from "../../components/other/BrandTitle";
import { dispatchEvent, EVENT } from "tools/events";
import { useEffectOnce } from "react-use";
import { UserAction } from "components/initializers/UserActionsLogger";
import { ReassuranceScreen } from "./reassurance/ReassuranceScreen";
import { AuthOptions } from "./AuthOptions";

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow: auto;
  text-align: center;
  padding: ${topPadding}px 0 ${bottomPadding}px;
  .${buttonClasses.outlined} {
    background: white;
  }
`;

const Small = styled.p`
  font-size: ${({ theme }) => theme.duo.fontSize.small};
  line-height: 1.4em;
  color: ${({ theme }) => theme.duo.color.textGrey};
`;

const HeaderSection = styled.div`
  margin-bottom: 20px;
`;

const useABTest = () => {
  const set_abtest = useSetState("abtest_removeLangPicker");

  useEffectOnce(() => {
    set_abtest(Math.random() > 0.5 ? "on" : "off");
  });
};

const LoginPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { data: preLoginData, isLoading: preLoginLoading } = usePreLoginQuery();
  const params = new URLSearchParams(location.search);
  const [isEmailOpen, setIsEmailOpen] = useState(false);
  const [isAuthOpen] = useState(true);
  const [isFromNewsletter] = useState(params.get("utm_source") === "newsletter");
  const [isSwitchingAccounts] = useState(params.get("switch_account") === "true");
  const [areLoginOptionsOpen, setLoginOptionsOpen] = useState(isFromNewsletter || isSwitchingAccounts);
  const [mammothHeight, setMammothHeight] = useState(120);
  const [subtitleVisible, setSubtitleVisible] = useState(true);
  const setToken = useSetState("token");
  const [showReassurance, setShowReassurance] = useState(!(isFromNewsletter || isSwitchingAccounts));
  useABTest();

  if (preLoginData?.token) {
    setToken(preLoginData.token);
  }

  const toggleEmailMethod = useCallback(
    (show: boolean) => {
      setIsEmailOpen(show);
      navigate({ hash: show ? "email" : "" });
    },
    [navigate]
  );

  const handleKeyboardToggle = useCallback((isVisible: boolean) => {
    if (isDesktop()) return;
    setSubtitleVisible(!isVisible);
  }, []);

  useEffect(() => {
    const newHeight = isEmailOpen && isPhone() ? 50 : 120;
    if (mammothHeight !== newHeight) {
      setMammothHeight(newHeight);
    }
  }, [isEmailOpen, mammothHeight]);

  useEffect(() => {
    const isEmailHash = location.hash === "#email";
    if (isEmailHash !== isEmailOpen) {
      setIsEmailOpen(isEmailHash);
    }
  }, [location.hash, isEmailOpen]);

  useEffectOnce(() => {
    dispatchEvent(EVENT.ATTConsent);
    dispatchEvent(EVENT.logUserAction, { action: UserAction.signupVisited });
  });

  if (preLoginLoading) {
    return null;
  }

  if (showReassurance) {
    return (
      <ReassuranceScreen
        onLogin={() => {
          setShowReassurance(false);
          setLoginOptionsOpen(true);
        }}
      />
    );
  }

  return (
    <Wrap>
      <LoginHeader
        onBack={() => {
          setShowReassurance(true);
          setLoginOptionsOpen(false);
          navigate("/");
        }}
        showBack={areLoginOptionsOpen && !isEmailOpen && (isApp() || isAuthOpen)}
      />
      <HeaderSection>
        <Stack alignItems="center" spacing={1}>
          <MammothNew height={mammothHeight} style={{ transition: "height 0.5s ease" }} />
          <BrandTitle>DuoCards</BrandTitle>
          {subtitleVisible && <Trans parent={Small}>Add words from anywhere, never forget them</Trans>}
        </Stack>
      </HeaderSection>
      <Toggler show={isAuthOpen}>
        <AuthOptions
          isEmailOpen={isEmailOpen}
          areLoginOptionsOpen={areLoginOptionsOpen}
          onEmailToggle={toggleEmailMethod}
          onKeyboardToggle={handleKeyboardToggle}
        />
      </Toggler>
      {isDesktop() && <div></div>} {/* empty to do not stick to bottom on desktop */}
    </Wrap>
  );
};

export default LoginPage;

export enum DebugGeography {
  Disabled = 0,
  EEA = 1,
  NotEEA = 2
}

export type RequestInfoUpdateOptions = {
  debugGeography?: DebugGeography;
  tagForUnderAgeOfConsent?: boolean;
  testDeviceIds?: string[];
};

export enum FormStatus {
  Unknown = 0,
  Available = 1,
  Unavailable = 2
}

export enum TrackingAuthorizationStatus {
  notDetermined = 0,
  restricted = 1,
  denied = 2,
  authorized = 3
}

export enum ConsentStatus {
  Unknown = 0,
  Required = 1,
  NotRequired = 2,
  Obtained = 3
}

export enum PrivacyOptionsRequirementStatus {
  Unknown = 0,
  Required = 1,
  NotRequired = 2
}

export interface IConsent {
  canRequestAds(): Promise<boolean>;
  privacyOptionsRequirementStatus(): Promise<PrivacyOptionsRequirementStatus>;
  loadAndShowIfRequired(): Promise<void>;
  showPrivacyOptionsForm(): Promise<void>;
  trackingAuthorizationStatus(): Promise<TrackingAuthorizationStatus>;
  requestTrackingAuthorization(): Promise<TrackingAuthorizationStatus>;
  getConsentStatus(): Promise<ConsentStatus>;
  getFormStatus(): Promise<FormStatus>;
  requestInfoUpdate(opts?: RequestInfoUpdateOptions): Promise<void>;
  // loadForm(): Promise<ConsentForm>;
  reset(): Promise<void>; // USE THIS ONLY FOR DEBUG PURPOSES
}

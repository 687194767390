import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { IconButton } from "@mui/material";
import ConsoleIcon from "@mui/icons-material/Terminal";
import ClearIcon from "@mui/icons-material/Refresh";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useGetState, useSetState } from "components/ReduxProvider";
import { topPadding } from "tools/device";

const Container = styled.div<{ collapsed: boolean }>`
  position: fixed;

  bottom: 0;
  right: 0;
  width: ${({ collapsed }) => (collapsed ? "40px" : "100%")};
  height: ${({ collapsed }) => (collapsed ? "40px" : "100%")};
  background: rgba(0, 0, 0, 0.9);
  color: white;
  overflow: auto;
  transition: all 0.3s ease;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: ${({ collapsed }) => (collapsed ? "center" : "flex-start")};
  justify-content: ${({ collapsed }) => (collapsed ? "center" : "flex-start")};
`;

const Header = styled.div`
  background: #444;
  padding: 7px;
  padding-top: ${10 + topPadding}px;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 1001; /* Ensure it stays above the logs */
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const IconContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const LogContainer = styled.div`
  padding: 10px;
  white-space: pre-wrap;
  font-family: monospace;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

const LogEntry = styled.div<{ type: string }>`
  border-bottom: 1px solid #555;
  padding-bottom: 5px;
  font-size: 12px;
  width: 100%;
  overflow-wrap: break-word;
  color: ${({ type }) => {
    switch (type) {
      case "ERROR":
      case "UNCAUGHT ERROR":
        return "red";
      case "WARN":
        return "yellow";
      default:
        return "white";
    }
  }};
`;

const ConsoleLogUI: React.FC = () => {
  const [logs, setLogs] = useState<{ type: string; message: string }[]>([]);
  const [collapsed, setCollapsed] = useState(true);
  const active = useGetState("logUIActive");
  const setLogUIActive = useSetState("logUIActive");

  useEffect(() => {
    if (!active) return;

    const originalConsoleLog = console.log;
    const originalConsoleInfo = console.info;
    const originalConsoleError = console.error;
    const originalConsoleWarn = console.warn;

    const handleLog = (type: string, ...args: any[]) => {
      const formattedArgs = args.map((arg) => {
        if (typeof arg === "object") {
          try {
            return JSON.stringify(arg, getCircularReplacer(), 2);
          } catch (e) {
            return "[Circular]";
          }
        }
        return arg.toString();
      });

      setLogs((prevLogs) => [...prevLogs, { type, message: formattedArgs.join(" ") }]);
    };

    console.log = (...args: any[]) => {
      handleLog("LOG", ...args);
      originalConsoleLog(...args);
    };

    console.info = (...args: any[]) => {
      handleLog("INFO", ...args);
      originalConsoleInfo(...args);
    };

    console.error = (...args: any[]) => {
      handleLog("ERROR", ...args);
      originalConsoleError(...args);
    };

    console.warn = (...args: any[]) => {
      handleLog("WARN", ...args);
      originalConsoleWarn(...args);
    };

    const handleUncaughtError = (event: ErrorEvent) => {
      handleLog("UNCAUGHT ERROR", event.message, event.filename, event.lineno, event.colno, event.error);
    };

    window.addEventListener("error", handleUncaughtError);

    return () => {
      console.log = originalConsoleLog;
      console.info = originalConsoleInfo;
      console.error = originalConsoleError;
      console.warn = originalConsoleWarn;
      window.removeEventListener("error", handleUncaughtError);
    };
  }, [active]);

  const getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key: string, value: any) => {
      if (typeof value === "object" && value !== null) {
        if (seen.has(value)) {
          return "[Circular]";
        }
        seen.add(value);
      }
      return value;
    };
  };

  const clearLogs = () => {
    setLogs([]);
  };

  if (!active) return null;

  return (
    <Container collapsed={collapsed}>
      {collapsed ? (
        <ConsoleIcon style={{ color: "white" }} onClick={() => setCollapsed(false)} />
      ) : (
        <>
          <Header>
            <IconButton onClick={() => setLogUIActive(false)} style={{ color: "white", float: "left" }}>
              <CloseIcon />
            </IconButton>
            Log
            <IconContainer>
              <IconButton onClick={clearLogs} style={{ color: "white" }}>
                <ClearIcon />
              </IconButton>
              <IconButton onClick={() => setCollapsed(true)} style={{ color: "white" }}>
                <ExpandMoreIcon />
              </IconButton>
            </IconContainer>
          </Header>
          <LogContainer>
            {logs.map((log, index) => (
              <LogEntry key={index} type={log.type}>
                {log.message}
              </LogEntry>
            ))}
          </LogContainer>
        </>
      )}
    </Container>
  );
};

export default ConsoleLogUI;

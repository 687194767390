import React, { useState } from "react";
import { Button } from "@mui/material";
import styled from "styled-components/macro";
import { useNavigate } from "react-router-dom";
import DialogWindow from "../../components/other/DialogWindow";
import { useViewerQuery } from "../../queries/viewerQuery";
import { useEffectOnce, useToggle } from "react-use";
import { useDeleteUserMutation } from "../../queries/users/deleteUser";
import Row from "../../components/other/Row";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import GroupsIcon from "@mui/icons-material/Groups";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import DesktopWindowsIcon from "@mui/icons-material/DesktopWindows";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import CloudDoneOutlinedIcon from "@mui/icons-material/CloudDoneOutlined";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import { Layout } from "../../components/header/Header";
import AppLang from "../../components/other/AppLang";
import { Trans, useTranslation } from "react-i18next";
import DoneIcon from "@mui/icons-material/Done";
import { SocialLinks } from "./ProfileLinks";
import LinkExt from "../../components/other/LinkExt";
import MeAndFriends from "./MeAndFriends";
import { ViewerNotificationsIcon } from "./NotificationsScreen";
import OAuth from "components/other/OAuth";
import { canShowOAuth, topPadding } from "tools/device";
import { useLogout } from "queries/users/logout";
import EmailAuth from "root/auth/EmailAuth";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import SupportAgentOutlinedIcon from "@mui/icons-material/SupportAgentOutlined";
import ContinueWithEmailButton from "root/auth/ContinueWithEmailButton";
import { checkIfAdmobPrivacyOptionIsRequired, showAdmobPrivacyForm } from "components/initializers/ConsentInitializer";
import SchoolIcon from "@mui/icons-material/School";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import LaunchIcon from "@mui/icons-material/Launch";
import { ADMIN } from "../RootRouter.config";
import { AFFILIATE, HELP_CENTER, NOTIFICATIONS, PASSWORD, SUBSCRIPTION, TEACHERS } from "./ProfileRouter.config";

const Wrap = styled.div`
  padding: ${topPadding}px 0 0 0;
`;
const WrapAuth = styled.div`
  text-align: center;
  max-width: 320px;
  margin: 20px auto 20px;
`;
const SLayout = styled(Layout)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const Rows = styled.div`
  position: relative;
  svg {
    margin-right: 10px;
  }
`;
const RedRow = styled(Row)`
  color: ${({ theme }) => theme.duo.color.textGrey};
`;
const SubsRow = styled(Row)<{ subscribed: boolean }>`
  color: ${({ theme, subscribed }) => (subscribed ? theme.duo.color.text : theme.duo.color.primary)};
`;
const Green = styled(Row)`
  color: ${({ theme }) => theme.duo.color.green};
  text-align: center;
  font-size: 95%;
  line-height: 1.45em;
`;
const SDoneIcon = styled(DoneIcon)`
  margin: 0 0 0 auto !important;
  color: ${({ theme }) => theme.duo.color.green};
`;
const Terms = styled.div`
  margin: 12px;
  font-size: ${({ theme }) => theme.duo.fontSize.small};
  text-align: center;
  &,
  a {
    color: #aaa;
  }
`;
const SLaunchIcon = styled(LaunchIcon)`
  position: absolute;
  right: 10px;
  color: ${({ theme }) => theme.duo.color.textGrey};
`;
const SLinkExt = styled(LinkExt)`
  color: ${({ theme }) => theme.duo.color.text};
  display: block;
  border-bottom: 0.5px solid ${({ theme }) => theme.duo.color.grey};
`;

const Finish = styled.div`
  background: white;
  overflow: hidden;
  margin: 2px 0 15px;
  box-shadow: 1px 1px 8px #0002;
`;

const Profile: React.FC = () => {
  const [dialogOpen, toggleDialog] = useToggle(false);
  const [isEmailOpen, toggleEmailOpen] = useToggle(false);
  const [isAdmobPrivacyOptionRequired, setIsAdmobPrivacyOptionRequired] = useState(false);
  const deleteUser = useDeleteUserMutation();
  const logout = useLogout();
  const { viewer, isGuest } = useViewerQuery();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffectOnce(() => {
    checkIfAdmobPrivacyOptionIsRequired().then(setIsAdmobPrivacyOptionRequired);
  });

  if (!viewer) return null;

  const handleDeleteUser = () => {
    deleteUser();
    logout();
  };

  const subscribed = Number(viewer?.subscription?.timeToExpire) > 0;

  return (
    <Wrap>
      <DialogWindow open={dialogOpen} onClose={toggleDialog} title={t("Delete account")}>
        <p>
          {!isGuest
            ? t("Are you sure you want to delete your account?")
            : t("This will delete guest-account. Continue?")}
        </p>
        <Button color="primary" onClick={() => handleDeleteUser()}>
          {t("Yes")}
        </Button>
        <Button onClick={toggleDialog}>{t("Cancel")}</Button>
      </DialogWindow>
      <SLayout narrow>
        <AppLang />
        {isGuest ? (
          <>
            <Finish>
              <Green>
                <div>
                  <div>
                    <CloudDoneOutlinedIcon />
                    &nbsp;
                    <DesktopWindowsIcon />
                    <PhoneIphoneIcon />
                    <PhoneAndroidIcon />
                    <LockOutlinedIcon />
                  </div>
                  {t("Finish your registration so you can use all the features and login from any device")}
                </div>
              </Green>

              <WrapAuth>
                {canShowOAuth() && <OAuth />}
                {isEmailOpen ? (
                  <EmailAuth registrationOnly={true} />
                ) : (
                  <ContinueWithEmailButton onClick={toggleEmailOpen} />
                )}
              </WrapAuth>
            </Finish>

            <Rows>
              <Row onClick={() => navigate(NOTIFICATIONS.url())}>
                <ViewerNotificationsIcon coachLevel={viewer.coachLevel} /> {t("Notifications")}
              </Row>
              <Row onClick={() => navigate(HELP_CENTER.url())}>
                <SupportAgentOutlinedIcon /> {t("Help center")}
              </Row>
              <SubsRow onClick={() => navigate(SUBSCRIPTION.url())} subscribed={subscribed}>
                <WhatshotIcon /> <Trans>Subscription</Trans>
                {subscribed && <SDoneIcon />}
              </SubsRow>
            </Rows>

            <div style={{ textAlign: "center", margin: 20 }}>
              <Button sx={(theme) => ({ color: theme.palette.text.primary })} onClick={toggleDialog}>
                <ExitToAppOutlinedIcon /> &nbsp; {t("Leave")}
              </Button>
            </div>
          </>
        ) : (
          <>
            <MeAndFriends />

            <Rows>
              <Row onClick={() => navigate(NOTIFICATIONS.url())}>
                <ViewerNotificationsIcon coachLevel={viewer.coachLevel} /> {t("Notifications")}
              </Row>
              <Row onClick={() => navigate(HELP_CENTER.url())}>
                <SupportAgentOutlinedIcon /> {t("Help center")}
              </Row>
              <SubsRow onClick={() => navigate(SUBSCRIPTION.url())} subscribed={subscribed}>
                <WhatshotIcon /> <Trans>Subscription</Trans>
                {subscribed && <SDoneIcon />}
              </SubsRow>
              <Row onClick={() => navigate(PASSWORD.url())}>
                <LockOutlinedIcon /> {t("Login options")}
              </Row>
              {(viewer.admin || 0) >= 2 && (
                <Row onClick={() => navigate(ADMIN.url())} style={{ color: "purple" }}>
                  <AdminPanelSettingsIcon /> Admin
                </Row>
              )}
              {viewer.affiliate && (
                <Row onClick={() => navigate(AFFILIATE.url())}>
                  <GroupsIcon /> {t("Partner program")}
                </Row>
              )}
              <Row onClick={() => navigate(TEACHERS.url())}>
                <SchoolIcon /> {t("For teachers")}
              </Row>
              <SLinkExt href="https://duocards.canny.io/feature-requests">
                <Row>
                  <LightbulbIcon /> {t("Feature request")}
                  <SLaunchIcon />
                </Row>
              </SLinkExt>
              <Row onClick={() => logout()}>
                <MeetingRoomIcon /> {t("Logout")}
              </Row>
            </Rows>

            <SocialLinks />

            <div>
              <RedRow onClick={toggleDialog}>
                <DeleteForeverOutlinedIcon /> &nbsp; {t("Delete account")}
              </RedRow>
              <Terms>
                <LinkExt href="https://www.duocards.com/privacypolicy.html">
                  <Trans>privacy policy</Trans>
                </LinkExt>{" "}
                &nbsp;|&nbsp;{" "}
                <LinkExt href="https://www.duocards.com/termsofuse.html">
                  <Trans>terms of use</Trans>
                </LinkExt>
                {isAdmobPrivacyOptionRequired && (
                  <>
                    &nbsp;|&nbsp;{" "}
                    <span onClick={showAdmobPrivacyForm}>
                      <Trans>ads privacy settings</Trans>
                    </span>
                  </>
                )}
              </Terms>
            </div>
          </>
        )}
      </SLayout>
    </Wrap>
  );
};

export default Profile;

import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";

type TransitionComponentProps = TransitionProps & {
  children: React.ReactElement<any, any>;
  ref?: React.Ref<unknown>;
};

export const SlideDownTransition = ({ ref, ...props }: TransitionComponentProps) => {
  return <Slide direction="down" ref={ref} {...props} />;
};

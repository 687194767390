import { graphql } from "babel-plugin-relay/macro";
import { useMutation } from "relay-hooks";
import { useNavigate } from "react-router";
import { createDeckMutation } from "./__generated__/createDeckMutation.graphql";
import { useViewerQuery } from "../viewerQuery";
import { MAIN, VERIFY } from "../../root/RootRouter.config";

export const CreateDeck = graphql`
  mutation createDeckMutation($front: String!, $back: String!, $name: String) {
    createDeck(front: $front, back: $back, name: $name) {
      ...viewerQuery_decks
    }
  }
`;

export const useCreateDeckMutation = () => {
  const navigate = useNavigate();
  const { viewer } = useViewerQuery();

  const [mutate] = useMutation<createDeckMutation>(CreateDeck, {
    onCompleted: (res) => {
      // because there is no optimistic updater, we need to delay redirect so it happens after settings decks in redux (to avoid another redirect)
      setTimeout(() => {
        if (!viewer?.email || viewer?.emailVerified) {
          navigate(MAIN.url(), { replace: true });
        } else {
          navigate(VERIFY.url(), { replace: true });
        }
      }, 100);
    }
  });

  return (variables: createDeckMutation["variables"]) => mutate({ variables });
};

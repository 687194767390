import React from "react";
import { Trans, useTranslation } from "react-i18next";
import styled, { css } from "styled-components/macro";
import Row from "../../components/other/Row";
import { useViewerQuery } from "../../queries/viewerQuery";
import RowIn from "../../components/other/RowIn";
import { Button, Checkbox, FormControl, MenuItem, Select } from "@mui/material";
import { useSeeHint } from "../../components/hint/Hint";
import { useEditUser } from "../../queries/users/editUser";
import NightsStayIcon from "@mui/icons-material/NightsStay";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationImportantIcon from "@mui/icons-material/NotificationImportant";
import NotificationsOffIcon from "@mui/icons-material/NotificationsOff";
import Flames from "components/fire/Flames";
import NotificationsPermissionsAlert from "./NotificationsPermissionsAlert";
import { NotificationEnum, STREAK_XPS } from "sharedJs__generated/constants";
import { dispatchEvent, EVENT, snackbar } from "tools/events";
import { useGetState } from "components/ReduxProvider";

export const NOTIFICATIONSDONE = "notificationsDone"; // might not be seen by users from desktop

const Wrap = styled.div`
  display: flex;
  min-height: 100%;
  flex-direction: column;
  justify-content: space-around;
`;
const Emoji = styled.span`
  padding: 0 5px;
  font-size: 18px;
  line-height: 1.2em;
`;
const Title = styled.div<{ big?: boolean }>`
  font-weight: ${({ theme }) => theme.duo.fontWeight.w700};
  font-size: ${({ big }) => (big ? 105 : 85)}%;
  margin: ${({ big }) => (big ? "20px" : "10px 20px 5px")};
  text-align: center;
`;
const List = styled.div`
  svg {
    margin: 0 10px 0 0;
  }
`;
const SRow = styled(Row)<{ selected?: boolean }>`
  border: 1px solid white;
  padding: 17px 20px;
  border-top: 1px solid ${({ theme }) => theme.duo.color.grey};
  &:first-of-type {
    border-top: 1px solid white;
  }
  ${({ selected }) =>
    selected &&
    css`
      color: ${({ theme }) => theme.duo.color.primary};
      border: 1px solid #0099dd !important;
      border-radius: 4px;
    `}
`;
const FiresSettings = styled(SRow)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 100%;
  padding: 10px 5px;
  margin: 5px 0 0;
  color: inherit;
  font-size: 90%;
  & > div {
    display: flex;
    align-items: center;
  }
`;
const ButtonWrap = styled.div`
  padding: 5px 30px;
`;
const Bottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${({ theme }) => theme.duo.fontSize.smallest};
  line-height: 1.3em;
  padding: 5px;
  svg {
    font-size: 18px;
  }
`;
const SSelect = styled(Select)`
  margin: 3px 2px 3px 4px;
  .MuiSelect-select {
    padding: 7px 22px 7px 8px !important;
  }
  svg {
    right: -2px;
  }
`;

type Props = {
  inTutorial?: boolean;
  style?: React.CSSProperties;
};

const Notifications: React.FC<Props> = ({ inTutorial, style }) => {
  const { t } = useTranslation();
  const { viewer } = useViewerQuery();
  const defaultStreakTime = 19;
  const [time, setTime] = React.useState(viewer?.streakTime || defaultStreakTime); // default streak time is set in postgres as default when creating user
  const fcmId = useGetState("fcmId");
  const [isInitialTutorialView, setIsInitialTutorialView] = React.useState(inTutorial);

  const seeHint = useSeeHint();
  const editUser = useEditUser();

  const save = (values: { coachLevel?: number; streakTime?: number }) => {
    setIsInitialTutorialView(false);
    !inTutorial && dispatchEvent(EVENT.refreshNotifications);

    if (viewer?.coachLevel && values.coachLevel !== undefined && !values.coachLevel) {
      snackbar(t("For effective learning, it's essential to be notified at the right moments"), {
        severity: "error"
      });
    }
    editUser(values);
  };

  const notifyLevel = isInitialTutorialView ? 2 : viewer?.coachLevel;

  if (!viewer) return null;

  return (
    <Wrap style={style}>
      <div />
      {!inTutorial && viewer.coachLevel !== NotificationEnum.none && fcmId === null && (
        <NotificationsPermissionsAlert />
      )}
      <div>
        <Title big={inTutorial}>
          <Trans>How hard do you want to study?</Trans>
          <Emoji>{notifyLevel === 3 ? "💪" : notifyLevel === 2 ? "👌" : notifyLevel === 1 ? "🤏" : "🤫"}</Emoji>
        </Title>
        <List style={inTutorial ? { boxShadow: "0 0 16px #def", margin: "0 -15px" } : undefined}>
          <SRow selected={notifyLevel === 0} onClick={() => save({ coachLevel: 0 })}>
            <NotificationsOffIcon />
            <RowIn title={t("Turn off notifications")} text={t("I have my style")} />
          </SRow>

          <SRow selected={notifyLevel === 1} onClick={() => save({ coachLevel: 1 })}>
            <NotificationsNoneIcon />
            <RowIn title={t("Not much")} text={t("Notify me after 2 days of inactivity")} />
          </SRow>
          <SRow selected={notifyLevel === 2} onClick={() => save({ coachLevel: 2 })}>
            <NotificationsIcon />
            <RowIn title={t("Regularly")} text={t("Notify me after 1 day of inactivity")} />
          </SRow>
          <SRow selected={notifyLevel === 3} onClick={() => save({ coachLevel: 3 })}>
            <NotificationImportantIcon />
            <RowIn title={t("Very hard")} text={t("Notify me after 4 hours if there are cards to learn.")} />
          </SRow>
        </List>
      </div>

      {!inTutorial && (
        <div>
          <Title>
            <Trans>Daily streak notifications</Trans>
          </Title>
          <FiresSettings
            selected={!!viewer.streakTime}
            onClick={() => save({ streakTime: viewer.streakTime ? -1 : time })}
          >
            <Checkbox sx={{ margin: "1px", padding: "9px 1px 9px 1px" }} checked={!!viewer.streakTime} />
            <div>
              <Trans>Notify me at</Trans>{" "}
              <FormControl onClick={(e) => e.stopPropagation()}>
                <SSelect
                  disabled={!viewer.streakTime}
                  value={time}
                  onChange={(e) => {
                    setTime(Number(e.target.value));
                    save({ streakTime: Number(e.target.value) });
                  }}
                >
                  {[10, 12, 14, 16, 18, 19, 20, 21].map((val) => (
                    <MenuItem key={val} value={val}>
                      {val}:00
                    </MenuItem>
                  ))}
                </SSelect>
              </FormControl>
            </div>
            <div>
              <Trans>If I won't have</Trans>
              <Flames strength={4} xps={STREAK_XPS} />
            </div>
          </FiresSettings>
        </div>
      )}

      {inTutorial && (
        <ButtonWrap>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            onClick={() => {
              if (isInitialTutorialView) {
                save({ coachLevel: 2 });
              }
              seeHint(NOTIFICATIONSDONE);
              dispatchEvent(EVENT.refreshNotifications);
            }}
          >
            <Trans>Continue</Trans>
          </Button>
        </ButtonWrap>
      )}
      <Bottom>
        <NightsStayIcon />
        <Trans>No notifications between 22:00 and 9:00</Trans>
      </Bottom>
    </Wrap>
  );
};

export default Notifications;

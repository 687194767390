import React, { useRef, RefObject } from "react";
import CardItem from "../../../../components/other/CardItem";
import { cardsQuery_Deck$data } from "../../../../queries/cards/__generated__/cardsQuery_Deck.graphql";
import Battery20 from "@mui/icons-material/Battery20";
import Battery30 from "@mui/icons-material/Battery30";
import Battery50 from "@mui/icons-material/Battery50";
import Battery80 from "@mui/icons-material/Battery80";
import Battery90 from "@mui/icons-material/Battery90";
import BatteryFull from "@mui/icons-material/BatteryFull";
import { useTranslation } from "react-i18next";
import { useViewerDecks } from "../../../../queries/viewerQuery";
import { dispatchEvent, EVENT } from "../../../../tools/events";
import styled from "styled-components/macro";
import { MAXKNOWNCOUNT } from "../../../../sharedJs__generated/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashXmark, faEdit, faArrowsRotate } from "@duocards/pro-regular-svg-icons";

const SCardMenuItem = styled.div<{ isDelete?: boolean }>`
  display: grid;
  grid-template-columns: 30px 1fr;
  align-items: center;
  justify-items: center;
  gap: 10px;
  color: ${({ isDelete, theme }) => (isDelete ? theme.duo.color.red : "inherit")};
  > svg {
    font-size: 16px;
  }
`;

const batteryIcons = [
  <Battery20 />,
  <Battery20 />,
  <Battery30 />,
  <Battery50 />,
  <Battery80 />,
  <Battery90 />,
  <Battery90 />,
  <BatteryFull />
];

const StyledCardItem = styled(CardItem)``;

const SCardItem = React.forwardRef<HTMLDivElement, any>((props, ref) => <StyledCardItem {...props} ref={ref} />);

type Props = { cards: cardsQuery_Deck$data["cards"]["edges"]; onDeleteCard; onResetCard; hidden };

const CardListIn: React.FC<Props> = React.memo(({ cards, onDeleteCard, onResetCard, hidden }) => {
  const { deck } = useViewerDecks();
  const { t } = useTranslation();
  const nodeRefs = useRef<{ [key: string]: RefObject<HTMLDivElement> }>({});

  return (
    <>
      {cards?.map((edge) => {
        const card = edge?.node && {
          ...edge?.node,
          theory: edge?.node?.sCard?.theory || null
        };

        return (
          card &&
          card.id !== hidden && (
            <SCardItem
              ref={nodeRefs.current[card.id]}
              key={card.id}
              card={card}
              sourceId={card.sourceId}
              icon={batteryIcons[Math.min(card.knownCount || 0, 7)]}
              speechLang={deck?.front}
              onClick={() => card && dispatchEvent(EVENT.openCardEditDialog, { ...card, theory: card.theory?.theory })}
              mainScreenDesign
              iconColor={
                !card.waiting && Number(card.knownCount) < MAXKNOWNCOUNT
                  ? "green"
                  : Number(card.knownCount) >= 5
                    ? "gold"
                    : "primary"
              }
              actions={[
                {
                  title: (
                    <SCardMenuItem>
                      <FontAwesomeIcon icon={faEdit} />
                      {t("Edit")}
                    </SCardMenuItem>
                  ),
                  action: () =>
                    card && dispatchEvent(EVENT.openCardEditDialog, { ...card, theory: card.theory?.theory })
                },
                {
                  title: (
                    <SCardMenuItem>
                      <FontAwesomeIcon icon={faArrowsRotate} />
                      {t("Reset")}
                    </SCardMenuItem>
                  ),
                  action: () => card && onResetCard(card)
                },
                {
                  title: (
                    <SCardMenuItem isDelete>
                      <FontAwesomeIcon icon={faTrashXmark} />
                      {t("Delete")}
                    </SCardMenuItem>
                  ),
                  action: () => onDeleteCard(card)
                }
              ]}
            />
          )
        );
      })}
    </>
  );
});

export default CardListIn;

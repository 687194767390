import React from "react";
import {
  signInWithRedirect,
  getRedirectResult,
  GoogleAuthProvider as GoogleAuthProviderCordova,
  UserCredential,
  OAuthProvider as OAuthProviderCordova
} from "firebase/auth/cordova";
import {
  signInWithPopup,
  GoogleAuthProvider as GoogleAuthProviderWeb,
  OAuthProvider as OAuthProviderWeb
} from "firebase/auth";
import { useSetState } from "components/ReduxProvider";
import { isBrowser } from "tools/device";
import { graphql } from "babel-plugin-relay/macro";
import { useMutation } from "relay-hooks";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import * as Sentry from "@sentry/browser";
import { useInviteHash } from "components/dialogs/InviteDialog/InviteDialog";
import { OAuthMutation, OAuthProviderId } from "./__generated__/OAuthMutation.graphql";
import { snackbar, dispatchEvent, EVENT } from "tools/events";
import AppleIcon from "@mui/icons-material/Apple";
import { LoginButton } from "../../root/auth/LoginButton";
import { UserAction } from "components/initializers/UserActionsLogger";

const mutationBEAuth = graphql`
  mutation OAuthMutation(
    $idToken: String!
    $idTokenFirebase: String
    $photoUrl: String
    $inviteHash: String
    $oauthProviderId: OAuthProviderId
    $clientId: String
    $forceGuestToUserMerge: Boolean
  ) {
    oauth(
      idToken: $idToken
      idTokenFirebase: $idTokenFirebase
      photoUrl: $photoUrl
      inviteHash: $inviteHash
      oauthProviderId: $oauthProviderId
      clientId: $clientId
      forceGuestToUserMerge: $forceGuestToUserMerge
    ) {
      token
      deviceId
    }
  }
`;

const ButtonWrap = styled.div`
  margin: 0 0 20px 0;
`;

export enum OAuthButtonType {
  CONTINUE,
  CONNECT
}

type GogglePlusCredentials = {
  email: string;
  userId: string;
  displayName: string;
  familyName: string;
  givenName: string;
  imageUrl?: string;
  idToken?: string;
  serverAuthCode?: string;
  accessToken: string;
};

type Props = {
  type?: OAuthButtonType;
  hideApple?: boolean;
  hideGoogle?: boolean;
  forceGuestToUserMerge?: boolean;
};
const OAuth: React.FC<Props> = (p) => {
  const GoogleAuthProvider = isBrowser() ? GoogleAuthProviderWeb : GoogleAuthProviderCordova;
  const OAuthProvider = isBrowser() ? OAuthProviderWeb : OAuthProviderCordova;
  const [mutateAuth] = useMutation<OAuthMutation>(mutationBEAuth);
  const secondTry = React.useRef(false);
  const resultReceived = React.useRef(false);
  const usedOAuthProviderId = React.useRef<OAuthProviderId>("google");
  const { t } = useTranslation();
  const inviteHash = useInviteHash();
  const auth = (window as any).firebaseAuth;
  const setLoaderGlobal = useSetState("loaderGlobal");
  const setToken = useSetState("token");

  React.useEffect(() => {
    auth && auth.useDeviceLanguage();
  }, [auth]);

  const authFinished = (errorMessage?: string) => {
    setLoaderGlobal(false);
    if (errorMessage) {
      snackbar(t(errorMessage), { severity: "error" });
    }
    dispatchEvent(EVENT.logUserAction, {
      action: errorMessage ? UserAction.userAuthorizedFailed : UserAction.userAuthorized,
      detail: { type: usedOAuthProviderId.current }
    });
  };

  const authenticateOnBE = (idToken: string, idTokenFirebase?: string, photoUrl?: string | null, clientId?: string) => {
    mutateAuth({
      variables: {
        idToken,
        idTokenFirebase,
        photoUrl,
        inviteHash,
        oauthProviderId: usedOAuthProviderId.current,
        clientId,
        forceGuestToUserMerge: p.forceGuestToUserMerge
      },
      onCompleted: ({ oauth }) => {
        oauth && setToken(oauth.token);
        authFinished();
      },
      onError: (e) => {
        authFinished(e.message);
      }
    });
  };

  const handleGoogleCordovaAuthResponse = async (resultRaw: GogglePlusCredentials | string | null) => {
    // ios needs transfom string to object
    const result: GogglePlusCredentials | null = typeof resultRaw === "string" ? JSON.parse(resultRaw) : resultRaw;

    if (result && !resultReceived.current) {
      resultReceived.current = true;

      const idTokenFirebase = auth.currentUser !== null ? await auth.currentUser.getIdToken() : "";
      if (result && result.idToken) {
        authenticateOnBE(result.idToken, idTokenFirebase, result.imageUrl ?? null);
      } else {
        authFinished("Missing credentials, please try login again or contact tech support.");
      }
    } else {
      authFinished("Something went wrong. Please use email/password in the meantime.");
    }
  };

  const handleAuthResponse = async (result: UserCredential | null) => {
    setLoaderGlobal(true);
    if (result && !resultReceived.current) {
      resultReceived.current = true;

      const credentials =
        usedOAuthProviderId.current === "apple"
          ? OAuthProvider.credentialFromResult(result)
          : GoogleAuthProvider.credentialFromResult(result);
      const idTokenFirebase = await auth.currentUser.getIdToken();

      if (credentials && credentials.idToken) {
        authenticateOnBE(credentials.idToken, idTokenFirebase, result.user.photoURL);
      } else {
        authFinished("Missing credentials, please try login again or contact tech support.");
      }
    } else {
      if (secondTry.current) {
        authFinished("Something went wrong. Please use email/password in the meantime.");
      } else {
        secondTry.current = true;
        setTimeout(() => {
          if (!resultReceived.current) {
            startAuthProcess(usedOAuthProviderId.current);
          }
        }, 2000);
      }
    }
  };

  const startAuthProcess = (oauthProviderId: OAuthProviderId) => {
    setLoaderGlobal(true);
    usedOAuthProviderId.current = oauthProviderId;
    //
    // googleplus plugin
    if (oauthProviderId === "google" && window.plugins?.googleplus) {
      window.plugins?.googleplus.login(
        { webClientId: process.env.REACT_APP_GOOGLE_API_WEB_CLIENT_ID },
        (obj) => handleGoogleCordovaAuthResponse(obj),
        () => authFinished() // canceled by user
      );
    }
    //
    // cordova-plugin-sign-in-with-apple (only on ios)
    else if (oauthProviderId === "apple" && (window as any)?.cordova?.plugins?.SignInWithApple) {
      (window as any).cordova.plugins.SignInWithApple.signin(
        { requestedScopes: [0, 1] }, // 0,1 should be email and name
        (succ) => authenticateOnBE(succ.identityToken, undefined, undefined, "com.duocards.app"),
        () => authFinished() // canceled by user
      );
    }
    //
    // browser or appleId on android
    else {
      setTimeout(() => setLoaderGlobal(false), 2000);
      // signInWithPopup is internally checking for "iPad" in userAgent and throws error if do not find it
      const isIPad = (window as any)?.device?.model?.includes("iPad");
      if (isIPad && !navigator.userAgent.includes("iPad")) {
        const ua = navigator.userAgent;
        // @ts-ignore
        navigator.__defineGetter__("userAgent", () => ua + " iPad");
      }

      const provider =
        usedOAuthProviderId.current === "apple" ? new OAuthProvider("apple.com") : new GoogleAuthProvider();
      provider.addScope("email");
      const authPromise = isBrowser()
        ? signInWithPopup(auth, provider)
        : signInWithRedirect(auth, provider).then(() => getRedirectResult(auth));

      authPromise.then(handleAuthResponse).catch((e) => {
        console.warn(`Auth failed`, e.message);

        if (
          [
            "auth/network-request-failed",
            "auth/popup-blocked",
            "auth/user-cancelled",
            "auth/missing-or-invalid-nonce",
            "auth/invalid-credential-or-provider-id",
            "auth/cancelled-popup-request"
          ].includes(e.code)
        ) {
          authFinished();
          return;
        }

        if (e.code === "auth/operation-not-supported-in-this-environment") {
          authFinished(
            "This sign in method is not available on your device. Please log in using email and password instead."
          );
          return;
        }

        if (
          e.code ===
          "The requested mobile application corresponding to the identifier (Android package name or iOS bundle ID) provided is not installed on this device."
        ) {
          authFinished(
            "This sign in method is not available on your device. Please log in using email and password instead or write us at info@duocards.com."
          );
          Sentry.captureMessage("OAuth error: Android package name or iOS bundle ID missing");
          return;
        }

        // on some devices the auth closes the auth window prematurely, let's try to handle that usecase
        if (e.code === "auth/popup-closed-by-user" || e.code === "auth/redirect-cancelled-by-user") {
          setTimeout(() => {
            // in case that getRedirectResult keeps hanging on promise, let's hide the loading button, so user can at least login in a different way
            setLoaderGlobal(false);
          }, 5000);
          getRedirectResult(auth).then(handleAuthResponse);
          return;
        }

        Sentry.captureException(e);
        authFinished("Something went wrong. Our developers will look at it.");
      });
    }
  };

  return (
    <>
      {!p.hideGoogle && (
        <ButtonWrap>
          <LoginButton
            startIcon={<img src="https://www.google.com/favicon.ico" alt="google login" width="26" height="26" />}
            onClick={() => startAuthProcess("google")}
            fullWidth
          >
            {p.type === OAuthButtonType.CONNECT ? t("Connect with Google") : t("Continue with Google")}
          </LoginButton>
        </ButtonWrap>
      )}
      {!p.hideApple && (
        <ButtonWrap>
          <LoginButton
            startIcon={<AppleIcon style={{ marginTop: -2 }} />}
            onClick={() => startAuthProcess("apple")}
            fullWidth
          >
            {p.type === OAuthButtonType.CONNECT ? t("Connect with Apple") : t("Continue with Apple")}
          </LoginButton>
        </ButtonWrap>
      )}
    </>
  );
};

export default OAuth;

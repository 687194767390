import { useViewerDecks, useViewerQuery, Viewer } from "queries/viewerQuery";
import React from "react";
import { FIVEHINT } from "root/main/main/Main";
import { useLocation, useMatch } from "react-router";
import { FIRSTFEWHINT } from "./steps/FirstFewCards";
import { useSeeHint } from "components/hint/Hint";
import { ONBOARDING, MAIN } from "../RootRouter.config";
import { getNearestNonVirtualStep, useOnboardingStepsData } from "./onboarding/onboardingStepsData";
import { getStepHash } from "./onboarding/OnboardingRedirect";
import useOnboardingData from "hooks/useOnboardingData";
import { useNavigate } from "react-router-dom";
import { FIRSTCARDSCOUNT } from "./Tutorial";
import { useDBLog } from "hooks/useDBLog";
import { PETMAMMOTH } from "./steps/PetMammoth";
import { PreparedSources, StepScreenId } from "./onboarding/onboardingTypes";
import { usePrevious } from "react-use";
import { snackbar } from "tools/events";
import { Trans } from "react-i18next";
import { webFunnelSteps } from "./onboarding/onboardingStepsData";
import { useIsPublicUrl } from "root/RootRouter";
import { TUTORIALDONE } from "./steps/Verdict";

const getScreenIdForPreparedSources = (preparedSources: PreparedSources): StepScreenId => {
  if (preparedSources.videoId) {
    return StepScreenId.PREPARED_VIDEO;
  }
  if (preparedSources.articleId) {
    return StepScreenId.PREPARED_ARTICLE;
  }
  return StepScreenId.START_LEARNING; // no article or video -> start learning
};

const shouldBeInMainScreen = (nextStep: StepScreenId, hintWasSeen: (hint: string) => boolean): boolean => {
  const mainScreenSteps = [
    StepScreenId.PREPARED_COURSE_OPENED,
    StepScreenId.PREPARED_VIDEO_OPENED,
    StepScreenId.PREPARED_ARTICLE_OPENED,
    StepScreenId.PREPARED_COURSE,
    StepScreenId.PREPARED_VIDEO,
    StepScreenId.PREPARED_ARTICLE,
    StepScreenId.START_LEARNING
  ];

  return (hintWasSeen(FIVEHINT) || hintWasSeen(TUTORIALDONE)) && mainScreenSteps.includes(nextStep);
};

export const isWebFunnel = (viewer?: Viewer) =>
  !viewer?.deviceId &&
  !viewer?.emailVerified &&
  // erase line below for debugging web-funnel "emailLogin" steps locally
  process.env.NODE_ENV !== "development";

export const useOnboardingInitialAndForceRedirect = (shouldSkipOnboarding: boolean) => {
  const { deck } = useViewerDecks();
  const isPublicUrl = useIsPublicUrl();
  const seeHint = useSeeHint();
  const [initialRedirectDone, setInitialRedirectDone] = React.useState(shouldSkipOnboarding);
  const location = useLocation();
  const navigate = useNavigate();
  const { getOnboardingData } = useOnboardingData();
  const onboardingStepsData = useOnboardingStepsData();
  const { hintWasSeen, viewer } = useViewerQuery();
  const firstLoad = React.useRef(true);
  const onboardingData = getOnboardingData();
  const dbLog = useDBLog();
  const prevIsWebFunnel = usePrevious(isWebFunnel(viewer));
  const isWebFunnelChanged = viewer?.email && prevIsWebFunnel === true && isWebFunnel(viewer) === false; // edge-case when user just logged into mobile app but from webFunnel using webFunnelSelf referrer

  React.useEffect(() => {
    if (shouldSkipOnboarding || isPublicUrl) return;

    if (firstLoad.current || isWebFunnelChanged) {
      firstLoad.current = false;
      if (isWebFunnelChanged) snackbar(<Trans>Welcome back!</Trans>, { severity: "success", confetti: true });
      const { currentStep, latestStepReached, preparedSources } = getOnboardingData();

      let nextStep: StepScreenId | undefined;
      if (webFunnelSteps.includes((currentStep || latestStepReached) as any) && !isWebFunnel(viewer)) {
        nextStep = StepScreenId.PREPARED_COURSE;
      } else if (currentStep) {
        nextStep = currentStep;
      } else if (!latestStepReached) {
        nextStep = onboardingStepsData[0].screenId;
      } else if (!hintWasSeen(FIRSTFEWHINT)) {
        nextStep = StepScreenId.PREPARED_COURSE;
      } else if (!hintWasSeen(FIVEHINT)) {
        nextStep = getScreenIdForPreparedSources(preparedSources);
      } else {
        nextStep = latestStepReached;
      }

      nextStep = getNearestNonVirtualStep(nextStep);

      if (nextStep) {
        const targetUrl = shouldBeInMainScreen(nextStep, hintWasSeen)
          ? MAIN.url()
          : ONBOARDING.url() + getStepHash(nextStep);

        if (location.pathname !== targetUrl) {
          navigate(targetUrl);
        }
      }
      setInitialRedirectDone(true);
    }
  }, [
    onboardingStepsData,
    shouldSkipOnboarding,
    navigate,
    getOnboardingData,
    location,
    hintWasSeen,
    dbLog,
    deck,
    viewer,
    isWebFunnelChanged,
    isPublicUrl
  ]);

  React.useEffect(() => {
    if (shouldSkipOnboarding || hintWasSeen(FIRSTFEWHINT) || deck?.stats.total !== FIRSTCARDSCOUNT) return;
    seeHint(FIRSTFEWHINT);
    const screenId = getScreenIdForPreparedSources(onboardingData.preparedSources);
    navigate(ONBOARDING.url() + getStepHash(screenId), { replace: true });
  }, [deck, hintWasSeen, seeHint, navigate, onboardingData.preparedSources, shouldSkipOnboarding]);

  return initialRedirectDone;
};

export const useBlockOnboardingAfterCompletion = () => {
  const { hintWasSeen } = useViewerQuery();
  const hasOnboardingCompleted = hintWasSeen(PETMAMMOTH) || hintWasSeen(TUTORIALDONE);
  const inOnboarding = useMatch(ONBOARDING.url());
  const navigate = useNavigate();

  React.useEffect(() => {
    if (hasOnboardingCompleted && inOnboarding) {
      navigate(MAIN.url(), { replace: true });
    }
  }, [hasOnboardingCompleted, inOnboarding, navigate]);
};

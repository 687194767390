/**
 * @generated SignedSource<<d5c549f25b1c042a47bee8830565f633>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type FlatIconEdit = {
  flatId: string;
  url: string;
};
export type createSCardMutation$variables = {
  back?: string | null;
  backLang?: string | null;
  front: string;
  hint?: string | null;
  sourceId: string;
  svg?: FlatIconEdit | null;
  theory?: string | null;
};
export type createSCardMutation$data = {
  readonly createSCard: {
    readonly id: string;
    readonly sCards: ReadonlyArray<{
      readonly back: {
        readonly translated: boolean | null;
        readonly value: string;
      } | null;
      readonly front: string;
      readonly hint: string | null;
      readonly id: string;
      readonly sourceId: string;
      readonly svg: {
        readonly flatId: string;
        readonly url: string;
      } | null;
      readonly theory: {
        readonly canEdit: boolean;
        readonly lang: string;
        readonly langNative: string;
        readonly sCardId: string;
        readonly theory: string;
        readonly theoryEn: string | null;
        readonly theoryIsChecked: boolean;
        readonly theoryNative: string;
        readonly theoryNativeIsChecked: boolean;
      } | null;
    }>;
  } | null;
};
export type createSCardMutation = {
  response: createSCardMutation$data;
  variables: createSCardMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "back"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "backLang"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "front"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hint"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sourceId"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "svg"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "theory"
},
v7 = {
  "kind": "Variable",
  "name": "backLang",
  "variableName": "backLang"
},
v8 = [
  {
    "kind": "Variable",
    "name": "back",
    "variableName": "back"
  },
  (v7/*: any*/),
  {
    "kind": "Variable",
    "name": "front",
    "variableName": "front"
  },
  {
    "kind": "Variable",
    "name": "hint",
    "variableName": "hint"
  },
  {
    "kind": "Variable",
    "name": "sourceId",
    "variableName": "sourceId"
  },
  {
    "kind": "Variable",
    "name": "svg",
    "variableName": "svg"
  },
  {
    "kind": "Variable",
    "name": "theory",
    "variableName": "theory"
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v10 = [
  (v7/*: any*/)
],
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "front",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hint",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sourceId",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "translated",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "langNative",
      "variableName": "backLang"
    }
  ],
  "concreteType": "SCardTheory",
  "kind": "LinkedField",
  "name": "theory",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sCardId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "theory",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "theoryIsChecked",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "theoryNative",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "theoryNativeIsChecked",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "theoryEn",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lang",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "langNative",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canEdit",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "flatId",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "createSCardMutation",
    "selections": [
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "Source",
        "kind": "LinkedField",
        "name": "createSCard",
        "plural": false,
        "selections": [
          (v9/*: any*/),
          {
            "alias": null,
            "args": (v10/*: any*/),
            "concreteType": "SCard",
            "kind": "LinkedField",
            "name": "sCards",
            "plural": true,
            "selections": [
              (v9/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SCardBack",
                "kind": "LinkedField",
                "name": "back",
                "plural": false,
                "selections": [
                  (v14/*: any*/),
                  (v15/*: any*/)
                ],
                "storageKey": null
              },
              (v16/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Upload",
                "kind": "LinkedField",
                "name": "svg",
                "plural": false,
                "selections": [
                  (v17/*: any*/),
                  (v18/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v6/*: any*/),
      (v1/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Operation",
    "name": "createSCardMutation",
    "selections": [
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "Source",
        "kind": "LinkedField",
        "name": "createSCard",
        "plural": false,
        "selections": [
          (v9/*: any*/),
          {
            "alias": null,
            "args": (v10/*: any*/),
            "concreteType": "SCard",
            "kind": "LinkedField",
            "name": "sCards",
            "plural": true,
            "selections": [
              (v9/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SCardBack",
                "kind": "LinkedField",
                "name": "back",
                "plural": false,
                "selections": [
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              },
              (v16/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Upload",
                "kind": "LinkedField",
                "name": "svg",
                "plural": false,
                "selections": [
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "93f59fa0e2b6f9ba12134cec5689e598",
    "id": null,
    "metadata": {},
    "name": "createSCardMutation",
    "operationKind": "mutation",
    "text": "mutation createSCardMutation(\n  $sourceId: ID!\n  $front: String!\n  $hint: String\n  $back: String\n  $theory: String\n  $backLang: String\n  $svg: FlatIconEdit\n) {\n  createSCard(sourceId: $sourceId, front: $front, hint: $hint, back: $back, theory: $theory, backLang: $backLang, svg: $svg) {\n    id\n    sCards(backLang: $backLang) {\n      id\n      front\n      hint\n      sourceId\n      back {\n        value\n        translated\n        id\n      }\n      theory(langNative: $backLang) {\n        sCardId\n        theory\n        theoryIsChecked\n        theoryNative\n        theoryNativeIsChecked\n        theoryEn\n        lang\n        langNative\n        canEdit\n      }\n      svg {\n        flatId\n        url\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1d3be5e700c8af1094d6e5b75f7948f8";

export default node;

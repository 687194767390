import React from "react";
import * as Sentry from "@sentry/browser";
import { useEffect } from "react";

type SuccessCallback = (message: string) => void;
type FailureCallback = (message: string) => void;
type IdCallback = (id: string) => void;
type UrlCallback = (url: string) => void;

enum ClarityLogLevel {
  None = "None",
  Error = "Error",
  Warning = "Warning",
  Info = "Info",
  Debug = "Debug",
  Verbose = "Verbose"
}

type ClarityConfig = {
  isIonic?: boolean;
  userId?: string | null;
  logLevel?: ClarityLogLevel;
  allowMeteredNetworkUsage?: boolean;
  allowedDomains?: string[];
  disableOnLowEndDevices?: boolean;
  maximumDailyNetworkUsageInMB?: number | null;
};

// example config
// const clarityConfig: ClarityConfig = {
// isIonic: false,
// userId: null,
// allowMeteredNetworkUsage: false,
// allowedDomains: ["*"],
// disableOnLowEndDevices: true
// maximumDailyNetworkUsageInMB: null
// };

export interface ClarityPlugin {
  LogLevel: typeof ClarityLogLevel;
  initialize: (projectId: string, success: SuccessCallback, failure: FailureCallback, config?: ClarityConfig) => void;
  pause: (success: SuccessCallback, failure: FailureCallback) => void;
  resume: (success: SuccessCallback, failure: FailureCallback) => void;
  isPaused: (success: (isPaused: boolean) => void, failure: FailureCallback) => void;
  setCustomUserId: (userId: string, success: SuccessCallback, failure: FailureCallback) => void;
  setCustomSessionId: (sessionId: string, success: SuccessCallback, failure: FailureCallback) => void;
  getCurrentSessionId: (callback: IdCallback, failure: FailureCallback) => void;
  getCurrentSessionUrl: (callback: UrlCallback, failure: FailureCallback) => void;
  setCustomTag: (key: string, value: string, success: SuccessCallback, failure: FailureCallback) => void;
}

const initializeCordovaMSClarity = (): Promise<void> => {
  return new Promise((resolve, reject) => {
    const success: SuccessCallback = (message: string) => {
      // console.log("MS Clarity initialized successfully:", message);
      resolve();
    };

    const failure: FailureCallback = (message: string) => {
      const error = new Error(`MS Clarity initialization failed: ${message}`);
      reject(error);
    };

    const clarityPlugin = window.ClarityPlugin;
    if (clarityPlugin && clarityPlugin.initialize) {
      clarityPlugin.initialize("nxehmkivel", success, failure);
    } else {
      failure("ClarityPlugin not found");
    }
  });
};

const initializeWebMSClarity = (onLoadCallback?: () => void): HTMLScriptElement => {
  const script = document.createElement("script");
  script.innerHTML = `
    (function(c,l,a,r,i,t,y){
      c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
      t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
      y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
      })(window, document, "clarity", "script", "o5j6my12g4");
      `;

  script.onload = () => {
    onLoadCallback?.();
  };

  document.head.appendChild(script);
  return script;
};

const MSClarity: React.FC = () => {
  useEffect(() => {
    if (process.env.NODE_ENV === "development") {
      return;
    }

    let script: HTMLScriptElement | undefined;
    if (window.ClarityPlugin) {
      initializeCordovaMSClarity().catch((error) => {
        Sentry.captureException(error);
      });
    } else {
      script = initializeWebMSClarity();
    }

    return () => {
      if (script) {
        document.head.removeChild(script);
      }
    };
  }, []);

  return null;
};

export default MSClarity;

/**
 * @generated SignedSource<<e2b49584b8bd64e8d2822a7c3a5eedb7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type xpIncrementMutation$variables = {
  xps?: number | null;
};
export type xpIncrementMutation$data = {
  readonly xpIncrement: {
    readonly streakFreeze: number;
    readonly streakFreezeXps: number;
    readonly xps: {
      readonly currentStreak: number;
      readonly days: ReadonlyArray<{
        readonly added: number | null;
        readonly freeze: string | null;
        readonly reviewed: number | null;
        readonly streaked: boolean;
        readonly when: string;
        readonly xp: number | null;
      }>;
      readonly today: number;
      readonly todayExtra: number;
      readonly total: number;
      readonly yesterdayLostStreak: number;
    };
  };
};
export type xpIncrementMutation = {
  response: xpIncrementMutation$data;
  variables: xpIncrementMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "xps"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "xps",
    "variableName": "xps"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "streakFreeze",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "streakFreezeXps",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Xps",
  "kind": "LinkedField",
  "name": "xps",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "today",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "todayExtra",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "total",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currentStreak",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "yesterdayLostStreak",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DayXps",
      "kind": "LinkedField",
      "name": "days",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "xp",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "added",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "reviewed",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "when",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "streaked",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "freeze",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "xpIncrementMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "xpIncrement",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "xpIncrementMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "xpIncrement",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ac98275fd93e2d7b6f0ea828ddcdb216",
    "id": null,
    "metadata": {},
    "name": "xpIncrementMutation",
    "operationKind": "mutation",
    "text": "mutation xpIncrementMutation(\n  $xps: Int\n) {\n  xpIncrement(xps: $xps) {\n    streakFreeze\n    streakFreezeXps\n    xps {\n      today\n      todayExtra\n      total\n      currentStreak\n      yesterdayLostStreak\n      days {\n        xp\n        added\n        reviewed\n        when\n        streaked\n        freeze\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "5b0d5250b8a4e746280914cea5f55361";

export default node;

import React from "react";
import styled from "styled-components/macro";
import { useNavigate } from "react-router";
import { ADDCARDHASH } from "./CardNewDialog";
import BottomIcon from "./BottomIcon";
import { faPlus } from "@duocards/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SIconButton = styled(BottomIcon)`
  left: auto;
  right: 16px;
  z-index: 710; // above theoryWrapper

  svg {
    width: 20px;
    height: 20px;
  }
`;

type Props = {
  style?: React.CSSProperties;
  onClickOverwrite?: VoidFunction;
};

const CardNewButton: React.FC<Props> = ({ style, onClickOverwrite }) => {
  const navigate = useNavigate();

  return (
    <SIconButton onClick={onClickOverwrite ? onClickOverwrite : () => navigate({ hash: ADDCARDHASH })} style={style}>
      <FontAwesomeIcon icon={faPlus} />
    </SIconButton>
  );
};

export default CardNewButton;

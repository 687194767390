/**
 * @generated SignedSource<<c6330ef8a3a85edcb8ed37abe1dc4ef1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SubscriptionPlatform = "android" | "browser" | "ios" | "lemon" | "%future added value";
export type viewerQuery$variables = {};
export type viewerQuery$data = {
  readonly viewer: {
    readonly abtest: string | null;
    readonly admin: number | null;
    readonly affiliate: string | null;
    readonly aiMessagesUsedThisWeek: number;
    readonly appLang: string | null;
    readonly appleId: string | null;
    readonly bio: string | null;
    readonly coachLevel: number;
    readonly createdBeforeDays: number;
    readonly createdBeforeSeconds: number;
    readonly defaultLanguages: {
      readonly native: string | null;
      readonly target: string | null;
    } | null;
    readonly deviceId: string | null;
    readonly deviceOs: string | null;
    readonly email: string | null;
    readonly emailVerified: boolean;
    readonly fcmId: string | null;
    readonly flags: ReadonlyArray<{
      readonly body: string | null;
      readonly name: string;
      readonly updated: number;
      readonly value: string | null;
    }>;
    readonly gId: string | null;
    readonly id: string;
    readonly installReferrer: string | null;
    readonly inviter: {
      readonly deviceId: string | null;
      readonly id: string;
      readonly name: string | null;
    } | null;
    readonly name: string | null;
    readonly picture: string | null;
    readonly seenHints: ReadonlyArray<{
      readonly name: string;
      readonly when: number;
    }>;
    readonly showSubsKickDialog: boolean;
    readonly streakFreeze: number;
    readonly streakFreezeXps: number;
    readonly streakTime: number | null;
    readonly subscriptions: ReadonlyArray<{
      readonly cancelled: string | null;
      readonly famembers: ReadonlyArray<{
        readonly id: string;
        readonly name: string | null;
        readonly picture: string | null;
        readonly xps: {
          readonly currentStreak: number;
          readonly today: number;
          readonly total: number;
        };
      }>;
      readonly family: boolean;
      readonly platform: SubscriptionPlatform;
      readonly storeId: string;
      readonly timeToExpire: number;
      readonly transactionId: string;
    }>;
    readonly timezone: string;
    readonly timezonePrices: ReadonlyArray<{
      readonly amount: number;
      readonly amountOriginal: string | null;
      readonly basePlanId: string;
      readonly currency: string;
      readonly currencyOriginal: string | null;
    }>;
    readonly verifiedBeforeDays: number | null;
    readonly " $fragmentSpreads": FragmentRefs<"viewerQuery_decks" | "viewerQuery_friends" | "viewerQuery_xps">;
  };
};
export type viewerQuery = {
  response: viewerQuery$data;
  variables: viewerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bio",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "picture",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "emailVerified",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "admin",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "when",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "Hint",
  "kind": "LinkedField",
  "name": "seenHints",
  "plural": true,
  "selections": [
    (v1/*: any*/),
    (v7/*: any*/)
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timezone",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "TimezonePrice",
  "kind": "LinkedField",
  "name": "timezonePrices",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "basePlanId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "amount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currency",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "amountOriginal",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currencyOriginal",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "appLang",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fcmId",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "coachLevel",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deviceId",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deviceOs",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "installReferrer",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "affiliate",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "gId",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "appleId",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "streakTime",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "streakFreeze",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "streakFreezeXps",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "verifiedBeforeDays",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdBeforeDays",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdBeforeSeconds",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "showSubsKickDialog",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "abtest",
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "aiMessagesUsedThisWeek",
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "inviter",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    (v14/*: any*/)
  ],
  "storageKey": null
},
v30 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "total",
  "storageKey": null
},
v31 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "today",
  "storageKey": null
},
v32 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currentStreak",
  "storageKey": null
},
v33 = [
  (v0/*: any*/),
  (v1/*: any*/),
  (v3/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "Xps",
    "kind": "LinkedField",
    "name": "xps",
    "plural": false,
    "selections": [
      (v30/*: any*/),
      (v31/*: any*/),
      (v32/*: any*/)
    ],
    "storageKey": null
  }
],
v34 = {
  "alias": null,
  "args": null,
  "concreteType": "Subscription",
  "kind": "LinkedField",
  "name": "subscriptions",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "transactionId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timeToExpire",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "platform",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "storeId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "family",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cancelled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "famembers",
      "plural": true,
      "selections": (v33/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
},
v35 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v36 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "body",
  "storageKey": null
},
v37 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updated",
  "storageKey": null
},
v38 = {
  "alias": null,
  "args": null,
  "concreteType": "DefaultLanguages",
  "kind": "LinkedField",
  "name": "defaultLanguages",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "target",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "native",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "viewerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/),
          (v18/*: any*/),
          (v19/*: any*/),
          (v20/*: any*/),
          (v21/*: any*/),
          (v22/*: any*/),
          (v23/*: any*/),
          (v24/*: any*/),
          (v25/*: any*/),
          (v26/*: any*/),
          (v27/*: any*/),
          (v28/*: any*/),
          (v29/*: any*/),
          (v34/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserFlags",
            "kind": "LinkedField",
            "name": "flags",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v35/*: any*/),
              (v36/*: any*/),
              (v37/*: any*/)
            ],
            "storageKey": null
          },
          (v38/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "viewerQuery_friends"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "viewerQuery_decks"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "viewerQuery_xps"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "viewerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/),
          (v18/*: any*/),
          (v19/*: any*/),
          (v20/*: any*/),
          (v21/*: any*/),
          (v22/*: any*/),
          (v23/*: any*/),
          (v24/*: any*/),
          (v25/*: any*/),
          (v26/*: any*/),
          (v27/*: any*/),
          (v28/*: any*/),
          (v29/*: any*/),
          (v34/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserFlags",
            "kind": "LinkedField",
            "name": "flags",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v35/*: any*/),
              (v36/*: any*/),
              (v37/*: any*/),
              (v0/*: any*/)
            ],
            "storageKey": null
          },
          (v38/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Friends",
            "kind": "LinkedField",
            "name": "friends",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Friend",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "friend",
                    "plural": false,
                    "selections": (v33/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasOnlyFewFriends",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "shouldBeConfirmed",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Deck",
            "kind": "LinkedField",
            "name": "decks",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "front",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "back",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Stats",
                "kind": "LinkedField",
                "name": "stats",
                "plural": false,
                "selections": [
                  (v30/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "known",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "unknown",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "completed",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TtsVoice",
                "kind": "LinkedField",
                "name": "voices",
                "plural": true,
                "selections": [
                  (v0/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Xps",
            "kind": "LinkedField",
            "name": "xps",
            "plural": false,
            "selections": [
              (v31/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "todayExtra",
                "storageKey": null
              },
              (v30/*: any*/),
              (v32/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "yesterdayLostStreak",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "DayXps",
                "kind": "LinkedField",
                "name": "days",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "xp",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "added",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "reviewed",
                    "storageKey": null
                  },
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "streaked",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "freeze",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7e12a278dbc7d4d88e8b05083f9c90e1",
    "id": null,
    "metadata": {},
    "name": "viewerQuery",
    "operationKind": "query",
    "text": "query viewerQuery {\n  viewer {\n    id\n    name\n    bio\n    picture\n    email\n    emailVerified\n    admin\n    seenHints {\n      name\n      when\n    }\n    timezone\n    timezonePrices {\n      basePlanId\n      amount\n      currency\n      amountOriginal\n      currencyOriginal\n    }\n    appLang\n    fcmId\n    coachLevel\n    deviceId\n    deviceOs\n    installReferrer\n    affiliate\n    gId\n    appleId\n    streakTime\n    streakFreeze\n    streakFreezeXps\n    verifiedBeforeDays\n    createdBeforeDays\n    createdBeforeSeconds\n    showSubsKickDialog\n    abtest\n    aiMessagesUsedThisWeek\n    inviter {\n      id\n      name\n      deviceId\n    }\n    subscriptions {\n      transactionId\n      timeToExpire\n      platform\n      storeId\n      family\n      cancelled\n      famembers {\n        id\n        name\n        picture\n        xps {\n          total\n          today\n          currentStreak\n        }\n      }\n    }\n    flags {\n      name\n      value\n      body\n      updated\n      id\n    }\n    defaultLanguages {\n      target\n      native\n    }\n    ...viewerQuery_friends\n    ...viewerQuery_decks\n    ...viewerQuery_xps\n  }\n}\n\nfragment viewerQuery_decks on User {\n  decks {\n    id\n    name\n    front\n    back\n    stats {\n      total\n      known\n      unknown\n      completed\n    }\n    voices {\n      id\n    }\n  }\n}\n\nfragment viewerQuery_friends on User {\n  friends {\n    edges {\n      friend {\n        id\n        name\n        picture\n        xps {\n          total\n          today\n          currentStreak\n        }\n      }\n      hasOnlyFewFriends\n      shouldBeConfirmed\n    }\n  }\n}\n\nfragment viewerQuery_xps on User {\n  xps {\n    today\n    todayExtra\n    total\n    currentStreak\n    yesterdayLostStreak\n    days {\n      xp\n      added\n      reviewed\n      when\n      streaked\n      freeze\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e9955a9ce40f86202ee2bb6141539ae0";

export default node;

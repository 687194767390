import { Trans, useTranslation } from "react-i18next";
import { questionsData } from "../questions/questions";
import OnboardingQuestion from "../questions/OnboardingQuestion";
import AchievementList from "./AchievementList";
import CoursePreparationLoader from "./CoursePreparationLoader";
import LanguagesSelect from "./LanguagesSelect";
import PreparedSource from "./PreparedSource";
import StartLearning from "./StartLearning";
import Welcome from "./Welcome";
import SetEmail from "./SetEmail";
import FollowEmailedInstructions from "./FollowEmailedInstructions";
import FirstSubscription from "../misc/FirstSubscription";
import { isDesktop } from "tools/device";
import { useViewerQuery } from "queries/viewerQuery";
import { isWebFunnel } from "../onboardingRedirects";
import { OnboardingStepDataType, StepScreenId } from "./onboardingTypes";
import { useABTest } from "queries/users/useABTest";
import useUserProfile from "hooks/useUserProfile";
import TeachersInfoStep from "./TeachersInfoStep";

export const getNearestNonVirtualStep = (step: StepScreenId) => {
  if (step === StepScreenId.PREPARED_COURSE_OPENED) return StepScreenId.PREPARED_COURSE;
  if (step === StepScreenId.PREPARED_VIDEO_OPENED) return StepScreenId.PREPARED_VIDEO;
  if (step === StepScreenId.PREPARED_ARTICLE_OPENED) return StepScreenId.PREPARED_ARTICLE;
  return step;
};

// for refreshing when language changes, simple Trans component doesn't work
export const TT: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { t } = useTranslation();
  return <Trans t={t}>{children}</Trans>;
};

export const webFunnelSteps = [StepScreenId.SET_EMAIL, StepScreenId.FOLLOW_EMAILED_INSTRUCTIONS];
const nativeSteps = [StepScreenId.QUESTIONS_NOTIFICATIONS]; // only in app

export const useOnboardingStepsData = (): OnboardingStepDataType<React.FC<any>>[] => {
  const { viewer } = useViewerQuery();
  const { getUserProfileData } = useUserProfile();
  const teachersOnboarding = useABTest("abtest_teachersOnboarding");
  const withoutWelcome = useABTest("abtest_withoutWelcome");

  const onboardingSteps: OnboardingStepDataType<React.FC<any>>[] = [
    {
      canGoBack: true,
      screenId: StepScreenId.WELCOME,
      motivation: (
        <TT>
          Hi! I'm <b>Memo</b>, and I'll be guiding you through the app.
        </TT>
      ),
      hideProgressBar: true,
      optional: true,
      forceLargeMammoth: true,
      component: () => <Welcome />
    },

    {
      screenId: StepScreenId.WELCOME_2,
      motivation: <TT>Just a few quick questions before we get started with learning!</TT>,
      optional: true
    },

    {
      canGoBack: withoutWelcome,
      screenId: StepScreenId.QUESTIONS_LANGUAGE,
      motivation: (
        <TT>
          What <b>language</b> are we going to learn together?
        </TT>
      ),
      optional: true,
      component: LanguagesSelect,
      componentProps: {
        step: "language-select-1"
      }
    } as OnboardingStepDataType<typeof LanguagesSelect>,

    {
      screenId: StepScreenId.QUESTIONS_LANGUAGE_2,
      motivation: (
        <TT>
          What <b>language</b> do you speak?
        </TT>
      ),
      component: LanguagesSelect,
      componentProps: {
        step: "language-select-2"
      }
    } as OnboardingStepDataType<typeof LanguagesSelect>,

    {
      screenId: StepScreenId.QUESTIONS_LEVEL,
      motivation: (
        <TT>
          What's your current <b>level</b>?
        </TT>
      ),
      component: OnboardingQuestion,
      componentProps: questionsData.level,
      hideSubmitButton: true,
      optional: false
    } as OnboardingStepDataType<typeof OnboardingQuestion>,

    {
      screenId: StepScreenId.QUESTIONS_NOTIFICATIONS,
      motivation: <TT>How hard do you want to study?</TT>,
      component: OnboardingQuestion,
      componentProps: questionsData.notifications,
      optional: false
    } as OnboardingStepDataType<typeof OnboardingQuestion>,

    {
      screenId: StepScreenId.QUESTIONS_AGE,
      component: OnboardingQuestion,
      hideSubmitButton: true,
      optional: false,
      motivation: (
        <TT>
          How <b>old</b> are you?
        </TT>
      ),
      componentProps: questionsData.age
    } as OnboardingStepDataType<typeof OnboardingQuestion>,

    ...(teachersOnboarding
      ? [
          {
            screenId: StepScreenId.QUESTIONS_ROLE,
            component: OnboardingQuestion,
            componentProps: questionsData.role,
            optional: false,
            hideSubmitButton: false,
            motivation: (
              <TT>
                Are you a <b>student</b> or a <b>teacher</b>?
              </TT>
            )
          } as OnboardingStepDataType<typeof OnboardingQuestion>
        ]
      : []),

    ...(getUserProfileData().role === "teacher"
      ? [
          {
            motivation: <TT>As a teacher, you can:</TT>,
            screenId: StepScreenId.TEACHERS_INFO,
            component: TeachersInfoStep,
            optional: true
          } as OnboardingStepDataType<typeof TeachersInfoStep>
        ]
      : [
          {
            screenId: StepScreenId.QUESTIONS_MOTIVATION,
            component: OnboardingQuestion,
            componentProps: questionsData.motivation,
            optional: false,
            hideSubmitButton: true,
            motivation: (
              <TT>
                What is your <b>goal</b> in learning?
              </TT>
            )
          } as OnboardingStepDataType<typeof OnboardingQuestion>
        ]),

    {
      screenId: StepScreenId.QUESTIONS_REFERRALS,
      motivation: <TT>Where did you learn about Duocards app?</TT>,
      component: OnboardingQuestion,
      componentProps: questionsData.referrals,
      optional: false,
      hideSubmitButton: true
    } as OnboardingStepDataType<typeof OnboardingQuestion>,

    {
      screenId: StepScreenId.QUESTIONS_INTERESTS,
      motivation: (
        <TT>
          What are <b>your interests</b>? Choose one or more.
        </TT>
      ),
      component: OnboardingQuestion,
      componentProps: questionsData.interests,
      optional: false
    } as OnboardingStepDataType<typeof OnboardingQuestion>,

    {
      screenId: StepScreenId.COURSE_PREPARATION,
      component: CoursePreparationLoader,
      hideProgressBar: true,
      hideSubmitButton: true
    } as OnboardingStepDataType<typeof CoursePreparationLoader>,

    {
      screenId: StepScreenId.FIRST_WEEK,
      motivation: (
        <TT>
          You will learn <b>50 new words</b> in the first week!
        </TT>
      ),
      optional: true
    } as OnboardingStepDataType<typeof AchievementList>,
    {
      screenId: StepScreenId.SET_EMAIL, // only for browser (without deviceId)
      motivation: (
        <TT>
          What is your <b>email</b>?
        </TT>
      ),
      component: SetEmail,
      hideSubmitButton: true,
      hideProgressBar: false
    } as OnboardingStepDataType<typeof SetEmail>,
    {
      screenId: StepScreenId.ACHIEVEMENTS,
      motivation: <TT>Let's take a look at what you will achieve in the first three months!</TT>,
      component: AchievementList,
      hideProgressBar: false,
      optional: true
    } as OnboardingStepDataType<typeof AchievementList>,

    {
      screenId: StepScreenId.FIRST_PAYWALL,
      component: FirstSubscription,
      hideSubmitButton: true,
      hideProgressBar: true
    } as OnboardingStepDataType<typeof AchievementList>,

    {
      screenId: StepScreenId.FOLLOW_EMAILED_INSTRUCTIONS, // only for browser (without deviceId) = last step
      motivation: <TT>{isDesktop() ? "Open the email on your phone." : "We are getting close."}</TT>,
      component: FollowEmailedInstructions,
      hideSubmitButton: true,
      hideProgressBar: true
    } as OnboardingStepDataType<typeof FollowEmailedInstructions>,

    {
      screenId: StepScreenId.PREPARED_COURSE,
      motivation: <TT>Your learning plan is ready! I hope you’ll like it.</TT>,
      component: PreparedSource,
      componentProps: {
        type: "setOrCourse"
      },
      hideSubmitButton: true,
      hideProgressBar: true
    } as OnboardingStepDataType<typeof PreparedSource>,

    {
      screenId: StepScreenId.PREPARED_VIDEO,
      motivation: <TT>Now let's take a look at how to add words from videos and movies!</TT>,
      component: PreparedSource,
      componentProps: {
        type: "video"
      },
      hideSubmitButton: true,
      hideProgressBar: true
    } as OnboardingStepDataType<typeof PreparedSource>,

    {
      screenId: StepScreenId.PREPARED_ARTICLE,
      motivation: <TT>Now let's take a look at how to add words from articles!</TT>,
      component: PreparedSource,
      componentProps: {
        type: "article"
      },
      hideSubmitButton: true,
      hideProgressBar: true
    } as OnboardingStepDataType<typeof PreparedSource>,

    {
      screenId: StepScreenId.START_LEARNING,
      motivation: <TT>Great, you just created your deck of cards. Let's start learning!</TT>,
      hideSubmitButton: true,
      hideProgressBar: true,
      forceLargeMammoth: true,
      component: StartLearning
    } as OnboardingStepDataType<typeof StartLearning>
  ];

  return onboardingSteps.filter((step) => {
    if (withoutWelcome && [StepScreenId.WELCOME, StepScreenId.WELCOME_2].includes(step.screenId)) return false;

    if (isWebFunnel(viewer) && nativeSteps.includes(step.screenId)) return false;
    if (!isWebFunnel(viewer) && webFunnelSteps.includes(step.screenId)) return false;
    if (viewer?.email && step.screenId === StepScreenId.SET_EMAIL) return false;

    return true;
  });
};

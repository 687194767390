import { Trans } from "react-i18next";
import styled from "styled-components/macro";

const Title = styled.div`
  font-size: 1.4rem;
  text-transform: uppercase;
  margin: 20px 0;
  line-height: 1.3;
`;
const SubTitle = styled.div`
  font-size: 1.2rem;
  margin: 10px 0;
  line-height: 1.3;
`;
const Quiet = styled.div`
  margin-top: -20px;
  margin-bottom: 40px;
  font-size: 0.8rem;
  line-height: 1.6;
`;
const SImg = styled.img`
  width: 60%;
  display: block;
  margin: 0 auto;
`;

const SaleMainMotherLanguage = () => {
  return (
    <>
      <Title>
        <Trans>Mother Language Day</Trans>
      </Title>
      <SubTitle>
        <Trans>Celebrate your mother language by learning a new one!</Trans>
      </SubTitle>

      <SImg src="https://cdn.duocards.com/mail/mamooth-sale-60-no-celeb.png" />

      <Title>
        <Trans>
          Grab your
          <br />
          <strong>60% discount!</strong>
        </Trans>
      </Title>

      <Quiet>
        <Trans>It's not going to be better than this!</Trans>
      </Quiet>
    </>
  );
};

export default SaleMainMotherLanguage;

import React from "react";
import DialogFullWindow from "components/other/DialogFullWindow";
import GeneratedAppLangWarning, { GENERATED_APPLANG_HINT } from "root/other/GeneratedAppLangWarning";
import { useViewerQuery } from "queries/viewerQuery";
import { isAppLangGenerated } from "sharedJs__generated/langsList";
import { useGetState } from "components/ReduxProvider";
import { useABTest } from "queries/users/useABTest";

const GeneratedAppLangDialog: React.FC = () => {
  const { hintWasSeen, viewer } = useViewerQuery();
  const pickedLang = useGetState("pickedLang");

  const eligible = viewer && pickedLang && isAppLangGenerated(pickedLang) && !hintWasSeen(GENERATED_APPLANG_HINT);

  const abtest_onDemand_generatedAppLangDialog = useABTest("abtest_onDemand_generatedAppLangDialog", {
    createIfNotExists: !!eligible
  });

  if (!eligible || !abtest_onDemand_generatedAppLangDialog) return null;

  return (
    <DialogFullWindow open={true} background>
      <GeneratedAppLangWarning />
    </DialogFullWindow>
  );
};

export default GeneratedAppLangDialog;

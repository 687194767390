import { graphql } from "babel-plugin-relay/macro";

export const sourceEditQL = graphql`
  query sourceEditQuery($id: ID!, $backLang: String) {
    node(id: $id) {
      ... on Source {
        id
        uri
        nameRaw
        name(langNative: $backLang)
        lang
        multiLang
        kind
        course
        text
        source
        duoLang
        private
        difficulty
        videoStart
        videoLang
        videoDuration
        imageId
        image {
          url
        }
        natives {
          langNative
          nameNative
        }
        sCards(backLang: $backLang) {
          id
          front
          hint
          sourceId
          back {
            value
            translated
          }
          theory(langNative: $backLang) {
            sCardId
            theory
            theoryIsChecked
            theoryNative
            theoryNativeIsChecked
            theoryEn
            lang
            langNative
            canEdit
          }
          svg {
            flatId
            url
          }
        }
        contentTest {
          active
        }
      }
    }
  }
`;

import { Button, Paper, Radio, Stack } from "@mui/material";
import React from "react";
import styled, { css } from "styled-components/macro";
import { Trans, useTranslation } from "react-i18next";
import GTranslateIcon from "@mui/icons-material/GTranslate";
import { useDeviceLang } from "hooks/deviceHooks";
import LangDisplay from "components/LangMenu/LangDisplay";
import { useSetState } from "components/ReduxProvider";
import { useSeeHint } from "components/hint/Hint";

export const GENERATED_APPLANG_HINT = "generatedAppLangHint";

const TopIco = styled.div`
  margin-bottom: 10px;
  svg {
    color: ${({ theme }) => theme.duo.color.primary};
    font-size: 40px;
  }
`;
const Wrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  max-width: 600px;
  margin: 0 auto;
`;
const SPaper = styled(Paper)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 85%;
  padding: 50px 30px;
  background: #fff !important;
`;
const Title = styled.div`
  font-size: ${({ theme }) => theme.duo.fontSize.medium};
  font-weight: ${({ theme }) => theme.duo.fontWeight.w700};
  margin: 20px 0;
`;
const Text = styled.div`
  margin-bottom: 20px;
  font-size: 95%;
`;
const RadioRow = styled.div<{ selected?: boolean; first?: boolean }>`
  border: 1px solid white;
  padding: 17px 20px;
  cursor: pointer;

  ${(p) =>
    !p.first &&
    css`
      border-top: 1px solid ${({ theme }) => theme.duo.color.grey};
    `}

  ${(p) =>
    p.selected &&
    css`
      color: ${({ theme }) => theme.duo.color.primary};
      border: 1px solid #0099dd !important;
      border-radius: 4px;
    `}
`;
const SLangDisplay = styled(LangDisplay)`
  font-size: ${({ theme }) => theme.duo.fontSize.normal};
  font-weight: ${({ theme }) => theme.duo.fontWeight.w700};
  text-align: left;
`;
const ButtonStack = styled(Stack)`
  margin-top: 30px;
`;

const GeneratedAppLangWarning: React.FC<{}> = () => {
  const [selectedVariant, setSelectedVariant] = React.useState<string | undefined>();
  const { browserLang } = useDeviceLang();
  const { t } = useTranslation();
  const seeHint = useSeeHint();
  const setPickedLang = useSetState("pickedLang");

  const handleSave = () => {
    seeHint(GENERATED_APPLANG_HINT);
    setPickedLang(selectedVariant);
  };

  return (
    <Wrap>
      <SPaper>
        <TopIco>
          <GTranslateIcon />
        </TopIco>
        <Title>
          <Trans>Application language</Trans>
        </Title>
        <Text>
          {t(
            "The app is displayed in your native language. These translations are only generated by a machine and can contain mistakes."
          )}{" "}
          <Trans>Is it ok or should I use English?</Trans>
        </Text>

        <RadioRow selected={selectedVariant === "en"} onClick={() => setSelectedVariant("en")} first={true}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Radio checked={selectedVariant === "en"} color="primary" />
            <SLangDisplay lang={"en"} />
          </Stack>
        </RadioRow>

        <RadioRow selected={selectedVariant === browserLang} onClick={() => setSelectedVariant(browserLang)}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Radio checked={selectedVariant === browserLang} color="primary" />
            <SLangDisplay lang={browserLang} showAutoGenerated={true} />
          </Stack>
        </RadioRow>

        <ButtonStack direction="row" alignItems="center" justifyContent="center">
          <Button color="primary" variant="contained" onClick={handleSave} disabled={!selectedVariant}>
            <Trans>Save</Trans>
          </Button>
        </ButtonStack>
      </SPaper>
    </Wrap>
  );
};

export default GeneratedAppLangWarning;

import React from "react";
import { useDispatch } from "react-redux";
import styled, { css } from "styled-components/macro";
import { flag } from "../../tools/langs";
import { TTheory } from "types/TheoryTypes";
import { setTheoryLang } from "../../components/ReduxProvider";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const ButtonGroup = styled.div<{ $courseLayout: boolean }>`
  position: relative;
  opacity: 0.7;
  right: -12px;
  top: -4px;
  flex-shrink: 0;
  border-bottom-left-radius: 4px;

  ${(p) =>
    p.$courseLayout &&
    css`
      opacity: 1;
      right: 0;
      top: 0;
    `}
`;
const Btn = styled.div<{ selected: boolean; grey?: boolean; $courseLayout: boolean }>`
  position: relative;
  cursor: pointer;
  display: inline-block;
  padding: 8px 6px 2px;
  filter: ${({ selected }) => (!selected ? "grayscale(60%)" : "none")};
  opacity: ${({ selected }) => (!selected ? 0.4 : 1)};

  ${(p) => p.$courseLayout && !p.selected && `opacity: 0.4;`}
`;
const Hidden = styled(VisibilityOffIcon)`
  position: absolute;
  color: grey;
  font-size: 15px;
  right: 0;
  bottom: 0;
`;

type Props = {
  theory: TTheory;
  value: string;
  courseLayout?: boolean;
};

const TheoryToggler: React.FC<Props> = ({ theory, value, courseLayout }) => {
  const dispatch = useDispatch();

  if (!theory) return null;

  const handleToggle = (lang: string) => {
    dispatch(setTheoryLang({ forLang: theory.lang, pickedLang: lang }));
  };

  const showOriginal = theory.theoryIsChecked || theory.canEdit;

  if (!showOriginal && !theory.theoryEn) return <div style={{ width: 30 }} />;

  return (
    <ButtonGroup $courseLayout={!!courseLayout}>
      {showOriginal && theory.langNative !== theory.lang && (
        <Btn onClick={() => handleToggle(theory.lang)} selected={theory.lang === value} $courseLayout={!!courseLayout}>
          {flag(theory.lang)}
          {!theory.theoryIsChecked && <Hidden />}
        </Btn>
      )}
      {theory.theoryEn && (
        <Btn onClick={() => handleToggle("en")} selected={value === "en"} $courseLayout={!!courseLayout}>
          {flag("en")}
        </Btn>
      )}
      <Btn
        onClick={() => handleToggle(theory.langNative)}
        selected={theory.langNative === value}
        $courseLayout={!!courseLayout}
      >
        {flag(theory.langNative)}
      </Btn>
    </ButtonGroup>
  );
};

export default TheoryToggler;

import React from "react";
import styled from "styled-components/macro";
import { useNavigate } from "react-router";
import { Trans, useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { useLibraryUrl } from "../../root/RootRouter";
import { LEARN } from "../../root/main/MainRouter";
import { useViewerDecks } from "../../queries/viewerQuery";
import Rate, { SourceForRate } from "../other/Rate";
import RecommendedSources from "components/RecommendedSources/RecommendedSources";
import { MANY_UNKNOWN } from "root/main/main/Main";
import { LibTitle } from "root/library/library/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBooks } from "@duocards/pro-regular-svg-icons";
import { MAIN } from "../../root/RootRouter.config";

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% + 40px);
  margin: -10px 0;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding: 0 25px;
  position: relative;
  z-index: 550;
  background: ${({ theme }) => theme.duo.color.lightGrey};
`;
const Row = styled.div`
  margin: 20px 0;
`;
const SLibraryIcon = styled(FontAwesomeIcon)`
  font-size: 17px;
  padding-right: 3px;
`;

type Props = {
  source: SourceForRate;
};

const PickerNoCards: React.FC<Props> = ({ source }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { deck } = useViewerDecks();
  const libraryUrl = useLibraryUrl();

  return (
    <Wrap>
      {!source.mine && (
        <Row>
          <LibTitle $justText>
            <Trans>How did you like these cards?</Trans>
          </LibTitle>
          <Rate source={source} />
        </Row>
      )}

      {deck && deck.stats.unknown <= MANY_UNKNOWN && (
        <Row>
          <RecommendedSources lang={deck.front} />
        </Row>
      )}

      <Row>
        {Number(deck?.stats.unknown) < 10 ? (
          <Button color="primary" onClick={() => navigate(libraryUrl)}>
            <SLibraryIcon icon={faBooks} /> {t("go to Library")}
          </Button>
        ) : (
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              navigate(MAIN.url(), { replace: true });
              navigate(LEARN.url());
            }}
          >
            {t("Start learning")}
          </Button>
        )}
      </Row>
    </Wrap>
  );
};

export default PickerNoCards;

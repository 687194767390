import Flames from "components/fire/Flames";
import React from "react";
import styled from "styled-components/macro";
import { useViewerQuery } from "../../../../../queries/viewerQuery";
import { STREAK_FREEZE_XPS } from "../../../../../sharedJs__generated/constants";
import IceCube from "../../../../../components/fire/IceCube";
import { QuestBubble } from "../misc/QuestBubble";

const SFlames = styled(Flames)`
  display: inline-block;
  margin: 0 7px -7px 6px;
`;

export const StreakFreezeBubble: React.FC = () => {
  const { viewer } = useViewerQuery();

  return (
    <QuestBubble>
      <span>
        +<SFlames strength={4} xps={STREAK_FREEZE_XPS - (viewer?.streakFreezeXps ?? 0)} /> = +
        <IceCube coldness={2} number={1} />
      </span>
    </QuestBubble>
  );
};

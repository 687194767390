import React from "react";
import { Trans } from "react-i18next";
import { useGetState, useSetState } from "components/ReduxProvider";
import { useViewerQuery } from "queries/viewerQuery";
import { useSeeHint } from "components/hint/Hint";
import QuestReward from "../QuestReward";
import { dispatchEvent, EVENT } from "tools/events";
import OwnWordAddButton from "./OwnWordAddButton";
import { QuestMessage } from "../misc/QuestMessage";
import { PICKED_FEW_CARDS } from "components/fire/xpHooks";

export const OWNWORDQUEST = "ownWord";

const OwnWord: React.FC = () => {
  const ownWordAdded = useGetState("ownWordAdded");
  const seeHint = useSeeHint();
  const setShowQuestsBackdrop = useSetState("showQuestsBackdrop");

  React.useEffect(() => {
    setShowQuestsBackdrop(true);
    dispatchEvent(EVENT.updateMain);
  }, [setShowQuestsBackdrop, ownWordAdded]);

  return (
    <QuestMessage>
      <p
        style={{
          margin: "5px 0",
          fontSize: "85%",
          lineHeight: "1.5em",
          textDecoration: ownWordAdded ? "line-through" : "none"
        }}
      >
        <Trans>One more important feature to discover:</Trans>
      </p>
      <p style={{ margin: "5px 0", textDecoration: ownWordAdded ? "line-through" : "none" }}>
        <Trans>Create at least one word on your own.</Trans>
      </p>

      {!ownWordAdded && <OwnWordAddButton />}

      <QuestReward
        rewardedXps={10}
        claim={ownWordAdded}
        onDone={() => {
          seeHint(OWNWORDQUEST);
          setTimeout(() => {
            dispatchEvent(EVENT.updateMain);
          }, 1000);
        }}
      />
    </QuestMessage>
  );
};

export const useCreatingNewCard = () => {
  const { hintWasSeen } = useViewerQuery();
  const ownWordAdded = useGetState("ownWordAdded");

  return hintWasSeen(PICKED_FEW_CARDS) && !hintWasSeen(OWNWORDQUEST) && !ownWordAdded;
};

export default OwnWord;

import { isApp, isBrowser, isIos } from "tools/device";
import { useViewerQuery } from "queries/viewerQuery";
import { useSaleExpiration } from "./subscriptionHooks";
import { useGetState } from "components/ReduxProvider";

export type StoreId =
  | "year"
  | "3months"
  | "3months1"
  | "yearly" // -60%
  | "yearlyly"
  | "yearextra"
  | "family"
  | "familyextra" // year price (christmas-sale)
  | "onemonth1"
  | "onemonth"
  | "year@p1y" // android offers
  | "year@p1y@yeartrial"
  | "year@familyplan"
  | "year@familyplan@trial"
  | "year@onemonthplan"
  | "year@yearplan1" // -60%
  | "year@familyplan1" // year@p1y price (christmas-sale)
  | "year@onemonthplan1"
  | "year@threemonthsplan"
  | "year@threemonthsplan1";

enum Platform {
  android = "android",
  ios = "ios"
}

export const SUB: { id: StoreId; platform?: Platform; masterId?: "year" }[] = [
  { id: "3months" },
  { id: "3months1", platform: Platform.ios },
  { id: "year" },
  { id: "yearly" },
  { id: "yearlyly" },
  { id: "yearextra" },
  { id: "family" },
  { id: "familyextra", platform: Platform.ios },
  { id: "onemonth1", platform: Platform.ios },
  { id: "onemonth" },
  { id: "year@p1y", platform: Platform.android, masterId: "year" },
  { id: "year@p1y@yeartrial", platform: Platform.android, masterId: "year" },
  { id: "year@familyplan", platform: Platform.android, masterId: "year" },
  { id: "year@familyplan@trial", platform: Platform.android, masterId: "year" },
  { id: "year@onemonthplan", platform: Platform.android, masterId: "year" },
  { id: "year@yearplan1", platform: Platform.android, masterId: "year" },
  { id: "year@familyplan1", platform: Platform.android, masterId: "year" },
  { id: "year@onemonthplan1", platform: Platform.android, masterId: "year" },
  { id: "year@threemonthsplan", platform: Platform.android, masterId: "year" },
  { id: "year@threemonthsplan1", platform: Platform.android, masterId: "year" }
];

export const subsOnPlatform = () => {
  const platform = isIos() ? Platform.ios : Platform.android;
  return (
    // filter out android offers
    SUB.filter((s) => !s.masterId)
      //
      .filter((s) => s.platform === platform || !s.platform)
  );
};

/**
 * NOTE: subscriptionModel and SubscriptionRegister rely on having %family% keyword in family storeId
 */

export const useStoreIdsWithoutSale: () => {
  [key in "storeIdYear" | "storeIdFamily" | "storeIdMonth"]: StoreId;
} = () => {
  const subscriptions = useGetState("subscriptions");
  const { subscriptionFlags } = useViewerQuery();

  const androidStoreIdYear =
    !subscriptionFlags.hadSubscription && (isBrowser() || subscriptions?.["year@p1y@yeartrial"])
      ? "year@p1y@yeartrial"
      : "year@p1y";
  const androidStoreIdFamily =
    !subscriptionFlags.hadSubscription && (isBrowser() || subscriptions?.["year@familyplan@trial"])
      ? "year@familyplan@trial"
      : "year@familyplan";

  return {
    storeIdYear: isIos() && isApp() ? "year" : androidStoreIdYear,
    storeIdFamily: isIos() && isApp() ? "family" : androidStoreIdFamily,
    storeIdMonth: isIos() && isApp() ? "3months" : "year@threemonthsplan"
  };
};

export const useStoreIds: () => { [key in "storeIdYear" | "storeIdFamily" | "storeIdMonth"]: StoreId } = () => {
  const { storeIdMonth, storeIdYear: idYear, storeIdFamily: idFamily } = useStoreIdsWithoutSale();
  const { storeIdWithSale, familyIdWithSale } = useSaleExpiration();

  const storeIdYear = storeIdWithSale || idYear;
  const storeIdFamily = familyIdWithSale || idFamily;

  return { storeIdMonth, storeIdYear, storeIdFamily };
};

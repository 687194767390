import React from "react";
import { useTranslation } from "react-i18next";
import { LibTitle } from "root/library/library/styled";
import { useViewerDecks } from "queries/viewerQuery";
import LibraryLine from "../../root/library/library/LibraryLine";
import { useRecommendedSourcesPaginated } from "./recommendedSourcesPaginatedQuery";
import { Button } from "@mui/material";
import { useLibraryUrl } from "../../root/RootRouter";
import { useNavigate } from "react-router";
import styled from "styled-components/macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBooks } from "@duocards/pro-regular-svg-icons";

type Props = {
  lang: string;
  sourceId?: string;
};

const Wrap = styled.div`
  padding: 0 0 40px;
  text-align: center;
`;

const SButton = styled(Button)`
  padding: 30px 0;
`;

const SLibraryIcon = styled(FontAwesomeIcon)`
  font-size: 17px;
  padding-right: 3px;
`;

export const recommendedSourcesOnPage = 5;

const RecommendedSourcesPaginated: React.FC<Props> = ({ lang, sourceId }) => {
  const { decks } = useViewerDecks();
  const navigate = useNavigate();
  const libraryUrl = useLibraryUrl();

  const deck = decks?.find((d) => d.front === lang);
  const recommendedSources = useRecommendedSourcesPaginated(deck, sourceId);
  const { t } = useTranslation();

  return (
    <>
      {deck && (
        <Wrap>
          <LibTitle>{t("Where to next?")}</LibTitle>
          <LibraryLine sources={recommendedSources} sourcesOnPageOverride={recommendedSourcesOnPage} />
          <SButton color="primary" onClick={() => navigate(libraryUrl)}>
            <SLibraryIcon icon={faBooks} /> {t("go to Library")}
          </SButton>
        </Wrap>
      )}
    </>
  );
};

export default RecommendedSourcesPaginated;

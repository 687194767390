import React from "react";
import { CARD } from "../../root/main/MainRouter";
import { useFirstMountState } from "react-use";
import { useLocation, useNavigate } from "react-router";
import { VIDEOFORM } from "root/library/library/libraryUrls";

const Sharing: React.FC<{}> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isFirstMount = useFirstMountState();

  const redirectTo = (text?: string) => {
    if (!text) return;

    if (text.includes("https://youtu")) {
      navigate(VIDEOFORM.url({ term: text }), { replace: true });
      return;
    }
    const textFix = text.replace(/"(.*)"\s*http.*/g, "$1");
    const replaceUrl = CARD.url(textFix);
    if (location.pathname !== replaceUrl) {
      navigate(replaceUrl, { replace: true });
      return;
    }
  };

  // ANDROID:
  const handleAndroidIntent = (intent) => {
    if (intent.clipItems && intent.clipItems.length === 1) {
      redirectTo(intent.clipItems[0].text);
    }
  };
  const intentShim = (window.plugins || {}).intentShim;
  if (intentShim) {
    if (isFirstMount) {
      intentShim.getIntent(handleAndroidIntent, () => {});
    }
    intentShim.onIntent(handleAndroidIntent);
  }

  /// IOS:
  if (window.cordova?.openwith && isFirstMount) {
    window.cordova.openwith.init(
      () => console.info("openwith init"),
      (err) => console.info("openwith init failed: " + err)
    );
    window.cordova.openwith.addHandler((intent) => {
      redirectTo(intent?.items[0]?.text);
    });
  }

  return null;
};

export default Sharing;

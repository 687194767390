import React from "react";
import { useQuery } from "relay-hooks";
import { graphql } from "babel-plugin-relay/macro";
import { viewerQuery, viewerQuery$data } from "./__generated__/viewerQuery.graphql";
import { viewerQuery_decks$data } from "./__generated__/viewerQuery_decks.graphql";
import { useGetState } from "../components/ReduxProvider";
import { getSubscriptionFlags, SubscriptionFlags } from "queries/users/userSubscriptionModel";
import { FlagFunc, hasUserFlag } from "./users/userFlagsModel";
import { isNoPaymentTimezone } from "components/other/VpnAffiliate";

export const decksFrg = graphql`
  fragment viewerQuery_decks on User {
    decks {
      id
      name
      front
      back
      stats {
        total
        known
        unknown
        completed
      }
      voices {
        id
      }
    }
  }
`;
export const friendsFrg = graphql`
  fragment viewerQuery_friends on User {
    friends {
      edges {
        friend {
          id
          name
          picture
          xps {
            total
            today
            currentStreak
          }
        }
        hasOnlyFewFriends
        shouldBeConfirmed
      }
    }
  }
`;
export const xpsFrg = graphql`
  fragment viewerQuery_xps on User {
    xps {
      today
      todayExtra
      total
      currentStreak
      yesterdayLostStreak
      days {
        xp
        added
        reviewed
        when
        streaked
        freeze
      }
    }
  }
`;
const query = graphql`
  query viewerQuery {
    viewer {
      id
      name
      bio
      picture
      email
      emailVerified
      admin
      seenHints {
        name
        when
      }
      timezone
      timezonePrices {
        basePlanId
        amount
        currency
        amountOriginal
        currencyOriginal
      }
      appLang
      fcmId
      coachLevel
      deviceId
      deviceOs
      installReferrer
      affiliate
      gId
      appleId
      streakTime
      streakFreeze
      streakFreezeXps
      verifiedBeforeDays
      createdBeforeDays
      createdBeforeSeconds
      showSubsKickDialog
      abtest
      aiMessagesUsedThisWeek
      inviter {
        id
        name
        deviceId
      }
      subscriptions {
        transactionId
        timeToExpire
        platform
        storeId
        family
        cancelled
        famembers {
          id
          name
          picture
          xps {
            total
            today
            currentStreak
          }
        }
      }
      flags {
        name
        value
        body
        updated
      }
      defaultLanguages {
        target
        native
      }
      ...viewerQuery_friends
      ...viewerQuery_decks
      ...viewerQuery_xps
    }
  }
`;

export type Viewer = viewerQuery$data["viewer"] & { subscription: viewerQuery$data["viewer"]["subscriptions"][0] };

export const useViewerQuery = () => {
  const token = useGetState("token");

  const { data: props, retry, isLoading } = useQuery<viewerQuery>(query, {}, { skip: !token });
  const viewerRelay = props?.viewer;
  const viewerRedux = useGetState("viewer");
  const viewerRaw = viewerRelay || viewerRedux;
  const viewer: Viewer | undefined = React.useMemo(
    () =>
      viewerRaw
        ? {
            ...viewerRaw,
            subscription: viewerRaw.subscriptions?.filter((s) => s.timeToExpire > 0)?.[0] // first not expired subscription
          }
        : undefined,
    [viewerRaw]
  );

  const seenHintsNames = React.useMemo(() => viewer?.seenHints?.map((h) => h.name) ?? [], [viewer?.seenHints]);

  const hintWasSeen = React.useCallback(
    (name) => (!viewer ? true : seenHintsNames.includes(name)),
    [seenHintsNames, viewer]
  );

  const whenWasHintSeen = React.useCallback(
    (name): number => {
      if (!viewer) return 1; /*no user on public endpoints seen all hints by default*/

      const hint = viewer.seenHints.find((hint) => hint.name === name);
      return hint ? hint.when : Infinity /*not seen yet*/;
    },
    [viewer]
  );

  const subscriptionFlags: SubscriptionFlags = React.useMemo(() => {
    return getSubscriptionFlags(viewer?.subscription, viewer?.subscriptions, viewer?.name);
  }, [viewer]);

  const getFlag: FlagFunc = React.useCallback(
    (flagName) => {
      const flag = hasUserFlag(viewer?.flags, flagName);
      if (!flag) return;
      if (!flag.body) return flag;
      return { ...flag, body: JSON.parse(flag.body) };
    },
    [viewer?.flags]
  );

  const isSubscriber = !!viewer?.subscription;
  const isGuest = !viewer?.email;

  return {
    viewer,
    viewerRelay, // can be null if isLoading or offline
    whenWasHintSeen,
    hintWasSeen,
    getFlag,
    subscriptionFlags,
    isSubscriber,
    isGuest,
    retry,
    isLoading,
    noPayments: () => isNoPaymentTimezone(viewer?.timezone)
  };
};

//
// --- VIEWER FRAGMENTS ---
// fetched in QueriesToRedux.tsx for better performance
//

export type Deck = viewerQuery_decks$data["decks"][0];

export const useViewerDecks = () => {
  const deckId = useGetState("deckId");
  const decks = useGetState("decks");
  const deck = decks?.find((d) => d.id === deckId);
  return { decks, deck };
};

export const useViewerXps = () => ({ xps: useGetState("xps") });

export const useViewerFriends = () => ({ friends: useGetState("friends") });

import "react-app-polyfill/stable";
import "core-js/features/array/find";
import "core-js/features/array/includes";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { isAndroid, isBot, isBrowser, isIos, isUnsubscribe, isWebPpcCampaign } from "./tools/device";
import { initSentry } from "sentry/sentryInitializer";
import { logEvent } from "tools/analyticEvents";
import { TRYSHARING } from "./root/RootRouter.config";
import { initMetaPixel } from "hooks/initMetaPixel";
import { FEEDBACK } from "./root/profile/ProfileRouter.config";

initSentry();

function initAppsFlyer() {
  window.plugins?.appsFlyer?.initSdk(
    {
      devKey: "d3zUMtF8P68zARaRuX9UCK",
      appId: "1508159917",
      waitForATTUserAuthorization: 10,
      isDebug: false,
      collectIMEI: false,
      onInstallConversionDataListener: false // if true, then init-success-function result-variable holds the AppsFlyer attribution data
    },
    () => {
      logEvent("launch_app"); // this calls: window.plugins?.appsFlyer?.logEvent(eventName, {}, null, null);
    },
    (err) => {
      console.error("AppsFlyer init error:", err);
    }
  );
}

function initSingular() {
  const singularSdk = window.cordova?.plugins?.SingularCordovaSdk;
  if (!!singularSdk) {
    var singularConfig = new singularSdk.SingularConfig("duocards_57c6bb58", "04ba148f258e8755f5ec4ec71f6799dd");
    singularConfig.withSkAdNetworkEnabled(true); // SAS should not need this, but if not working, then uncomment
    singularConfig.withWaitForTrackingAuthorizationWithTimeoutInterval(120); // 2mins, because of ATT popup
    // singularConfig.withLoggingEnabled();
    // singularConfig.withLogLevel(3);

    singularSdk.init(singularConfig);
  }
}

const startApp = () => {
  // redirect FE to https (can't be easily done on heroku itself)
  if (window.location.hostname === "app.duocards.com" && window.location.protocol !== "https:") {
    window.location.href = "https:" + window.location.href.substring(window.location.protocol.length);
    return;
  }

  const container = document.getElementById("root");
  const root = createRoot(container!);
  root.render(<App />);
  initAppsFlyer();
  initSingular();
};

// @ts-ignore
if (isBrowser()) {
  let doNotStart = false;
  initMetaPixel();
  if (
    !isBot() &&
    window.location.href.indexOf(TRYSHARING.url(null)) === -1 &&
    window.location.href.indexOf(FEEDBACK.url()) === -1
  ) {
    const path = window.location.href.replace(window.location.origin, "");
    if (!isWebPpcCampaign() && !isUnsubscribe()) {
      if (isAndroid()) {
        // ***** Redirect to app on Android *****
        // WITH APP INSTALLED
        // Instagram / Messenger - You are leaving our app. The website .... Go back/ continue
        // Chrome - Top snackbar: Continu to Duocards. Continue
        // NO APP INSTALLED
        // Instagram / Messenger - small snackbar on bottom: Page cant be loaded
        // Chrome - nic
        window.location.href = `intent:/${path}#Intent;scheme=com.duocards.app;package=com.duocards.app;end`;
      }
      if (isIos()) {
        // ***** Redirect to app on iOS *****
        // WITH APP INSTALLED
        // Instagram - Automatic redirect
        // Chrome - Alert: Site is trying to open another application. Allow/dont allow.
        // Safari - Alert: Open this page in "DuoCards". Cancel/open
        // NO APP INSTALLED
        // Instagram - nic
        // Chrome - Alert: Site is trying to open another application. Allow/dont allow. Pri allow - Something went wrong. Got it
        // Safari - Safari cannot open the page because address is invalid. OK
        window.location.href = "com.duocards.app:/" + (path === "/" ? "/main" : path);
      }
    }
    if (window.location.pathname.startsWith("/i/") && !JSON.parse(localStorage.getItem("reduxState") || "{}").token) {
      doNotStart = true;
      setTimeout(() => (window.location.href = "https://get.duocards.com" + path), 100);
    }
  }
  !doNotStart && startApp();
} else {
  document.addEventListener(
    "deviceready",
    async () => {
      startApp();
      window.admob && (await window.admob.start());
    },
    false
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React from "react";
import styled from "styled-components";
import FeatureItem from "./FeatureItem";

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 37px;
  margin-left: 40px;
  margin-right: 40px;
  height: 100%;
  overflow-y: auto;
  padding-top: 60px;
  padding-bottom: 60px;
`;

const googleStorageUrl = "https://imgs.duocards.com/onboarding/3m/";

const achievements: {
  iconPath: string;
  title: string;
  description: string;
}[] = [
  {
    iconPath: `${googleStorageUrl}speak.svg`,
    title: "You will gain more confidence",
    description: "Stress-free practice in speaking, reading, and listening"
  },
  {
    iconPath: `${googleStorageUrl}words.svg`,
    title: "Expanded vocabulary",
    description: "Common words and practical phrases"
  },
  {
    iconPath: `${googleStorageUrl}learn.svg`,
    title: "Develop a new learning habbit",
    description: "Smart reminders, fun challenges, and more"
  }
];

const AchievementPreview: React.FC = () => {
  return (
    <Wrap>
      {achievements.map((achievement, index) => (
        <FeatureItem
          key={index}
          iconPath={achievement.iconPath}
          title={achievement.title}
          description={achievement.description}
        />
      ))}
    </Wrap>
  );
};

export default AchievementPreview;

import React from "react";
import styled from "styled-components/macro";
import { HeaderLayout } from "components/header/Header";
import { Trans, useTranslation } from "react-i18next";
import { Button, Stack, Switch, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGraduationCap, faArrowUpRightFromSquare } from "@duocards/pro-regular-svg-icons";
import { openInExternalBrowser } from "tools/device";
import MammothWithBubble, { MammothVariant } from "root/tutorial/onboarding/MammothWithBubble";
import useUserProfile from "hooks/useUserProfile";
import FeatureItem from "root/tutorial/onboarding/FeatureItem";

const SHeaderLayout = styled(HeaderLayout)`
  padding: 0 40px;
`;

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-top: 20px;
`;

const ToggleWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const Message = styled(Typography)`
  color: ${({ theme }) => theme.duo.palette.greyDark};
  margin-bottom: 30px;
  opacity: ${({ $isActive }: { $isActive: boolean }) => ($isActive ? 1 : 0)};
  transition: opacity 0.3s ease;
`;

const googleStorageUrl = "https://imgs.duocards.com/onboarding/3m/";

export const teacherFeatures = [
  {
    iconPath: `${googleStorageUrl}speak.svg`,
    title: "Create flashcard sets and share them with your students."
  },
  {
    iconPath: `${googleStorageUrl}words.svg`,
    title: "Access our extensive library of materials for your students."
  }
];

const InfoWrap = styled.div<{ $isActive: boolean }>`
  opacity: ${(props) => (props.$isActive ? 1 : 0.8)};
  filter: ${(props) => (props.$isActive ? "none" : "saturate(0)")};
  pointer-events: ${(props) => (props.$isActive ? "auto" : "none")};
  gap: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Teachers: React.FC = () => {
  const { t } = useTranslation();
  const { saveUserProfileData, getUserProfileData } = useUserProfile();
  const isTeacher = getUserProfileData().role === "teacher";

  return (
    <SHeaderLayout
      title={
        <Stack alignItems="center" direction="row" spacing={1}>
          <FontAwesomeIcon icon={faGraduationCap} /> <span>{t("For teachers")}</span>
        </Stack>
      }
      narrow
    >
      <Wrap>
        <div>
          <ToggleWrap>
            <Typography variant="body1">{t("I am a teacher")}</Typography>
            <Switch
              checked={isTeacher}
              onChange={(e) => saveUserProfileData({ role: e.target.checked ? "teacher" : "student" })}
              color="primary"
            />
          </ToggleWrap>
          <Message variant="body2" $isActive={isTeacher}>
            <Trans>Receive our educator newsletter with teaching resources and updates</Trans>
          </Message>
        </div>

        <InfoWrap $isActive={isTeacher}>
          <MammothWithBubble variant={MammothVariant.Small}>
            <Trans>As a teacher, you can:</Trans>
          </MammothWithBubble>

          {teacherFeatures.map((feature, index) => (
            <FeatureItem key={index} iconPath={feature.iconPath} title={feature.title} />
          ))}

          <Button
            color="primary"
            fullWidth
            onClick={() => openInExternalBrowser("https://duocards.com/en/duocards-for-teachers/")}
            endIcon={<FontAwesomeIcon icon={faArrowUpRightFromSquare} width={16} height={16} />}
          >
            <Trans>More Info</Trans>
          </Button>
        </InfoWrap>
      </Wrap>
    </SHeaderLayout>
  );
};

export default Teachers;

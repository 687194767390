/**
 * @generated SignedSource<<35c35bb26ae3e7d5606767160b710ed1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Difficulty = "beginner" | "easy" | "hard" | "moderate" | "%future added value";
export type Kind = "article" | "set" | "video" | "%future added value";
export type sourceQuery$variables = {
  backLang?: string | null;
  deckId?: string | null;
  uriOrId: string;
};
export type sourceQuery$data = {
  readonly sourceByUriOrId: {
    readonly authorId: string;
    readonly authorName: string | null;
    readonly captions: string | null;
    readonly copySource: boolean | null;
    readonly course: boolean;
    readonly difficulty: Difficulty;
    readonly duoLang: boolean;
    readonly id: string;
    readonly image: {
      readonly authorApiName: string;
      readonly authorName: string;
      readonly url: string;
    } | null;
    readonly imageId: string | null;
    readonly isCopied: boolean;
    readonly kind: Kind;
    readonly lang: string;
    readonly mine: boolean;
    readonly name: string;
    readonly private: boolean;
    readonly rating: {
      readonly continueFrom: number | null;
      readonly finished: number | null;
      readonly rating: number | null;
    } | null;
    readonly sCards: ReadonlyArray<{
      readonly back: {
        readonly sBackId: string | null;
        readonly translated: boolean | null;
        readonly value: string;
      } | null;
      readonly front: string;
      readonly hint: string | null;
      readonly id: string;
      readonly isInMyDeck: boolean | null;
      readonly pronunciation: string | null;
      readonly sourceId: string;
      readonly svg: {
        readonly flatId: string;
        readonly url: string;
      } | null;
      readonly theory: {
        readonly canEdit: boolean;
        readonly lang: string;
        readonly langNative: string;
        readonly sCardId: string;
        readonly theory: string;
        readonly theoryEn: string | null;
        readonly theoryIsChecked: boolean;
        readonly theoryNative: string;
        readonly theoryNativeIsChecked: boolean;
      } | null;
    }>;
    readonly seo: string | null;
    readonly source: string | null;
    readonly text: string | null;
    readonly uri: string;
    readonly videoDuration: number | null;
    readonly videoLang: string | null;
    readonly videoStart: number | null;
  };
};
export type sourceQuery = {
  response: sourceQuery$data;
  variables: sourceQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "backLang"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "deckId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "uriOrId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "uriOrId",
    "variableName": "uriOrId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uri",
  "storageKey": null
},
v6 = [
  {
    "kind": "Variable",
    "name": "langNative",
    "variableName": "backLang"
  }
],
v7 = {
  "alias": null,
  "args": (v6/*: any*/),
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": (v6/*: any*/),
  "kind": "ScalarField",
  "name": "seo",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "duoLang",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "course",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "private",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lang",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "source",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mine",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "copySource",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isCopied",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "authorId",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "authorName",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "difficulty",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "videoStart",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "videoLang",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "videoDuration",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "captions",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "imageId",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "authorApiName",
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rating",
  "storageKey": null
},
v30 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "continueFrom",
  "storageKey": null
},
v31 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "finished",
  "storageKey": null
},
v32 = [
  {
    "kind": "Variable",
    "name": "backLang",
    "variableName": "backLang"
  }
],
v33 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "front",
  "storageKey": null
},
v34 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hint",
  "storageKey": null
},
v35 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pronunciation",
  "storageKey": null
},
v36 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sourceId",
  "storageKey": null
},
v37 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sBackId",
  "storageKey": null
},
v38 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v39 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "translated",
  "storageKey": null
},
v40 = {
  "alias": null,
  "args": (v6/*: any*/),
  "concreteType": "SCardTheory",
  "kind": "LinkedField",
  "name": "theory",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sCardId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "theory",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "theoryIsChecked",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "theoryNative",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "theoryNativeIsChecked",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "theoryEn",
      "storageKey": null
    },
    (v14/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "langNative",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canEdit",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v41 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "flatId",
  "storageKey": null
},
v42 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "deckId",
      "variableName": "deckId"
    }
  ],
  "kind": "ScalarField",
  "name": "isInMyDeck",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "sourceQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Source",
        "kind": "LinkedField",
        "name": "sourceByUriOrId",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/),
          (v18/*: any*/),
          (v19/*: any*/),
          (v20/*: any*/),
          (v21/*: any*/),
          (v22/*: any*/),
          (v23/*: any*/),
          (v24/*: any*/),
          (v25/*: any*/),
          (v26/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Image",
            "kind": "LinkedField",
            "name": "image",
            "plural": false,
            "selections": [
              (v27/*: any*/),
              (v20/*: any*/),
              (v28/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Rating",
            "kind": "LinkedField",
            "name": "rating",
            "plural": false,
            "selections": [
              (v29/*: any*/),
              (v30/*: any*/),
              (v31/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v32/*: any*/),
            "concreteType": "SCard",
            "kind": "LinkedField",
            "name": "sCards",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v33/*: any*/),
              (v34/*: any*/),
              (v35/*: any*/),
              (v36/*: any*/),
              {
                "alias": null,
                "args": (v6/*: any*/),
                "concreteType": "SCardBack",
                "kind": "LinkedField",
                "name": "back",
                "plural": false,
                "selections": [
                  (v37/*: any*/),
                  (v38/*: any*/),
                  (v39/*: any*/)
                ],
                "storageKey": null
              },
              (v40/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Upload",
                "kind": "LinkedField",
                "name": "svg",
                "plural": false,
                "selections": [
                  (v41/*: any*/),
                  (v27/*: any*/)
                ],
                "storageKey": null
              },
              (v42/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "sourceQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Source",
        "kind": "LinkedField",
        "name": "sourceByUriOrId",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/),
          (v18/*: any*/),
          (v19/*: any*/),
          (v20/*: any*/),
          (v21/*: any*/),
          (v22/*: any*/),
          (v23/*: any*/),
          (v24/*: any*/),
          (v25/*: any*/),
          (v26/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Image",
            "kind": "LinkedField",
            "name": "image",
            "plural": false,
            "selections": [
              (v27/*: any*/),
              (v20/*: any*/),
              (v28/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Rating",
            "kind": "LinkedField",
            "name": "rating",
            "plural": false,
            "selections": [
              (v29/*: any*/),
              (v30/*: any*/),
              (v31/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v32/*: any*/),
            "concreteType": "SCard",
            "kind": "LinkedField",
            "name": "sCards",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v33/*: any*/),
              (v34/*: any*/),
              (v35/*: any*/),
              (v36/*: any*/),
              {
                "alias": null,
                "args": (v6/*: any*/),
                "concreteType": "SCardBack",
                "kind": "LinkedField",
                "name": "back",
                "plural": false,
                "selections": [
                  (v37/*: any*/),
                  (v38/*: any*/),
                  (v39/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              (v40/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Upload",
                "kind": "LinkedField",
                "name": "svg",
                "plural": false,
                "selections": [
                  (v41/*: any*/),
                  (v27/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              (v42/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bdafeb13eaf636ba46b5ce1c9b8306d1",
    "id": null,
    "metadata": {},
    "name": "sourceQuery",
    "operationKind": "query",
    "text": "query sourceQuery(\n  $uriOrId: String!\n  $backLang: String\n  $deckId: ID\n) {\n  sourceByUriOrId(uriOrId: $uriOrId) {\n    id\n    uri\n    name(langNative: $backLang)\n    seo(langNative: $backLang)\n    text\n    duoLang\n    kind\n    course\n    private\n    lang\n    source\n    mine\n    copySource\n    isCopied\n    authorId\n    authorName\n    difficulty\n    videoStart\n    videoLang\n    videoDuration\n    captions\n    imageId\n    image {\n      url\n      authorName\n      authorApiName\n      id\n    }\n    rating {\n      rating\n      continueFrom\n      finished\n      id\n    }\n    sCards(backLang: $backLang) {\n      id\n      front\n      hint\n      pronunciation\n      sourceId\n      back(langNative: $backLang) {\n        sBackId\n        value\n        translated\n        id\n      }\n      theory(langNative: $backLang) {\n        sCardId\n        theory\n        theoryIsChecked\n        theoryNative\n        theoryNativeIsChecked\n        theoryEn\n        lang\n        langNative\n        canEdit\n      }\n      svg {\n        flatId\n        url\n        id\n      }\n      isInMyDeck(deckId: $deckId)\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "81e49c1f98fabf1ee8159db5f18ad310";

export default node;

import React, { useImperativeHandle } from "react";
import styled from "styled-components/macro";
import { useEffectOnce } from "react-use";

const Wrap = styled.div<{ $atBottom: boolean }>`
  position: sticky;
  bottom: 0;
  padding: 20px;
  background: white;
  z-index: 5;
  border-top: 1px solid white;

  ${(p) =>
    !p.$atBottom &&
    `
    border-top: 1px solid ${p.theme.duo.color.grey};
    box-shadow: ${p.theme.duo.boxShadow.normal};
  `}
`;

type Props = {
  children: React.ReactNode;
  className?: string;
  ref?: React.Ref<HTMLDivElement>;
};

const StickyBottom = ({ children, className, ref }: Props) => {
  const internalRef = React.useRef<HTMLDivElement>(null);
  // Forward the ref to the internal element if provided
  useImperativeHandle(ref, () => internalRef.current as HTMLDivElement);
  const [atBottom, setAtBottom] = React.useState(true);

  function getScrollParent(node) {
    if (node == null) {
      return null;
    }

    if (node.scrollHeight > node.clientHeight) {
      return node;
    } else {
      return getScrollParent(node.parentNode);
    }
  }

  const checkScroll = () => {
    const scrollParent = getScrollParent(internalRef.current);
    if (scrollParent && scrollParent.scrollTop + scrollParent.offsetHeight >= scrollParent.scrollHeight) {
      setAtBottom(true);
    } else {
      scrollParent && setAtBottom(false);
    }
  };

  useEffectOnce(() => {
    const iId = setInterval(checkScroll, 300);
    return () => clearInterval(iId);
  });

  return (
    <Wrap $atBottom={atBottom} className={className} ref={internalRef}>
      {children}
    </Wrap>
  );
};

export default StickyBottom;

import { langs } from "../sharedJs__generated/langsList";
import { loginToExtensionHash } from "components/other/ChromeExtensionLogin";
import { REFERRER_FROM_URL } from "../components/initializers/ReferrerCounter.model";

const width = window.innerWidth;
const height = window.innerHeight;
// iphones: https://iosref.com/res
const isNewIphone = () =>
  (width === 414 && height === 896) ||
  (width === 375 && height === 812) ||
  (width === 390 && height === 844) ||
  (width === 428 && height === 926) ||
  (width === 440 && height === 956); // iPhone 16 Pro Max
const isIphone14pro = () => (width === 393 && height === 852) || (width === 430 && height === 932);

// https://stackoverflow.com/questions/9038625/detect-if-device-is-ios
export const isIos = () =>
  (/iPad|iPhone|iPod/.test(navigator.userAgent) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)) &&
  !(window as any).MSStream;

export const isAndroid = () => /android/i.test(navigator.userAgent);

export const isBrowser = () => !window.cordova;

export const isApp = () => !!window.cordova;

export const isDesktop = () => !isIos() && !isAndroid();

export const isPhone = () => isIos() || isAndroid();

export const isPhonesBrowser = () => isBrowser() && isPhone();

export const isBot = () => /bot|crawl|spider/i.test(navigator.userAgent);

export const canShowOAuth = () => !window.cordova || (window as any).device;

export const isChromeExtension = () => window.location.hash === "#chromeextension";

export const isChromeExtensionLogin = () => window.location.hash === loginToExtensionHash;

// our utm docs: https://docs.google.com/spreadsheets/d/15VvM8PHG1Haj2VHWUzyd3D4jCXH2iDg5TXy_b0GXGvQ/edit?gid=0#gid=0
export const isWebPpcCampaign = () => window.location.search.includes("utm_medium=ppc");

export const isUnsubscribe = () => window.location.search.includes("unsubscribe");

export const devicePaddings = () => {
  if (!isIos()) return { top: 0, bottom: 0 };
  if (isIphone14pro()) return { top: 44, bottom: 20 };
  if (isNewIphone()) return { top: 30, bottom: 18 };
  return { top: 14, bottom: 0 };
};
const { top, bottom } = devicePaddings();
// shortcut to get paddings without calling a function
export const topPadding = top;
export const bottomPadding = bottom;

// NOT USED ATM
export const isNewVersion = () => isBrowser() || window.appversion >= 10701;

export const isDevelopmentMode = (): boolean => {
  const devUrlRegexp = /^https?:\/\/localhost|^https?:\/\/192\.168\./;

  return !!window.location.href.match(devUrlRegexp);
};

export const browserLang = () => {
  const uLang = window.navigator.language.split("-")[0];
  return langs[uLang] ? uLang : "en";
};

// @ts-ignore
export const inAppBrowser = () => typeof cordova !== "undefined" && cordova.InAppBrowser;

export const openInExternalBrowser = (
  link: string,
  { shouldReuseCurrentTab }: { shouldReuseCurrentTab?: boolean } = {}
) => {
  if (inAppBrowser()) {
    inAppBrowser().open(link, "_system");
  } else {
    if (shouldReuseCurrentTab) {
      window.location.href = link;
    } else {
      window.open(link, "_blank");
    }
  }
};

export const redirectToStore = () => {
  const referrer = (window as any)[REFERRER_FROM_URL] ? "referrer=" + (window as any)[REFERRER_FROM_URL] : null;
  const link = isIos()
    ? "https://apps.apple.com/us/app/duocards-learning-decks/id1508159917"
    : isAndroid()
      ? `https://play.google.com/store/apps/details?id=com.duocards.app${referrer ? "&" + referrer : ""}`
      : `https://www.duocards.com${referrer ? "?" + referrer : ""}`;

  openInExternalBrowser(link);
};

type Version = { major: number; minor: number; patch: number };

export const getDeviceOSVersion = (): Version | undefined => {
  const device = (window as any).device;
  if (!device || !device.version) {
    return;
  }
  const version = device.version;
  const [major, minor, patch] = version.split(".").map(Number);
  return { major, minor, patch };
};

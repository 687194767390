import { useSetState } from "components/ReduxProvider";
import React from "react";
import { useNavigate } from "react-router";
import { NOTIFICATION, NotificationEnum } from "../../sharedJs__generated/constants";
import { NOCARDSHASH } from "../dialogs/NoCardsDialog";
import { useEffectOnce, useEvent } from "react-use";
import { EVENT } from "tools/events";
import { useDBLog } from "hooks/useDBLog";
import { NOTIFICATIONDBLOG } from "sharedJs__generated/constants";
import { SUBSCRIPTION } from "../../root/profile/ProfileRouter.config";
import { MAIN } from "../../root/RootRouter.config";
import { useViewerQuery } from "../../queries/viewerQuery";
import { TUTORIALDONE } from "root/tutorial/steps/Verdict";
import { PETMAMMOTH } from "root/tutorial/steps/PetMammoth";

const messaging = window.cordova?.plugins?.firebase?.messaging;

const NotificationsRegister: React.FC<{}> = () => {
  const { viewer, hintWasSeen } = useViewerQuery();
  const setFcmId = useSetState("fcmId");
  const navigate = useNavigate();
  const dbLog = useDBLog();
  const hasOnboardingCompleted = hintWasSeen(PETMAMMOTH) || hintWasSeen(TUTORIALDONE);
  // this checks notification registration every time user opens app, it handles these cases:
  // - ask user for notifs permission after reinstall
  // - remove fcmId for users that has manually removed the permission in the OS
  // - ask for fcmId for users that has manually granted the permission to the OS
  useEffectOnce(() => {
    if (hasOnboardingCompleted && viewer && viewer.coachLevel !== NotificationEnum.none) {
      updateFcmId();
    }
  });

  useEvent(EVENT.refreshNotifications, () => {
    updateFcmId();
  });

  // handle what happens when user clicks a specific notification while the app is terminated
  useEffectOnce(() => {
    if (!messaging) return;

    messaging.onBackgroundMessage((payload) => {
      // messaging.setBadge(1); // we might play (abtest) with icon badging in future
      if (
        [
          NOTIFICATION.sale,
          NOTIFICATION.blackFridayNotif,
          NOTIFICATION.christmasFamilyNotif,
          NOTIFICATION.expired
        ].includes(payload.notifName)
      ) {
        navigate(SUBSCRIPTION.url());
      }

      if ([NOTIFICATION.noCards].includes(payload.notifName)) {
        navigate(MAIN.url() + NOCARDSHASH, { replace: true });
      }
      dbLog(NOTIFICATIONDBLOG, "notification opened", { notifName: payload.notifName });
    });
  });

  const updateFcmId = () => {
    if (messaging && viewer && viewer.coachLevel !== NotificationEnum.none) {
      // we do call for the permission even if user has fcmId already - to detect if he disabled manually notifs, it will go to the catch clause
      // we can ask for permission multiple times, it only shows once by the OS
      messaging
        .requestPermission()
        .then(() => {
          messaging.getToken().then((fcmId) => {
            setFcmId(fcmId);
          });
        })
        .catch((d) => {
          setFcmId(null);
        });
      // messaging.onMessage(... here we can handle notifications when app is open...);
    }
  };

  return null;
};

export default NotificationsRegister;

import React from "react";
import { Route, Routes } from "react-router-dom";
import Password from "./Password";
import Profile from "./Profile";
import UserEdit from "./UserEdit";
import NotificationsScreen from "./NotificationsScreen";
import Affiliate from "root/profile/Affiliate";
import SubscriptionScreen from "./subscription/SubscriptionScreen";
import Teachers from "./Teachers";
import { AFFILIATE, NOTIFICATIONS, PASSWORD, SUBSCRIPTION, USEREDIT, TEACHERS } from "./ProfileRouter.config";

const ProfileRouter: React.FC<{}> = () => {
  return (
    <Routes>
      <Route path={PASSWORD.relative} element={<Password />} />
      <Route path={SUBSCRIPTION.relative} element={<SubscriptionScreen />} />
      <Route path={USEREDIT.relative} element={<UserEdit />} />
      <Route path={NOTIFICATIONS.relative} element={<NotificationsScreen />} />
      <Route path={AFFILIATE.relative} element={<Affiliate />} />
      <Route path={TEACHERS.relative} element={<Teachers />} />
      <Route index element={<Profile />} />
    </Routes>
  );
};

export default ProfileRouter;

import React, { useCallback } from "react";
import styled from "styled-components/macro";
import MammothWithBubble, { MammothVariant } from "./MammothWithBubble";
import { useOnboardingStepsData } from "./onboardingStepsData";
import { useLocation, useNavigate } from "react-router-dom";
import OnboardingStep from "./OnboardingStep";
import { getStepHash, getStepNameFromHash } from "./OnboardingRedirect";
import useOnboardingData from "hooks/useOnboardingData";
import { ONBOARDING } from "root/RootRouter.config";
import { useGetState } from "components/ReduxProvider";
import { usePrevious } from "react-use";
import { OnboardingSubmitCallback, StepScreenId } from "./onboardingTypes";
import { isApp } from "tools/device";
import OnboardingQuestion from "../questions/OnboardingQuestion";
import { dispatchEvent, EVENT } from "../../../tools/events";
import { useABTest } from "queries/users/useABTest";

const MamothWrap = styled.div<{ $isSmall: boolean }>`
  padding: 0 30px;
  display: flex;
  align-items: ${({ $isSmall }) => ($isSmall ? "flex-start" : "center")};
  width: ${({ $isSmall }) => ($isSmall ? "100%" : "auto")};
`;

const MAX_PROGRESS_VALUE = 100;

const progressFunction = (stepIndex: number, totalSteps: number): number => {
  if (stepIndex <= 0 || totalSteps <= 0) {
    return 0; // first step not visible and also because of square root
  }

  if (totalSteps - 1 <= stepIndex) {
    return 1;
  }

  const normalizedProgress = stepIndex / totalSteps;
  const progress = Math.sqrt(normalizedProgress); // decrease progress increase over time
  return Math.min(Math.max(progress, 0), 1);
};

const calculateProgressValue = (currentStepIndex: number, totalSteps: number): number => {
  const progressForCurrentStep = progressFunction(currentStepIndex, totalSteps);
  const progress = Math.min(progressForCurrentStep * MAX_PROGRESS_VALUE, MAX_PROGRESS_VALUE);
  return progress;
};

const OnboardingSteps: React.FC = () => {
  const onboardingStepsData = useOnboardingStepsData();
  const [currentStepIndex, setCurrentStepIndex] = React.useState(-1); // correct step is selected from the hash url
  const isDeviceDialogOpen = useGetState("deviceUsersDialogOpen");
  const progressValue = calculateProgressValue(
    currentStepIndex,
    (onboardingStepsData?.findIndex((step) => step.screenId === StepScreenId.ACHIEVEMENTS) ?? -1) + 1
  );
  const currentStep = currentStepIndex > -1 && onboardingStepsData ? onboardingStepsData[currentStepIndex] : null;
  const prevStepIndex = usePrevious(currentStepIndex);
  const { getOnboardingData, saveOnboardingData } = useOnboardingData();
  const { notificationsPending } = getOnboardingData();
  const [canContinue, setCanContinue] = React.useState(false);
  const submitFunctionRef = React.useRef<() => Promise<boolean>>();
  const navigate = useNavigate();
  const location = useLocation();
  const abtest_notificationSecondDialog = useABTest("abtest_notificationSecondDialog");
  const abtest_allowNativeBack = useABTest("abtest_allowNativeBack");

  const handleBack = () => {
    if (!onboardingStepsData) return;

    const targetStep =
      currentStepIndex === 0 ? "/" : ONBOARDING.url() + getStepHash(onboardingStepsData[currentStepIndex - 1].screenId);

    navigate(targetStep);
  };

  const handleContinue = async () => {
    if (!onboardingStepsData) return;
    if (submitFunctionRef.current) {
      const res = await submitFunctionRef.current?.();
      if (!res) return;
    }
    setCanContinue(false);

    submitFunctionRef.current = undefined;

    navigate(ONBOARDING.url() + getStepHash(onboardingStepsData[currentStepIndex + 1].screenId));
  };

  const handleCanSubmitChange: OnboardingSubmitCallback = useCallback(
    (canContinue: boolean, submitFunction?: () => Promise<boolean>): void => {
      setCanContinue(canContinue);
      submitFunctionRef.current = canContinue ? submitFunction : undefined;
    },
    [setCanContinue]
  );

  React.useEffect(() => {
    if (!onboardingStepsData) return;
    const stepName = getStepNameFromHash(location.hash);
    const stepIndex = onboardingStepsData.findIndex((step) => step.screenId === stepName);
    setCurrentStepIndex(stepIndex);
  }, [location, onboardingStepsData, navigate]);

  React.useEffect(() => {
    if (!onboardingStepsData || abtest_allowNativeBack) return;
    // Determines if the user has navigated back to a step they shouldn't be able to access
    // Returns true if the user should be redirected to their previous valid step
    const isBackRestricted = () => {
      if (currentStepIndex <= 0) return false; // unknown step or first step
      if (prevStepIndex === undefined) return false; // No previous step recorded
      if (prevStepIndex <= currentStepIndex) return false; // Not moving backwards
      if (onboardingStepsData[prevStepIndex]?.canGoBack) return false; // Step allows going back
      if (onboardingStepsData[currentStepIndex]?.screenId === StepScreenId.SET_EMAIL) return false; // going to set email is always allowed
      return true; // User has moved back to a restricted step
    };

    if (isBackRestricted()) {
      navigate(ONBOARDING.url() + getStepHash(onboardingStepsData[prevStepIndex!].screenId));
    }
  }, [prevStepIndex, currentStepIndex, onboardingStepsData, navigate, abtest_allowNativeBack]);

  if (!currentStep || isDeviceDialogOpen || !onboardingStepsData) {
    return null;
  }

  if (isApp() && notificationsPending && !abtest_notificationSecondDialog) {
    // users from web has postponed notifications step
    const notificationStep = onboardingStepsData?.find(
      (step) => step.screenId === StepScreenId.QUESTIONS_NOTIFICATIONS
    );
    if (!notificationStep) return null; // this might happen when switching from web-funnel

    const handleContinue = () => {
      dispatchEvent(EVENT.refreshNotifications);
      saveOnboardingData({ notificationsPending: false });
    };

    return (
      <OnboardingStep
        showBackButton={false}
        showContinue={true}
        showProgressBar={false}
        buttonDisabled={false}
        buttonOnClick={handleContinue}
      >
        <MamothWrap $isSmall={true} key={notificationStep?.screenId}>
          <MammothWithBubble variant={MammothVariant.Small}>{notificationStep?.motivation}</MammothWithBubble>
        </MamothWrap>
        <OnboardingQuestion {...notificationStep?.componentProps} onRequestSubmit={handleContinue} />
      </OnboardingStep>
    );
  }

  return (
    <OnboardingStep
      onBack={handleBack}
      showBackButton={!!currentStep.canGoBack}
      showProgressBar={!currentStep.hideProgressBar && progressValue > 0}
      progressValue={progressValue}
      buttonDisabled={currentStep.optional ? false : !canContinue}
      showContinue={!currentStep.hideSubmitButton}
      buttonOnClick={handleContinue}
    >
      <>
        {currentStep.motivation && (
          <MamothWrap
            $isSmall={currentStep.component && !currentStep.forceLargeMammoth ? true : false}
            key={currentStep.screenId}
          >
            <MammothWithBubble
              variant={
                currentStep.component && !currentStep.forceLargeMammoth ? MammothVariant.Small : MammothVariant.Large
              }
            >
              {currentStep.motivation}
            </MammothWithBubble>
          </MamothWrap>
        )}
        {currentStep.component && (
          <currentStep.component
            onRequestSubmit={handleContinue}
            onCanSubmitChange={handleCanSubmitChange}
            {...currentStep.componentProps}
          />
        )}
      </>
    </OnboardingStep>
  );
};

export default OnboardingSteps;

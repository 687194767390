import React from "react";
import styled from "styled-components/macro";

// font: Baloo Chettan 2

const Title = styled.img`
  margin: 0 auto;
`;

type Props = {
  ref?: React.Ref<HTMLImageElement>;
  [key: string]: any;
};

const DuoCardsTitle = ({ ref, ...props }: Props) => {
  return (
    <Title
      ref={ref}
      alt="DuoCards"
      width="180"
      height="34"
      {...props}
      src="https://cdn.duocards.com/img/logo.png"
      srcSet="https://cdn.duocards.com/img/logo.png, https://cdn.duocards.com/img/logo.png 2x, https://cdn.duocards.com/img/logo3x.png 3x"
    />
  );
};

export const SmallDuoCards = styled(DuoCardsTitle).attrs({ width: 60, height: 11.33 })`
  padding: 0 6px;
  box-sizing: content-box;
`;

export default DuoCardsTitle;

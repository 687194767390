import { isBrowser } from "./device";

export enum PROXY {
  retained_1_hour = `retained_1_hour`,
  active_after_onboarding = `active_after_onboarding`,
  user_liked_app = `user_liked_app`,
  social_popup_displayed = `social_popup_displayed`,
  email_set = `email_set`
}

/**
  Cordova plugins docs:
    https://github.com/cordova-plugin-facebook-connect/cordova-plugin-facebook-connect
    https://github.com/chemerisuk/cordova-plugin-firebase-analytics
    https://support.singular.net/hc/en-us/articles/8586543222683-Singular-SDK-Integration-for-Cordova

  debugging:
    https://www.facebook.com/events_manager2/list/app/2599670450282809/overview?act=401611451583239
    https://console.firebase.google.com/u/0/project/duocards/analytics/app/ios:com.duocards.app/events
    https://app.singular.net/#/react/testing-console
    google-ads -> conversions
*/

export const logEvent = (eventName: string) => {
  if (isBrowser()) {
    window?.fbq?.("track", eventName); // works only in production
    gtag("event", eventName);
  } else {
    window.facebookConnectPlugin?.logEvent(eventName);
    window.cordova?.plugins?.firebase?.analytics?.logEvent(eventName);
    // window.plugins?.appsFlyer?.logEvent(eventName, {}, null, null);
    window.cordova?.plugins?.SingularCordovaSdk?.event(eventName);
  }
};

export const logFBTutorialCompletedEvent = () => {
  window.facebookConnectPlugin?.logEvent("fb_mobile_tutorial_completion", {
    fb_success: 1,
    fb_content: "duocards"
  });
};

type Params = { price: number; currency: string; params?: { external_id?: string } };
export const logPurchase = async ({ price, currency, params }: Params) => {
  if (process.env.NODE_ENV === "development") return;

  if (isBrowser()) {
    window?.fbq?.("track", "Purchase", { value: price, currency }); // works only in production
    window?.fbq?.("track", "purchase_estimate", { value: price, currency });
    gtag("event", "purchase_estimate", { value: price, currency }); // in google-ads: "Purchase (Google Analytics event purchase_estimate)"
    gtag_report_web_purchase(price, currency);
  } else {
    window.facebookConnectPlugin?.logPurchase(price, currency, { external_id: params?.external_id });
    window.cordova?.plugins?.firebase?.analytics?.logEvent("purchase_estimate", { value: price, currency });
    window.cordova?.plugins?.SingularCordovaSdk?.customRevenue("purchase", currency, price);
  }
};

// snippet from google ads
function gtag_report_web_purchase(value: number, currency: string) {
  gtag("event", "conversion", {
    send_to: "AW-11351319695/XLGmCJzkzZYaEI_J3aQq", // in google ads: "purchase_estimate - manual"
    value: value,
    currency: currency,
    transaction_id: "",
    event_callback: undefined
  });
  return false;
}

import React from "react";
import { useNavigate } from "react-router";
import styled from "styled-components/macro";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import { CARD } from "root/main/MainRouter";

export const SFab = styled(Fab)<{ $secondary?: boolean }>`
  z-index: 10;
  margin-left: ${(p) => (p.$secondary ? "0" : "15px")};
  &,
  &:hover {
    background: ${(p) => (p.$secondary ? p.theme.duo.color.green : p.theme.duo.color.primaryGradient)};
  }
  svg {
    font-size: 2rem;
  }
`;

type Props = {
  ref?: React.Ref<HTMLButtonElement>;
};

export const AddButton = ({ ref, ...props }: Props) => {
  const navigate = useNavigate();
  return (
    <SFab
      {...props}
      color="primary"
      onClick={() => {
        navigate(CARD.url());
      }}
      ref={ref}
    >
      <AddIcon />
    </SFab>
  );
};

import React from "react";
import styled from "styled-components";
import { teacherFeatures } from "root/profile/Teachers";
import FeatureItem from "./FeatureItem";

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 37px;
  margin-left: 40px;
  margin-right: 40px;
  height: 100%;
  overflow-y: auto;
  padding-top: 60px;
  padding-bottom: 60px;
`;

const features = [
  ...teacherFeatures,
  {
    iconPath: `https://imgs.duocards.com/onboarding/3m/learn.svg`,
    title: "But first, let's explore the app from a student's perspective."
  }
];

const TeachersInfoStep: React.FC = () => {
  return (
    <Wrap>
      {features.map((feature, index) => (
        <FeatureItem key={index} iconPath={feature.iconPath} title={feature.title} />
      ))}
    </Wrap>
  );
};

export default TeachersInfoStep;

import { useGetState, useSetState } from "components/ReduxProvider";
import React from "react";
import {
  RequestInfoUpdateOptions,
  ConsentStatus,
  DebugGeography,
  PrivacyOptionsRequirementStatus,
  TrackingAuthorizationStatus
} from "components/cordova/ConsentTypes";
import * as Sentry from "@sentry/browser";
import { EVENT } from "tools/events";
import { useEvent } from "react-use";
import { isIos } from "tools/device";
import { useViewerQuery } from "queries/viewerQuery";
import { useSetFlag } from "queries/users/setFlag";
import { CONSENT_CHECK_FLAG } from "queries/users/userFlagsModel";
const consent = window.consent;
const canUseNewAdmob = window.appversion > 11909;

const enum ConsentCheckFlag {
  viewed = "viewed",
  accepted = "accepted"
}

const ConsentInitializer: React.FC<{}> = () => {
  const trackingDeniedRedux = useGetState("trackingDenied");
  const setTrackingDenied = useSetState("trackingDenied");
  const canRequestAdsRedux = useGetState("canRequestAdmobAds");
  const setCanRequestAds = useSetState("canRequestAdmobAds");
  const { getFlag } = useViewerQuery();
  const setFlag = useSetFlag();
  const consentCheckFlag = getFlag(CONSENT_CHECK_FLAG)?.value;

  const updateTrackingDeniedStatus = React.useCallback(
    (iosTrackingStatus: TrackingAuthorizationStatus) => {
      const trackingDenied = iosTrackingStatus === TrackingAuthorizationStatus.denied;
      if (!!trackingDeniedRedux !== trackingDenied) {
        setTrackingDenied(trackingDenied);
      }
    },
    [trackingDeniedRedux, setTrackingDenied]
  );

  const getOrRequestIosTrackingStatus = React.useCallback(async (): Promise<TrackingAuthorizationStatus> => {
    let iosTrackingStatus = await consent.trackingAuthorizationStatus();
    if (!iosTrackingStatus) {
      iosTrackingStatus = await consent.requestTrackingAuthorization();
    }
    return iosTrackingStatus;
  }, []);

  const doConsentProcess = React.useCallback(async () => {
    // docs: https://admob-plus.github.io/docs/cordova/consent
    // more info: https://developers.google.com/admob/android/privacy
    if (!consent) return;

    const isIosPlatform = isIos();
    const consentStatus: ConsentStatus = await consent.getConsentStatus();

    let iosTrackingStatus: TrackingAuthorizationStatus = TrackingAuthorizationStatus.notDetermined;
    let canRequestAds: boolean = true; // default to true, if using old admob

    if (canUseNewAdmob) {
      await requestConsentInfoUpdateIfNeeded(consentStatus);
      if (consentCheckFlag !== ConsentCheckFlag.viewed && consentCheckFlag !== ConsentCheckFlag.accepted) {
        setFlag({ name: CONSENT_CHECK_FLAG, value: ConsentCheckFlag.viewed });
      }
      await consent.loadAndShowIfRequired(); // if user is in EAA it displays GDPR consent and then ATT popup, otherwise it displays pre-ATT popup instead of consent
      if (consentCheckFlag !== ConsentCheckFlag.accepted) {
        setFlag({ name: CONSENT_CHECK_FLAG, value: ConsentCheckFlag.accepted });
      }
      if (isIosPlatform) {
        iosTrackingStatus = await consent.trackingAuthorizationStatus();
      }

      canRequestAds = await consent.canRequestAds();
    } else if (isIosPlatform) {
      // oldadmob and ios
      iosTrackingStatus = await getOrRequestIosTrackingStatus();
    }

    updateTrackingDeniedStatus(iosTrackingStatus);

    // update canrequestads
    if (!!canRequestAdsRedux !== canRequestAds) {
      setCanRequestAds(canRequestAds);
      if (!canRequestAds) {
        Sentry.captureException(new Error("Ads not allowed"));
      }
    }
  }, [
    canRequestAdsRedux,
    setCanRequestAds,
    consentCheckFlag,
    setFlag,
    updateTrackingDeniedStatus,
    getOrRequestIosTrackingStatus
  ]);

  const ATTConsent = React.useCallback(async () => {
    if (!consent || !isIos()) return;

    const iosTrackingStatus = await getOrRequestIosTrackingStatus();
    updateTrackingDeniedStatus(iosTrackingStatus);
  }, [updateTrackingDeniedStatus, getOrRequestIosTrackingStatus]);

  useEvent(EVENT.ATTConsent, ATTConsent);
  useEvent(EVENT.consentCheck, doConsentProcess);

  return null;
};

const getDebugParams = (): RequestInfoUpdateOptions => ({
  testDeviceIds: [
    "E34DB6CCCB61991C73501F13D7434DC3" /*ANDROID tablet MT*/,
    "019443AD-62BA-41C9-9625-01E88053A27D" /*ios phone MT*/
  ], // look into android logcat or ios device logs for this device hash and add it to array
  debugGeography: DebugGeography.EEA // Europe Union
});

const requestConsentInfoUpdateIfNeeded = async (consentStatus: ConsentStatus) => {
  if (consentStatus === ConsentStatus.Unknown || consentStatus === ConsentStatus.Required) {
    await consent.requestInfoUpdate(process.env.NODE_ENV === "development" ? getDebugParams() : undefined);
  }
};

export const checkIfAdmobPrivacyOptionIsRequired = async (): Promise<boolean> => {
  if (!consent || !canUseNewAdmob) return false;

  const status = await consent.privacyOptionsRequirementStatus();
  return status === PrivacyOptionsRequirementStatus.Required;
};

export const showAdmobPrivacyForm = async () => {
  await consent.showPrivacyOptionsForm();
};

export default ConsentInitializer;

import { graphql } from "babel-plugin-relay/macro";

export const SortSCardQL = graphql`
  mutation sortSCardMutation($id: ID!, $sortAfterId: ID, $backLang: String) {
    sortSCard(id: $id, sortAfterId: $sortAfterId) {
      sCards(backLang: $backLang) {
        id
        front
        hint
        back {
          value
          translated
        }
      }
    }
  }
`;

import React from "react";
import styled from "styled-components/macro";
import { Trans } from "react-i18next";

const SKnowSign = styled.div`
  color: ${({ theme }) => theme.duo.color.green};
  font-size: 18px;
  font-weight: bold;
  position: absolute;
  z-index: 200;
  transform: rotate(-20deg);
  left: 20px;
  top: 25px;
  border: 3px solid ${({ theme }) => theme.duo.color.green};
  border-radius: ${({ theme }) => theme.duo.borderRadius.normal};
  padding: 5px 10px;
  opacity: 0;
  transition: opacity 0.15s ease;
`;
const SUnknowSign = styled(SKnowSign)`
  color: ${({ theme }) => theme.duo.color.red};
  transform: rotate(20deg);
  left: auto;
  top: 35px;
  right: 15px;
  border-color: ${({ theme }) => theme.duo.color.red};
`;
const SDelSign = styled(SKnowSign)`
  color: ${(p) => p.theme.duo.color.textDelete};
  transform: rotate(2deg);
  left: 37%;
  top: 20px;
  right: auto;
  border-color: grey;
`;

type Props = {
  ref?: React.Ref<HTMLDivElement>;
};

export const KnownSign = ({ ref }: Props) => (
  <SKnowSign ref={ref}>
    <Trans>Got it</Trans>
  </SKnowSign>
);

export const UnknowSign = ({ ref }: Props) => (
  <SUnknowSign ref={ref}>
    <Trans>Study again</Trans>
  </SUnknowSign>
);

export const DelSign = ({ ref }: Props) => (
  <SDelSign ref={ref}>
    <Trans>Delete</Trans>
  </SDelSign>
);

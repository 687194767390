import React from "react";
import styled from "styled-components/macro";
import { useCardCreate } from "queries/cards/cardCreate";
import { sourceQuery$data } from "../../../queries/sources/__generated__/sourceQuery.graphql";
import { useSetState } from "../../../components/ReduxProvider";
import { Trans, useTranslation } from "react-i18next";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Button } from "@mui/material";
import { useViewerQuery, useViewerDecks } from "../../../queries/viewerQuery";
import { PickerCard } from "../../../components/picker/Picker";
import SCardForm from "../../../components/picker/SCardForm";
import Rate from "../../../components/other/Rate";
import CardItem from "components/other/CardItem";
import { faEyeSlash, faRectangleVerticalHistory, faEdit, faFlag } from "@duocards/pro-regular-svg-icons";
import { EVENT, dispatchEvent } from "tools/events";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Wrap = styled.div``;
const ButtWrap = styled.div`
  position: sticky;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  padding: 15px 0 25px;
  width: 100%;
  background: linear-gradient(#f3f9ff00, #f3f9ffaa 30%, #f3f9ffff 95%);
  z-index: 5;
`;
const SButton = styled(Button)`
  width: 70%;
  max-width: 400px;
`;

const SMenuItem = styled.div`
  display: grid;
  grid-template-columns: 30px 1fr;
  align-items: center;
  justify-items: center;
  gap: 10px;
`;

type Props = {
  source: NonNullable<sourceQuery$data["sourceByUriOrId"]>;
  onSetShowPicker: VoidFunction;
};

const SetCards: React.FC<Props> = ({ source, onSetShowPicker }) => {
  const { cardCreate } = useCardCreate();
  const { t } = useTranslation();
  const [dialogCard, setDialogCard] = React.useState<PickerCard | undefined>();
  const { viewer } = useViewerQuery();
  const { deck, decks } = useViewerDecks();
  const setDeckCreate = useSetState("deckCreate");

  const handleCreate = (sCard: NonNullable<Props["source"]["sCards"]>[0], deleted) => {
    const back = sCard.back?.value;
    if (back === undefined || deck?.front !== source.lang) {
      return setDeckCreate({ lang: source.lang as string });
    }
    cardCreate({
      front: sCard.front,
      hint: sCard.hint,
      sCardId: sCard.id,
      sourceId: source.id,
      back,
      sBackId: sCard.back?.sBackId,
      deckId: deck.id,
      deleted,
      svg: sCard.svg
    });
  };
  const handleSuggest = (sCard: NonNullable<Props["source"]["sCards"]>[0]) => {
    if (!source.mine) {
      return !viewer?.name
        ? alert(t("You have to be registered to suggest edits"))
        : dispatchEvent(EVENT.openReportIssueDialog, {
            type: "card",
            sCardId: sCard.id,
            sBackId: sCard.back?.sBackId,
            langBack: deck?.back
          });
    } else if (!sCard.back?.value || deck?.front !== source.lang) {
      return setDeckCreate({ lang: source.lang as string });
    }
    !viewer?.name ? alert(t("You have to be registered to suggest edits")) : setDialogCard(sCard);
  };

  if (!source?.sCards.length) return null;

  return (
    <>
      <Wrap>
        {source?.sCards?.map((sCard) => (
          <CardItem
            icon={
              sCard.isInMyDeck === false ? (
                <VisibilityOffIcon />
              ) : sCard.isInMyDeck ? (
                <FontAwesomeIcon icon={faRectangleVerticalHistory} />
              ) : undefined
            }
            style={{
              opacity: sCard.isInMyDeck === false || sCard.sourceId !== source.id ? 0.6 : 1,
              textDecoration: sCard.sourceId !== source.id ? "line-through" : undefined
            }}
            iconColor={sCard.isInMyDeck === true ? "green" : "textGrey"}
            key={sCard.id}
            card={{
              ...sCard,
              back: sCard.back?.value
            }}
            sourceId={source.id}
            speechLang={source.lang}
            actions={[
              ...(sCard.isInMyDeck === true
                ? []
                : [
                    {
                      title: (
                        <SMenuItem>
                          <FontAwesomeIcon icon={faRectangleVerticalHistory} />
                          {t("Add")}
                        </SMenuItem>
                      ),
                      action: () => handleCreate(sCard, false)
                    }
                  ]),
              ...(sCard.isInMyDeck === false
                ? []
                : [
                    {
                      title: (
                        <SMenuItem>
                          <FontAwesomeIcon icon={faEyeSlash} />
                          {t("Dismiss")}
                        </SMenuItem>
                      ),
                      action: () => handleCreate(sCard, true)
                    }
                  ]),
              {
                title: (
                  <SMenuItem>
                    {source.mine ? <FontAwesomeIcon icon={faEdit} /> : <FontAwesomeIcon icon={faFlag} />}
                    {source.mine ? t("Edit") : t("Report poor quality")}
                  </SMenuItem>
                ),
                action: () => handleSuggest(sCard)
              }
            ]}
          />
        ))}
        <ButtWrap>
          <SButton
            color="primary"
            variant="contained"
            size="large"
            onClick={
              decks && decks.find((d) => d.front === source.lang)
                ? onSetShowPicker
                : () => setDeckCreate({ lang: source.lang }) // this shows download button in public-mode
            }
          >
            <Trans>Pick cards</Trans>
          </SButton>
        </ButtWrap>
        <SCardForm card={dialogCard} source={source} onClose={() => setDialogCard(undefined)} />
      </Wrap>
      <Rate padded source={source} />{" "}
    </>
  );
};

export default SetCards;

import React from "react";
import styled from "styled-components/macro";
import { useNavigate } from "react-router-dom";
import { HeaderLayout } from "../../../components/header/Header";
import HeaderMenu from "../../../components/header/HeaderMenu";
import CardForm from "./CardForm";
import ExtensionOutButton from "./ExtensionOutButton";
import { useCardCreate } from "queries/cards/cardCreate";
import { CardValues, useGetState, useSetState } from "../../../components/ReduxProvider";
import { useViewerDecks, useViewerQuery } from "../../../queries/viewerQuery";
import { devicePaddings, isChromeExtension } from "../../../tools/device";
import { useTranslation } from "react-i18next";
import { dispatchEvent, EVENT, snackbar } from "tools/events";
import { PICKED_FEW_CARDS } from "components/fire/xpHooks";
import { OWNWORDQUEST, useCreatingNewCard } from "../main/quests/everySource/OwnWord";
import { useMount, useUnmount } from "react-use";
import { useSeeHint } from "components/hint/Hint";
import OwnWordRewardDialog from "../main/quests/everySource/OwnWordRewardDialog";
import { MAIN } from "../../RootRouter.config";

const { top, bottom } = devicePaddings();

const Wrap = styled.div`
  padding: ${top + 20}px 20px ${bottom + 20}px;
`;

const CardNew: React.FC<{}> = () => {
  const { deck } = useViewerDecks();
  const navigate = useNavigate();
  const { cardCreate } = useCardCreate();
  const { t } = useTranslation();
  const { hintWasSeen } = useViewerQuery();
  const creatingNewCard = useCreatingNewCard();
  const setOwnWordAdded = useSetState("ownWordAdded");
  const ownWordAdded = useGetState("ownWordAdded");
  const seeHint = useSeeHint();

  useUnmount(() => {
    // when user visits this component before quest, or if uses native-back, it will finish the quest without reward
    (!hintWasSeen(PICKED_FEW_CARDS) || !ownWordAdded) && seeHint(OWNWORDQUEST);
  });

  useMount(() => {
    // default ownWordAdded is undefined, false is set after seeing this component
    // so if user visits this component twice (user turned phone off and on to unstuck), we will set seehint and back button will appear
    if (creatingNewCard) {
      if (ownWordAdded === false) seeHint(OWNWORDQUEST);
      if (ownWordAdded === undefined) setOwnWordAdded(false);
    }
  });

  const handleSave = (values: CardValues, opts?: { fromSharing?: boolean }) => {
    if (cardCreate({ ...values, deckId: deck!.id })) {
      hintWasSeen(OWNWORDQUEST) && snackbar(t("The card was added"), { bottom: true, autoHide: 2000 });
    } else {
      return false;
    }
    if (hintWasSeen(PICKED_FEW_CARDS) && !hintWasSeen(OWNWORDQUEST)) {
      setOwnWordAdded(true);
      dispatchEvent(EVENT.openOwnWordDialog);
    }
  };

  if (!deck) return null;

  return (
    <HeaderLayout
      // replace because of the "shared text" behaviour
      onBack={() => navigate(`${MAIN.url()}`, { replace: true })}
      noBack={isChromeExtension() || creatingNewCard}
      right={!creatingNewCard && <HeaderMenu noLabel />}
      transparent
      withShadow
    >
      <Wrap>
        <CardForm onSave={handleSave} fromLang={deck.front} toLang={deck.back} checkForDuplicates={true} />
        <ExtensionOutButton />
      </Wrap>

      <OwnWordRewardDialog />
    </HeaderLayout>
  );
};

export default CardNew;

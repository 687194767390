import * as Sentry from "@sentry/react";

export const initSentry = () => {
  if (process.env.NODE_ENV === "production") {
    Sentry.init({
      dsn: "https://b4333e112aa549caad5ddc7dbc79091a@o405311.ingest.sentry.io/5270716",
      beforeSend: (e) => {
        // ignore all graphql errors (which are in object)
        if (e?.message && (e.message[0] as any)?.message) return null;
        return e;
      },
      beforeBreadcrumb: (breadcrumb, hint) => {
        if (
          breadcrumb.category === "fetch" &&
          breadcrumb.data?.url?.indexOf("identitytoolkit.googleapis.com") > 0 &&
          typeof hint?.response?.clone === "function" &&
          hint?.response?.status >= 400
        ) {
          if ((window as any).logAuthEvent) {
            Sentry.captureMessage(JSON.stringify((window as any).logAuthEvent));
          }
        }
        return breadcrumb;
      },
      ignoreErrors: [
        "AbortError: The play() request was interrupted by a call to pause(). https://goo.gl/LdLk22",
        "AbortError: The play() request was interrupted by a call to pause().",
        "Non-Error promise rejection captured with value: false",
        "Failed to execute 'getComputedStyle' on 'Window': parameter 1 is not of type 'Element'.",
        "NotFoundError: Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.",
        "ResizeObserver loop limit exceeded",
        "AbortError: The operation was aborted.",
        "Cannot read properties of undefined (reading 'data')", // connection error | outdated response
        "Cannot read properties of null (reading 'data')", // connection error | outdated response
        "null is not an object (evaluating 'e.data')", // connection error | outdated response
        "undefined is not an object (evaluating 'e.data')", // connection error
        "Cannot read properties of null (reading 'src')", // connection error
        "Cannot read property 'data' of null", // connection error
        "e is undefined", // connection error
        // "Can't find variable: cordova", // connection error on ios
        "Non-Error promise rejection captured with value: Request Error: No ad to show.", // is covered with another (less frequent) error in setry
        "Non-Error promise rejection captured with value: The provided view controller is already presenting another view controller.", // admob (3rd party library) issue
        /com.google.android.gms.ads/, // containing "com.google.android.gms.ads" -> often error from admob plugin
        "Request Error: No ad to show.", // often error from admob plugin
        "UnknownError: Connection to Indexed Database server lost. Refresh the page to try again", // often error happeing all the time, but not blocking anything
        "SubscriptionRegister not existing storeId"
      ]
    });

    if (window.appversion) {
      Sentry.setTag("appversion", window.appversion);
    }

    if (window.cordova) {
      document.addEventListener("deviceready", () => {
        // TODO: remove this, or make it working, for unknown reason, the BuildInfo is not available on the window object, tried iOS app
        const BuildInfo = (window as any).BuildInfo;
        if (BuildInfo) {
          Sentry.setContext("cordova", BuildInfo.version);
        }
      });
    }
  }
};

import { IconButton } from "@mui/material";
import React from "react";
import styled, { css } from "styled-components/macro";
import { rightAlignLangs, smallAlphabets } from "sharedJs__generated/langsList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVolume } from "@duocards/pro-regular-svg-icons";
import { stripHtml } from "tools/strings";

const Wrap = styled.div<{ $active?: boolean; $lang: string }>`
  display: flex;
  align-items: flex-start;
  padding: 4px 20px 4px 5px;
  font-size: ${({ $lang }) => (!smallAlphabets.includes($lang) ? 110 : $lang === "ar" ? 170 : 140)}%;
  line-height: 1.6;
  margin: 0;
  text-align: ${({ $lang }) => (rightAlignLangs.includes($lang) ? `right` : "left")};
  justify-content: ${({ $lang }) => (rightAlignLangs.includes($lang) ? `flex-end` : "flex-start")};
  ${({ $active }) =>
    $active &&
    css`
      background: white;
      box-shadow: 0.5px 0.5px 7px #0001;
    `}
`;
const SIconButton = styled(IconButton)<{ $active?: boolean }>`
  color: ${({ theme }) => theme.duo.color.primary};
  opacity: ${({ $active }) => ($active ? 0.9 : 0.4)};
  top: 3px;
  padding: 5px 10px 5px 5px;
  svg {
    width: 18px;
    height: 18px;
  }
`;
const Text = styled.div`
  margin: 3px 0;
`;

type Props = {
  onPlay: VoidFunction;
  caption: { start: number; dur: number; text: string };
  active: boolean;
  lang: string;
  ref: React.Ref<HTMLDivElement>;
};

const Caption = ({ onPlay, caption, active, lang, ref }: Props) => {
  return (
    <Wrap $active={active} ref={ref} $lang={lang}>
      <SIconButton onClick={onPlay} size="small" $active={active}>
        <FontAwesomeIcon icon={faVolume} />
      </SIconButton>
      <Text>{stripHtml(caption.text)}</Text>
    </Wrap>
  );
};

export default Caption;

import React from "react";
import CardNewDialog from "../../root/main/card/CardNewDialog";
import CardEditDialog from "../cardEditDialog/CardEditDialog";
import CardsMaxDialog from "./CardsMaxDialog";
import DeckCreateDialog from "./DeckCreateDialog";
import FlamesMaxDialog from "./FlamesMaxDialog";
import InviteAcceptDialog from "./InviteAcceptDialog";
import InviteDialog from "./InviteDialog/InviteDialog";
import TooManyDecksDialog from "./TooManyDecksDialog";
import NoCardsDialog from "./NoCardsDialog";
import ReportIssueDialog from "./ReportIssueDialog";
import LoaderGlobalWithMemoDialog from "./LoaderGlobalWithMemoDialog";
import SaleMainDialog from "./SaleMainDialog/SaleMainDialog";
import GoalDialog from "./GoalDialog/GoalDialog";
import GeneratedAppLangDialog from "components/other/GeneratedAppLangDialog";

type Props = {};

const Dialogs: React.FC<Props> = () => {
  return (
    <>
      <DeckCreateDialog />
      <TooManyDecksDialog />
      <InviteDialog />
      <InviteAcceptDialog />
      <CardEditDialog />
      <CardNewDialog />
      <GoalDialog />
      <CardsMaxDialog />
      <FlamesMaxDialog />
      <NoCardsDialog />
      <ReportIssueDialog />
      <LoaderGlobalWithMemoDialog />
      <SaleMainDialog />
      <GeneratedAppLangDialog />
    </>
  );
};

export default Dialogs;

import { Button } from "@mui/material";
import { PICKERID } from "components/picker/Picker";
import { useViewerDecks } from "queries/viewerQuery";
import React from "react";
import { Trans } from "react-i18next";
import { useDebounce } from "react-use";
import useClickAway from "hooks/useClickAway";
import styled, { css, keyframes } from "styled-components/macro";
import { FIRSTCARDSCOUNT } from "../Tutorial";
import { TutorialFC } from "../TutorialContent";
import TutorialPointer from "../TutorialPointer";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DeleteIcon from "@mui/icons-material/Delete";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCardsBlank } from "@duocards/pro-solid-svg-icons";

export const FIRSTFEWHINT = "firstFew";

const moovingKF = keyframes`
  0% { top: 50%; opacity: 1; }
  50% {  top: 24%; opacity: 1; }
  60% { top: 24%; opacity: 0; }
  90% { top: 50%; opacity: 0; }
`;

const Green = styled.strong`
  color: ${({ theme }) => theme.duo.color.green};
  display: block;
  font-size: 110%;
`;
const shakeKF = keyframes`
  10%, 90% {left: 2px; }
  25%, 75% {left: -4px;}
  50% {left: 4px;}
`;
const Strong = styled.strong<{ shake?: boolean; color?: "green" | "grey" }>`
  position: relative;
  color: ${({ theme, color }) => (color === "green" ? theme.duo.color.green : color === "grey" ? "#999" : "inherit")};
  ${({ shake }) =>
    shake &&
    css`
      animation: ${shakeKF} 500ms;
    `};
`;
const STutorialPointer = styled(TutorialPointer)`
  top: 50%;
  left: 55%;
  animation: ${moovingKF} 2s ease-out infinite;
`;
const Cards = styled.div`
  position: relative;
  height: 130px;
  width: 130px;
  margin: 15px auto 20px;
  background: radial-gradient(${({ theme }) => theme.duo.color.lightBlue}, #fff 70%);
`;
const Card = styled.div<{ rotated?: boolean }>`
  position: absolute;
  top: 40px;
  left: 0;
  right: 0;
  width: 100px;
  height: 50px;
  background: white;
  border-radius: 5px;
  box-shadow: 0.5px 0.5px 4px #0006;
  margin: 0 auto;
  transform: rotate(${({ rotated }) => (rotated ? "8deg" : "-1deg")});
`;
const Svg = styled.svg`
  position: relative;
  top: -30px;
  left: 14px;
  width: 60px;
  height: 60px;
`;
const Icons = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 20px;
  margin: 0 auto;
  width: 30px;
  svg {
    position: absolute;
    color: ${({ theme }) => theme.duo.color.green};
    filter: drop-shadow(0 0 3px #fff);
  }
`;
const UpIcon = styled(KeyboardArrowUpIcon)`
  top: 15px;
`;
const SaveIcon = styled(FontAwesomeIcon)`
  top: -5px;
  font-size: 19px;
  transform: rotate(20deg);
`;
const DownIcon = styled(KeyboardArrowDownIcon)`
  top: 93px;
  color: #999 !important;
`;
const DelIcon = styled(DeleteIcon)`
  top: 114px;
  color: #999 !important;
`;
const Later = styled.span`
  display: block;
  font-size: 90%;
  margin-top: 3px;
  opacity: 0.6;
`;

const FirstFewCards: TutorialFC = ({ setPosition, position, setVisibleId }) => {
  const [clickedAway, setClickedAway] = React.useState(false);
  const [shakeIt, setShakeIt] = React.useState(false);
  const { deck } = useViewerDecks();
  const ref = React.useRef<any>();

  const bottom = position === "bottom";

  useClickAway(ref, () => bottom && setClickedAway(true), ["mousedown", "touchstart"]);
  useClickAway(ref, () => bottom && setShakeIt(true), ["click"]);
  useDebounce(() => shakeIt && setShakeIt(false), 500, [shakeIt]);

  return (
    <div ref={ref}>
      {bottom && (
        <Green>
          {deck?.stats.total} / {FIRSTCARDSCOUNT}
        </Green>
      )}
      {!bottom && (
        <Cards>
          <Card rotated></Card>
          <Card>
            <Svg enable-background="new 0 0 506.664 506.664" height="512" viewBox="0 0 506.664 506.664" width="512">
              <g>
                <path
                  fill="#444"
                  d="m35.499 506.664c-2.874 0-5.748-.198-8.621-.562-4.327-.562-7.399-4.558-6.838-8.886.562-4.327 4.558-7.399 8.886-6.838 11.132 1.453 22.528-.958 32.57-6.177-12.024-14.964-14.567-37.161-5.021-54.173 7.399-13.147 24.147-22.627 38.284-14.997 6.375 3.435 10.669 9.513 12.387 17.54 1.288 6.078 1.057 13.114-.661 19.819-3.039 11.759-9.348 22.594-18.201 31.546 11.495 3.468 25.138.495 33.396-8.225 1.486-1.586 2.907-3.402 4.426-5.318 2.444-3.138 4.988-6.375 8.324-9.249 3.402-2.94 7.531-5.087 11.66-6.078 5.252-1.255 10.306-.628 14.567 1.817 7.102 4.03 9.612 11.297 11.627 17.111.231.661.462 1.321.694 1.982 1.453 4.063 3.468 5.946 4.063 6.045.694-.033 2.643-1.552 3.799-2.444 2.444-1.883 5.516-4.261 9.579-5.318 7.432-1.949 14.039 1.354 18.861 3.799.595.297 1.222.595 1.817.892 19.027 9.15 45.089 2.114 56.948-15.327 2.444-3.634 7.399-4.558 11-2.114 3.634 2.444 4.558 7.399 2.114 11-7.895 11.66-20.249 20.216-34.75 24.114s-29.498 2.709-42.182-3.402c-.661-.33-1.354-.661-2.015-.991-2.874-1.42-6.144-3.072-7.796-2.643-.958.231-2.444 1.42-3.898 2.543-2.94 2.279-6.97 5.384-12.519 5.715-.33.033-.661.033-.958.033-8.159 0-15.195-6.111-18.961-16.549-.264-.727-.495-1.453-.76-2.18-1.255-3.667-2.577-7.432-4.459-8.522-1.718-.991-5.318.132-8.027 2.477-2.18 1.883-4.129 4.393-6.21 7.036-1.652 2.147-3.402 4.327-5.417 6.474-9.117 9.612-22.132 14.567-35.179 14.567-8.258 0-16.483-1.982-23.717-6.078-11.99 7.597-25.467 11.561-38.812 11.561zm48.558-78.418c-4.889 0-10.835 4.426-13.741 9.579-6.507 11.561-4.36 27.318 4.261 37.293 7.928-7.168 13.907-16.384 16.582-26.69 1.949-7.564 1.255-16.681-3.898-19.456-.991-.495-2.081-.726-3.204-.726z"
                />
              </g>
            </Svg>
          </Card>
          <Icons>
            <UpIcon />
            <DownIcon />
            <SaveIcon icon={faCardsBlank} />
            <DelIcon />
          </Icons>
        </Cards>
      )}
      <p>
        <Trans>
          <Strong color="green" shake={shakeIt}>
            Drag up
          </Strong>{" "}
          words you want to learn.
        </Trans>
        {bottom && (
          <Later>
            (<Trans>Drag down will be available later</Trans>)
          </Later>
        )}
      </p>
      {!bottom && (
        <Trans parent="p">
          <Strong color="grey">Drag down</Strong> to dismiss.
        </Trans>
      )}
      {!bottom && (
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setPosition("bottom");
            setVisibleId(PICKERID);
          }}
        >
          OK
        </Button>
      )}

      {bottom && !clickedAway && <STutorialPointer />}
    </div>
  );
};

export default FirstFewCards;

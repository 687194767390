import React from "react";
import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import TutorialPointer from "../../../../tutorial/TutorialPointer";
import { Box, useTheme } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGift, faUserVneck as farUserVneck } from "@duocards/pro-regular-svg-icons";
import { useSeeHint } from "../../../../../components/hint/Hint";
import { INVITE_FRIEND_QUEST, INVITE_FRIEND_QUEST_PHASE_2 } from "./InviteFriendQuest.model";
import { dispatchEvent, EVENT } from "../../../../../tools/events";
import TransVariable from "../../../../../components/other/TransVariable";
import { QuestCloseButton } from "../misc/QuestCloseButton";
import { QuestMessage } from "../misc/QuestMessage";

const STutorialPointer = styled(TutorialPointer)`
  bottom: 20px;
  right: 14%;
  @media (min-width: 500px) {
    right: calc(50% - 175px);
  }
`;
const RewardWrap = styled.div`
  position: absolute;
  left: calc(50% - 20px);
  top: -23px;
  svg {
    position: static;
  }
`;
const P = styled(Box)`
  margin: 10px 0 13px;
`;
const SFontAwesomeIcon = styled(FontAwesomeIcon)`
  height: 24px;
  margin: 0 1px;
`;

export const InviteFriendQuestPhase2 = () => {
  const seeHint = useSeeHint();
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <QuestMessage>
      <div>
        <P>
          <Trans>
            Go to your <SFontAwesomeIcon icon={farUserVneck} color={theme.palette.text.secondary} /> and click on "
            <TransVariable text={t("Connect with friends")} />" button.
          </Trans>
        </P>

        <QuestCloseButton
          onClick={() => {
            seeHint(INVITE_FRIEND_QUEST_PHASE_2);
            seeHint(INVITE_FRIEND_QUEST);
            setTimeout(() => dispatchEvent(EVENT.updateMain));
          }}
        />

        <RewardWrap>
          <FontAwesomeIcon icon={faGift} size="2x" color={theme.duo.color.star} />
        </RewardWrap>

        <STutorialPointer pulsing rotation={135} />
      </div>
    </QuestMessage>
  );
};

import { useSetFlag } from "queries/users/setFlag";
import { UTMS_FLAG } from "queries/users/userFlagsModel";
import { useViewerQuery } from "queries/viewerQuery";
import React from "react";
import { useEffectOnce } from "react-use";

export type UtmFlagBody = {
  utm_campaign: string;
  utm_source: string;
  utm_medium: string;
  fb_external_id: string;
};

/**
 * here we store utms to the store and when the user logs in, we store it as a flag
 */

const UtmAndExternalIdResolver = () => {
  const [utms, setUtms] = React.useState<{ utm_campaign: string; utm_source: string; utm_medium: string }>();
  const { viewer, getFlag } = useViewerQuery();
  const setFlag = useSetFlag();
  const done = React.useRef(false);

  useEffectOnce(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const utm_campaign = urlParams.get("utm_campaign");
    const utm_source = urlParams.get("utm_source");
    const utm_medium = urlParams.get("utm_medium"); // ppc | email ...

    if (utm_campaign && utm_source && utm_medium && utm_medium === "ppc") {
      setUtms({ utm_campaign, utm_source, utm_medium });
    }
  });

  React.useEffect(() => {
    if (done.current) return;
    const utmsFlag = getFlag(UTMS_FLAG);
    if (viewer && utms && !utmsFlag) {
      const fb_external_id = (window as any).fb_external_id;
      setFlag({ name: UTMS_FLAG, body: JSON.stringify({ ...utms, fb_external_id } as UtmFlagBody) });
      done.current = true;
    }
  });

  return null;
};

export default UtmAndExternalIdResolver;

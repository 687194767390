import { graphql } from "babel-plugin-relay/macro";

export const EditSCard = graphql`
  mutation editSCardMutation(
    $id: ID!
    $front: String!
    $hint: String
    $back: String
    $backLang: String
    $theory: String
    $sourceId: ID
    $svg: FlatIconEdit
    $synchronize: Boolean
    $deckId: ID
  ) {
    editSCard(
      id: $id
      front: $front
      hint: $hint
      back: $back
      backLang: $backLang
      svg: $svg
      theory: $theory
      synchronize: $synchronize
      deckId: $deckId
      sourceId: $sourceId
    ) {
      id
      front
      hint
      sourceId
      back {
        value
        translated
      }
      theory(langNative: $backLang) {
        sCardId
        theory
        theoryIsChecked
        theoryNative
        theoryNativeIsChecked
        theoryEn
        lang
        langNative
        canEdit
      }
      svg {
        flatId
        url
      }
    }
  }
`;

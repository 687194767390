/**
 * @generated SignedSource<<7c6d4314014f2c91cd226d3c51a5df47>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SubscriptionPlatform = "android" | "browser" | "ios" | "lemon" | "%future added value";
export type createSubscriptionMutation$variables = {
  debug: string;
  platform: SubscriptionPlatform;
};
export type createSubscriptionMutation$data = {
  readonly createSubscription: {
    readonly alreadySubscribedBy: string | null;
    readonly user: {
      readonly subscriptions: ReadonlyArray<{
        readonly czk: number | null;
        readonly family: boolean;
        readonly platform: SubscriptionPlatform;
        readonly storeId: string;
        readonly timeToExpire: number;
        readonly transactionId: string;
      }>;
    } | null;
  } | null;
};
export type createSubscriptionMutation = {
  response: createSubscriptionMutation$data;
  variables: createSubscriptionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "debug"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "platform"
},
v2 = [
  {
    "kind": "Variable",
    "name": "debug",
    "variableName": "debug"
  },
  {
    "kind": "Variable",
    "name": "platform",
    "variableName": "platform"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Subscription",
  "kind": "LinkedField",
  "name": "subscriptions",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "transactionId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timeToExpire",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "platform",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "storeId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "family",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "czk",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "alreadySubscribedBy",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "createSubscriptionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "CSReturnType",
        "kind": "LinkedField",
        "name": "createSubscription",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "createSubscriptionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "CSReturnType",
        "kind": "LinkedField",
        "name": "createSubscription",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e0f44ca09dcaa204f553f076855194dc",
    "id": null,
    "metadata": {},
    "name": "createSubscriptionMutation",
    "operationKind": "mutation",
    "text": "mutation createSubscriptionMutation(\n  $platform: SubscriptionPlatform!\n  $debug: String!\n) {\n  createSubscription(platform: $platform, debug: $debug) {\n    user {\n      subscriptions {\n        transactionId\n        timeToExpire\n        platform\n        storeId\n        family\n        czk\n      }\n      id\n    }\n    alreadySubscribedBy\n  }\n}\n"
  }
};
})();

(node as any).hash = "9b020e36f02c9f6293131a1c5b45cdea";

export default node;

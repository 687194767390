import React from "react";
import { Trans } from "react-i18next";
import styled from "styled-components/macro";
import { useViewerQuery, useViewerXps } from "../../../../../queries/viewerQuery";
import { useNavigate } from "react-router-dom";
import { SUBSCRIPTION } from "../../../../../root/profile/ProfileRouter.config";
import Flames from "../../../../../components/fire/Flames";
import { strengthFn } from "../../../../../components/fire/FireStats";
import { STREAK_FREEZE_XPS } from "../../../../../sharedJs__generated/constants";
import { QuestMessage } from "../misc/QuestMessage";
import TransVariable from "components/other/TransVariable";
const SFlames = styled(Flames)`
  display: inline-block;
  margin: 0 10px -7px 10px;
  transform: scale(1.1);
`;

const BubbleContent = styled.div`
  cursor: pointer;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  justify-content: center;
`;

export const StreakRecoveryBubble: React.FC = () => {
  const { isSubscriber, viewer } = useViewerQuery();
  const navigate = useNavigate();
  const { xps } = useViewerXps();
  const streakLength = xps?.yesterdayLostStreak || 0;
  const handleClick = () => {
    if (!isSubscriber) {
      navigate(SUBSCRIPTION.url());
    }
  };

  return (
    <QuestMessage>
      <BubbleContent onClick={handleClick}>
        <TextWrapper>
          <div>
            <Trans>
              Oh no! You lost your <TransVariable text={streakLength.toString()} />
              -day streak.
            </Trans>
          </div>
          <div>
            <Trans>
              Collect
              <SFlames
                strength={strengthFn(STREAK_FREEZE_XPS - (viewer?.streakFreezeXps ?? 0))}
                xps={STREAK_FREEZE_XPS - (viewer?.streakFreezeXps ?? 0)}
                noZZ
              />
              more today to recover your streak!
            </Trans>
          </div>

          {!isSubscriber && (
            <div>
              <Trans>Or subscribe to premium now to restore your daily streak immediately!</Trans>
            </div>
          )}
        </TextWrapper>
      </BubbleContent>
    </QuestMessage>
  );
};

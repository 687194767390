import React from "react";
import styled from "styled-components/macro";
import { sourceEditQuery } from "../__generated__/sourceEditQuery.graphql";
import { Trans } from "react-i18next";
import { useNavigate } from "react-router";
import { SOURCEFORM } from "../../library/libraryUrls";
import SingleRow from "./SingleRow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRectangleVerticalHistory } from "@duocards/pro-regular-svg-icons";

const Cards = styled.em`
  display: block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: ${({ theme }) => theme.duo.fontSize.small};
  line-height: 1.4em;
`;

type Props = {
  source: NonNullable<sourceEditQuery["response"]["node"]>;
};

const CardsRow: React.FC<Props> = ({ source }) => {
  const navigate = useNavigate();

  return (
    <SingleRow
      icon={<FontAwesomeIcon icon={faRectangleVerticalHistory} />}
      isOpen={false}
      onClick={() => navigate(SOURCEFORM.url({ id: source.id, action: "cards" }))}
    >
      <Trans parent="strong">Cards</Trans>
      <Cards> {source.sCards?.map((c) => c.front).join(",")} </Cards>
    </SingleRow>
  );
};

export default CardsRow;

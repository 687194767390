import React from "react";
import styled from "styled-components/macro";
import { useViewerQuery } from "queries/viewerQuery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@duocards/pro-solid-svg-icons";

const SStarIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: -1px;
  right: -1px;
  &&& {
    color: ${({ theme }) => theme.duo.color.star};
    width: 11px;
    height: 11px;
  }
`;

type Props = {
  className?: string;
  style?: React.CSSProperties;
  unconditional?: boolean;
};

const StarIcon: React.FC<Props> = ({ className, style, unconditional }) => {
  const { viewer, noPayments } = useViewerQuery();

  if (!unconditional && (viewer?.subscription || noPayments())) {
    return null;
  }

  return <SStarIcon icon={faStar} className={className} style={style} />;
};

export default StarIcon;

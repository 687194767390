import { graphql } from "babel-plugin-relay/macro";
import { useViewerXps, useViewerQuery } from "../../queries/viewerQuery";
import { useMutation } from "relay-hooks";
import { xpIncrementMutation } from "./__generated__/xpIncrementMutation.graphql";
import { useSetState } from "components/ReduxProvider";
import StreakAchievement from "./StreakAchievement";
import { useMatchMain } from "root/RootRouter";
import { getLevel } from "icons/garden/levelsHelper";
import LevelAchievement from "./LevelAchievement";
import { dispatchEvent, EVENT, snackbar } from "tools/events";
import StreakFreezeChargeAchievement from "./StreakFreezeChargeAchievement";
import { STREAK_FREEZE_EARN_QUEST, STREAK_FREEZE_QUEST } from "../../sharedJs__generated/constants";
import { useSeeHint } from "../hint/Hint";
import StreakRecoveryAchievement from "./StreakRecoveryAchievement";

export const XpIncrement = graphql`
  mutation xpIncrementMutation($xps: Int) {
    xpIncrement(xps: $xps) {
      streakFreeze
      streakFreezeXps
      xps {
        today
        todayExtra
        total
        currentStreak
        yesterdayLostStreak
        days {
          xp
          added
          reviewed
          when
          streaked
          freeze
        }
      }
    }
  }
`;

export const useXpIncrement = () => {
  const { getFlag, viewer, hintWasSeen } = useViewerQuery();
  const { xps } = useViewerXps();
  const onMain = useMatchMain({ end: true });
  const setPresent = useSetState("present");
  const setLastQuestXps = useSetState("lastQuestXps");
  const seeHint = useSeeHint();

  const [mutate] = useMutation<xpIncrementMutation>(XpIncrement, {
    onCompleted: ({ xpIncrement }) => {
      if (onMain) return;
      const { progressXps } = getLevel(xpIncrement.xps.total);

      if (xpIncrement.xps.days.slice(-2)[0].streaked && !xps?.days.slice(-2)[0].streaked) {
        // Show streak recovery notification
        snackbar(<StreakRecoveryAchievement />, { severity: "info", confetti: true });
      } else if (xpIncrement.xps.days.slice(-1)[0].streaked && !xps?.days.slice(-1)[0].streaked) {
        // Show regular streak achievement notification
        snackbar(<StreakAchievement />, { severity: "info", confetti: true });
        if (getFlag("myGoal")?.value !== "0" && xps?.currentStreak === 0) {
          dispatchEvent(EVENT.openGoalDialog);
        }
      } else if (progressXps === 0) {
        snackbar(<LevelAchievement />, { severity: "info", confetti: true });
        setPresent(true);
      }
    },
    updater: (_, data) => {
      if (viewer && viewer?.streakFreeze < data.xpIncrement.streakFreeze) {
        snackbar(<StreakFreezeChargeAchievement />, { severity: "info", confetti: true });

        if (hintWasSeen(STREAK_FREEZE_QUEST) && !hintWasSeen(STREAK_FREEZE_EARN_QUEST)) {
          setTimeout(() => {
            if (xps?.total) {
              setLastQuestXps(data.xpIncrement.xps.total);
            }
            seeHint(STREAK_FREEZE_EARN_QUEST);
          }, 10);
        }
      }
    }
  });

  return (xps?: number) => mutate({ variables: { xps } });
};

import React from "react";
import styled from "styled-components";
import SelectList, { SelectItem } from "./SelectList";
import { QuestionData, QuestionType } from "./questions";
import useOnboardingQuestions from "hooks/useOnboardingQuestions";
import { dispatchEvent, EVENT } from "../../../tools/events";
import { OnboardingSubmitCallback } from "../onboarding/onboardingTypes";

type Props = QuestionData & {
  onCanSubmitChange?: OnboardingSubmitCallback;
  onRequestSubmit?: () => void;
};

const ListWrap = styled.div`
  display: grid;
  overflow: hidden;
  width: 100%;
  height: 100%;
  align-items: center;
  overflow-y: auto;
  position: relative;
  margin-top: 5px;
`;

const OnboardingQuestion: React.FC<Props> = ({
  id,
  visualType,
  options,
  multipleAnswers,
  onCanSubmitChange,
  onRequestSubmit,
  disableAutoSubmit = false
}) => {
  const { saveQuestion, getAnswers } = useOnboardingQuestions();
  const [initialSelectedIds, setInitialSelectedIds] = React.useState<string[]>([]);
  const wrapRef = React.useRef<HTMLDivElement>(null);

  const handleAnswerChange = (selectedAnswers: SelectItem[]) => {
    const selectedIds = selectedAnswers.map((item) => item.id);
    onCanSubmitChange?.(selectedIds.length > 0);
    saveQuestion({
      questionId: id,
      answers: selectedIds
    });

    if (!multipleAnswers && !disableAutoSubmit) {
      onRequestSubmit?.();
    }
  };

  React.useEffect(() => {
    const answers = getAnswers(id);
    setInitialSelectedIds(answers);

    if (id === QuestionType.NOTIFICATIONS) {
      onCanSubmitChange?.(true, async () => {
        dispatchEvent(EVENT.refreshNotifications);
        return true;
      });
      return;
    }

    onCanSubmitChange?.(answers.length > 0);
  }, [getAnswers, id, onCanSubmitChange]);

  React.useEffect(() => {
    wrapRef.current?.scrollTo({ top: 0, behavior: "smooth" });
  }, [id]);

  return (
    <ListWrap ref={wrapRef}>
      <SelectList
        layoutMode={visualType}
        items={options}
        onChange={handleAnswerChange}
        multiSelect={multipleAnswers}
        initialSelectedIds={initialSelectedIds}
      />
    </ListWrap>
  );
};

export default OnboardingQuestion;
